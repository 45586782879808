
@import "~lessRoot/_mixins.less";

.b-btn {
	position: relative;
	text-decoration: none; //for the a tag variant.
	appearance: none;
	text-align: center;
	border-radius: 6px;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	line-height: 1.125rem;
	//Account for line-height and center text on button with less padding bottom
	padding: 15px 30px 13px 30px;
	transition: background-color 0.25s, border-color 0.25s, color 0.25s;
	outline-offset: 2px; //For keyboard user visibility

	&:focus-visible {
		.focus-outline()
	}

	&[aria-disabled="true"] {
		cursor: not-allowed;
	}

	&__spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	//Colors
	&--red {
		--b-button-main-color: #AA141A;
		--b-button-hover-color: shade(@BR, 40%);
		--b-button-text-color: white;

		&[aria-disabled="true"] {
			&:not([data-loading="true"]) {
				&:not(.b-btn--not-greyed-out) {
					--b-button-text-color: #CFCFCF;
					--b-button-main-color: #F5F5F5;
				}
			}
		}
	}
	&--white {
		--b-button-main-color: white;
		--b-button-hover-color: #E5F4FF;
		--b-button-text-color: @BB;

		&[aria-disabled="true"] {
			&:not([data-loading="true"]) {
				&:not(.b-btn--not-greyed-out) {
					--b-button-text-color: #718BA5;
					--b-button-main-color: #34597F;
				}
			}
		}
	}
	&--blue {
		--b-button-main-color: @BB;
		--b-button-hover-color: shade(@BB, 20%);
		--b-button-text-color: white;

		&[aria-disabled="true"] {
			&:not([data-loading="true"]) {
				&:not(.b-btn--not-greyed-out) {
					--b-button-text-color: #CFCFCF;
					--b-button-main-color: #F5F5F5;
				}
			}
		}
	}

	//Variants
	&--primary {
		font-size: 1.125rem;
		border: 2px solid transparent;
		color: var(--b-button-text-color);
		background-color: var(--b-button-main-color);

		&:hover:not([aria-disabled="true"]) {
			background-color: var(--b-button-hover-color);
		}

		.b-btn__spinner {
			height: 1.25rem;
			width: 1.25rem;

			.spinner-path {
				stroke: var(--b-button-text-color);
			}
		}
	}

	&--secondary {
		font-size: 1rem;
		border: 2px solid var(--b-button-main-color);
		color: var(--b-button-main-color);
		background-color: transparent;

		&:hover:not([aria-disabled="true"]) {
			background-color: var(--b-button-main-color);
			color: var(--b-button-text-color);
		}

		.b-btn__spinner {
			height: 1.125rem;
			width: 1.125rem;

			.spinner-path {
				stroke: var(--b-button-main-color);
			}
		}
	}

	&--minimal {
		font-size: 1rem;
		//Account for line-height and center text on button with less padding bottom
		padding: 10px 13px 8px 13px;

		.b-btn__spinner {
			height: 1.125rem;
			width: 1.125rem;
		}
	}

	.mobile-styles({
		&:active:not(:disabled):not([aria-disabled="true"]) {
			background-color: var(--b-button-hover-color) !important;
		}

		&:hover:not(:disabled):not([aria-disabled="true"]) {
			background-color: var(--b-button-main-color);
		}
	});

}
