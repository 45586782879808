
@import "~lessRoot/_mixins.less";

.login-page {
	&__header {
		font: normal normal bold 1.5rem/1.25 Roboto;
		color: #191B25;
		margin-bottom: 5px;
	}

	&__header:focus {
		outline: none;
	}

	&__subtitle {
		font: normal normal normal 1rem/1.25 Roboto;
		color: #4C5271;
		margin-bottom: 32px;
	}

	&__error-banner {
		margin-bottom: 15px;
		font-size: 0.875rem;
	}

	&__error-banner-text {
		color: black;
		letter-spacing: 0.42px;
		line-height: 1.29;
	}

	&__form-area {
		padding: 20px 15px;
		background-color: #F2F3F8;
		margin-bottom: 30px;
		text-align: left;
	}

	&__input-field {
		margin-bottom: 12px;
	}

	&__captcha > div {
		margin: 0 auto;
	}

	&__sign-in-button {
		margin: 20px 0;
		width: 100% !important;
		max-width: unset !important;
		font-size: 1.125rem !important;

		.mobile-and-up-styles({
			font-size: 1.25rem !important;
		});
	}

	&__remember-me-wrapper {
		display: flex;
		justify-content: center;
	}

	&__remember-me-button {
		label {
			color: #4C5271;
			font-size: 0.875rem;
		}
 	}

	&__link-button {
		font-size: 14px;
		display: block;
		margin: 0 auto 32px auto;
	}
}
