
@import '~@less/_mixins.less';
#headerSearchBar {
	position: relative;

	.desktop-min-only-styles({
		max-width: 765px;
	});
}

.search-wrapper {
	position: relative;
	display: flex;
	flex: 1 1 auto;
}

.header-search {
	width: 100%;
}

.searchbar-search-icon {
	color: white;
	font-size: 16px;

	.desktop-min-only-styles({
		font-size: 26px;
	});
}

.header-search-bar__input {
	background-color: #FFF;
	border-radius: 30px;
	border: 1px solid #dee1ee;
	font-size: 16px;
	color: black;
	padding: 9px 40px 9px 16px;
	width: 100%;
	font-family: @font-body;
	height: 40px;

	&::placeholder {
		color: shade(@CL, 15%);
		opacity: 1; /*FireFox*/
	}

	.desktop-min-only-styles({
		padding-right: 48px;
		height: 48px;
	});
}

//Wrapper is for old version of safari.
.header-search-bar__button-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 4px;
	aspect-ratio: 1;
	max-width: 40px;

	.desktop-min-only-styles({
		max-width: 48px;
	});
}

.header-search-bar__button {
	background-color: @BB;
	border-radius: 50%;
	border: none;
	outline: none;
	padding: 4px 4px 3px 4px;
	height: 100%;
	width: 100%;
}
