
@import "~lessRoot/_mixins.less";

.join-rewards-styles {
	text-align: center;

	&__header-image {
		width: 100%;
		margin-bottom: 5px;
	}

	&__header {
		font: normal normal bold 1.5rem/1.25 Roboto;
		color: #191B25;
		margin-bottom: 6px;
	}

	&__header:focus {
		outline: none;
	}

	&__subtitle {
		font-size: 1rem;
		line-height: 1.25;
		color: #4C5271;
		margin-bottom: 36px;
	}

	&__benefits-wrapper {
		margin-bottom: 44px;
	}

	&__benefit {
		color: #191B25;
		font: normal normal bold 0.875rem/1.3 Roboto;
		display: flex;
   		align-items: center;
    	justify-content: center;
		margin-bottom: 8px;

		> .icon {
			font-size: 1.25rem;
			color: #005FAE;
			margin-right: 5px;

			.mobile-and-up-styles({
				font-size: 1.75rem;
			});
		}
	}

	&__error-banner {
		text-align: left;
		margin-bottom: 22px !important;
		display: flex !important;
		color: black;
	}

	&__error-banner-text {
		color: black;
		letter-spacing: 0.42px;
		line-height: 1.29;
		font-size: 0.875rem;
	}

	&__form-area {
		background-color: #f2f3f8;
		color: black;
		padding: 20px 15px;
		text-align: left;
		margin-bottom: 30px;
	}

	&__bottom-label {
		color: #4C5271;
		font: italic normal normal 0.875rem/1.3 Roboto;
		margin-top: 4px;
	}

	&__join-button {
		margin-top: 20px;
		max-width: 100% !important;
		font-size: 1.125rem !important;

		.mobile-and-up-styles({
			font-size: 1.25rem !important;
		});
	}

	&__learn-more {
		font-size: 14px;
	}
}
