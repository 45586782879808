@import '../_mixins.less';

/* List Slider (Carousel base styles)
============================================*/

.list-slider {
	.list-slider();
}


list-slider {
	.list-slider-scroll-new();

	&:defined {
		.desktop-min-only-styles({
			.list-slider-controlled();
		});
	}
}

/* Primary Product Carousel
============================================*/

@productCarouselImageSize: 150px;
@productCarouselImageSizeMobile: 130px;

.product-carousel, .product-carousel-vue {
	margin-bottom: 25px;
	h2 {
		color: #666;
		font-weight: 300;
		font-size: 25px;
		letter-spacing: 2px;
		margin-bottom: 0px;
	}

	.tablet-styles({
		margin: 0 -15px 25px;
	});

	.mobile-styles({
		margin: 0 0 25px;
		h2 {
			font-size: 22px;
		}
	})
}

.product-carousel-vue {
	margin-top: 40px;
}

.pc-title {
	margin-bottom: 20px;
	p {
		color: #666;
		font-weight: 300;
		font-size: 25px;
		letter-spacing: 2px;
		margin-bottom: 0;
	}
	a {
		background-color: #fff;
		border: 2px solid rgba(36,135,196,.4);
		color: #2079B0;
		display: inline-block;
		margin: 10px 0;
		padding: 3px 15px;
		border-radius: 6px;
		text-decoration: none;
		&:hover {
			border-color: #2487c4;
			color: #2079B0;
		}
	}

	.mobile-styles({
		h2 {
			font-size: 22px;
		}
	});
}

.pc-viewport {
	.list-slider(6);

	.tablet-styles({
		.list-slider-scroll();
		.list-slider-items-per-slide(4.5);
		.pc-button {
			display: none;
		}
	});

	.mobile-styles({
		.list-slider-items-per-slide(2.75);
	});

	.ms-styles({
		.list-slider-items-per-slide(2.35);
	});
}

li.pc-item-wrapper {
	> div {
		margin: 0 auto;
	}
}

a.pc-item {
	max-width: @productCarouselImageSize;
	text-decoration: none;
	.ms-styles({
		max-width: @productCarouselImageSizeMobile;
	});
}

.pc-item-img img {
	.ms-styles({
		height: @productCarouselImageSizeMobile;
		width: @productCarouselImageSizeMobile;
	});
}


.pc-item-price {
	height: 40px;
	margin: 25px 0 5px 0;
	line-height: normal;
}

.pc-item-name {
	margin: 5px 0 25px 0;
	font-weight: 300;
	line-height: 17px;
}

.pc-item-badge {
	top: 0;
	padding: 5px;
	min-width: 100px;
	z-index: 2;
}

.pc-item-rating {
	--review-stars-font-size: 18px;
	color: #c38900;
	.absolute {
		overflow: hidden;
	}
}

button.pc-button {
	background: #e9f3f9;
	background-image: url('/assets/images/icons/chevron-left.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 24px;
	border: 2px solid #a7cfe7;
	border-radius: 0.4rem 0 0 0.4rem;
	color: #014A77;
	font-size: 1.5rem;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: 75px;
	width: 38px;
	z-index: 2;
	&.pc-next-button{
		transform: translateY(-50%) scaleX(-1);
		right: 0;
		left: auto;
	}
	&[aria-disabled="true"] {
		opacity: 0.5;
		cursor: not-allowed;
	}
	.tablet-styles({
		display: none
	});
}

