
@import "~lessRoot/_mixins.less";

.rewards-success-styles {
	text-align: center;

	&__header-image {
		width: 100%;
		margin-bottom: 5px;
	}

	&__header {
		font: normal normal bold 1.5rem/1.25 Roboto;
		color: #191B25;
		margin-bottom: 20px;
	}

	&__header:focus {
		outline: none;
	}

	&__email {
		font: normal normal bold 0.875rem/1.3 Roboto;
		color: #191B25;
		margin-bottom: 15px;
	}

	&__subtitle {
		font: normal normal normal 0.875rem/1.3 Roboto;
		color: #4C5271;
		margin-bottom: 30px;
	}

	&__continue {
		margin: 0 auto;
		max-width: unset;
		width: unset;
		margin-bottom: 30px;
		padding: 10px 24px !important;
		font-size: 1rem !important;

		.mobile-and-up-styles({
			font-size: 1.125rem !important;
		});
	}

	&__link-button {
		font-size: 0.875rem;
	}

}

