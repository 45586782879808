
@import '~@less/_mixins.less';
.store-finder-wrapper {
	p {
		margin: 0;
		padding: 0;
	}
	p, h3 {
		font-family: 'Roboto';
	}
}

.sfw-google-map-container {
	flex: 0 0 270px;
	margin: 15px 17px;
	position: relative;
	.mobile-styles({
		padding: 0 15px;
		margin: 0;
		flex: 1 1 320px;
	});
}

.sfw-google-map {
	.aspect-ratio(1, 1);
	iframe {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
	}
}

.sfw-switch-link {
	line-height: 25px;
	color: @LB;
}

.sfw-store-details {
	flex: 1;
	padding: 10px 10px 20px 10px;
}

.sfw-head {
	padding-bottom: 8px;
	padding-left: 5px;
	align-content: center;
	border-bottom: 1px solid #ccc;
	a.ui-btn {
		max-width: 120px;
		font-size: 11px;
		padding: 3px 5px;
		max-height: 24px;
		.mobile-styles({
			padding: 4px;
		});
	}
}

.sfw-head-text {
	margin-right: 15px;
	font-size: 12px;
	font-weight: 500;
	white-space: nowrap;
	color: #008040;
	.fa {
		font-size: 14px;
		margin-right: 4px;
	}
}

.sfw-body {
	margin-top: 10px;
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
	padding-left: 5px;
}

.sfw-store-address {
	h3 {
		font-size: 18px;
		font-weight: bold;
		color: #000;
		margin: 0 0 2px 0;
		padding: 0;
		line-height: 1.25em;
	}
	p {
		color: #666666;
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
	}
}

.sfw-footer {
	margin-top: 5px;
	padding-left: 5px;
	.blue-link {
		display: block;
		margin-top: 5px;
		text-decoration: none;
		color: @LB;
		text-align: left;
		&:hover {
			text-decoration: underline;
			color: #014A77;
		}
		.tablet-styles({
			margin-top: 10px;
		});
	}
}

.sfw-store-hours-wrapper {
	p {
		margin: 6px 0;
		&.sfw-store-message {
			margin: 3px 0 0 0;
		}
	}
}

.sfw-store-hours {
	max-width: 190px;
	line-height: 20px;
	.day {
		color: #000000;
	}
	.hours {
		color: #666666;
		font-weight: 400;
	}
}
