@import '../_variables.less';
@import '../_mixins.less';

/*=======================================================================
== Landing Page
========================================================================*/
.landing-page-video-section{
	height:auto;
	width:100%;
	margin-top:25px;
}
.landing-page-hierlist{
	font-weight:400;
	margin: 6px 5px 0;
	ul {
		list-style: square;
		margin: 0 0 0 18px;
	}

	li{
		padding:5px 0;
		a{
			text-decoration:none;
		}
	}
	padding-left:5px;

}
.landing-page-item-abstract{
	line-height: 17px;
	font-weight:400;
	margin:10px 5px 20px;
	li{
		padding:5px 0 5px 10px;
	}
    .styles-at-min(1440px, {
        margin:10px 5px 20px;
    });
	 .styles-at-min(@tablet,{
        margin:10px 5px 20px;
	 });
	padding-left:5px;
}
.landing-page-item{
	height:auto;
	margin-bottom:20px;
}
.landing-page-item-container{
	padding:0 10px 20px 10px;
	text-align: left;
    .mobile-styles({
        padding:0 0 20px 0;
    });
	.landing-page-item-header a {
		text-decoration:none;
	}
}
.landing-page-footer{
	bottom:0;
	font-weight:400;
	padding:5px 5px 5px 10px;
	font-weight:700;
	height:20px;
	vertical-align:top;
	a{
		text-decoration:none;
	}
}
.landing-page-section{
	padding:0 15px 15px 15px;
	.mobile-styles({padding:0 0 10px;});
	width:100%;
	.desktop-promo{
		display:block;
		margin-bottom:10px;
		aspect-ratio: 1678 / 630;
		width: 100%;

		&.short-banner {
			aspect-ratio: 1678 / 350;
		}

		img{
			max-width: 100%;
			height: auto;
			aspect-ratio: 1678 / 630;

			&.short-banner {
				aspect-ratio: 1678 / 350;
			}
		}
		.styles-at(@iphone,{
			display:none;
		})
	}
	.mobile-promo{
		display:none;
		margin-bottom:10px;
		aspect-ratio: 640 / 400;
		
		img {
			aspect-ratio: 640 / 400;
		}

		.styles-at(@iphone,{
			display:block;
		});
	}
}

.landing-page-section-body{
	margin-bottom:20px;
    img{
        height:auto;
        width:100%;
        margin:auto;
        .styles-at(768px,{
            height:auto;
            width:100%;
        });
    }
}

.landing-page-video-container{
	display: flex;
	div{
		display: flex;
		flex-direction: column;
	}
	margin-bottom:15px;
	overflow:hidden;
	width:100%;
	.styles-at(@iphone, {
		display:block;
	});
	.landing-page-video-img{
		width:33.3%;
		height:auto;
		max-height:315px;
		max-width:279px;

		.styles-at(@iphone,{
			display:none;
		});
	}
	.landing-page-video-mobileimg{
		display:none;
		.styles-at(@iphone,{
			width:100%;
			height:auto;
			display:block;
		});
	}
	img{
		height:100%;
		width:100%;
	}
	.landing-page-video{
		width:66.6%;
        .styles-at(@iphone,{
			width:100%;
			position: relative;
			padding-bottom: 44.25%;
			padding-top: 25px;
			height: 0;
			iframe{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
        });
	}
}
.landing-page-sidebar{
	h4{
		font-size:1em;
	}
}
.list-sidebar{

	.landing-page-sidebar{
		.styles-at(@tablet-min,{
			display:none;
		});
	}
	.sidebarlinks{
		> li{
			margin-bottom:10px;
			margin-top:10px;
			font-size:14px;
			font-weight:400;
			padding-top:5px;
			padding-left: 10px;
			//height:20px;
			.styles-at(768px,{
				margin:0;
				padding-top:14px;
				padding-bottom:14px;
				height:40px;
			});
			//overflow:hidden;
			a{
				text-decoration:none;
				.styles-at(768px,{
					font-weight:300;
					font-size:18px;
					display:block;
				});
			}
		}
		.styles-at(768px,{
			margin-bottom:0;
		});
		padding:0 8px;
		margin-bottom: 30px;
	}
}


.landing-page-section-body{
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    .landing-page-item {
        flex: 0 1 25%;
		.styles-at(1200px, {
			flex: 0 1 33.333%;
		});
		.tablet-styles({
			flex: 0 1 50%;
		});
		.ms-styles({
			flex: 0 1 100%;
		});
        text-align:center;
        font-weight:700;
        text-decoration: none;
        color: black;
    }
    img,span{
        padding: 10px;
        display:block;
    }
}

.landing-page-section-video{
	width:100%;
	position: relative;
	padding-bottom: 44.25%;
	padding-top: 25px;
	height: 0;
	display:block;
	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.landing-page-side-promo{
	width:100%;
	padding-bottom:5px;
	img{
		width:100%;
	}
}
.sidebar-group{
	&:not(:last-child){
		border-bottom: 2px solid #70a9db;
        .styles-at(768px,{
            border:none;
        });
	}
	padding-top:10px;
	padding-bottom:10px;
    .styles-at(768px,{
        padding-bottom:5px;
		padding-top:0;
		border-top:none;
    });
}
.sidebar-group .sidebar-collapsed{
    .styles-at(768px, {
        display:none !important;
    });
}

/*=======================================================================
== brands Page
========================================================================*/
#brandList{
    .brand-header{
        border-bottom:1px solid @grey-main;
        padding-bottom:10px;
    }
    .brand-area{
        column-count: 4;
        margin-bottom:20px;
        .tablet-styles({
            column-count: 3;
        });
        .mobile-styles({
			column-count: 2;
        });
        .iphone-styles({
            column-count: 1;
        });
        li{
            padding-left:20px;
            i{
                top:6px;
                left:0;
            }
        }
        a{
            display:block;
            width:100%;
            line-height:27px;
        }
    }
    #brandKey{
        .mobile-styles({
            flex-wrap: wrap;
        });
        font-family:@font-headers;
        margin-bottom:25px;
        .key{
            display:inline-block;
            .mobile-styles({
                flex: 1 1 10%;
                margin-bottom:8px;
                text-align:center;
            })
        }
    }
}