
@import "~lessRoot/_mixins.less";
.rewards-sidemenu-styles {
	&__wrapper {
		padding: 20px 15px 40px 15px;
	}

	.blain-text-field__label:first-child {
		color: #4C5271;
	}
}

