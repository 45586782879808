
@import '~@less/_mixins.less';
.mobile-menu-item {
	width: 100%;

	&__link {
		.button-reset();
		padding: 12px 10px;
		text-align: left;
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		cursor: pointer;

		color: shade(@CL, 80%);
		text-decoration: none;
		line-height: 19px;
		font-size: 16px;
		font-weight: 500;
	}

	.right-section {
		font-size: 10px;
		span {
			color: @BB;
		}
	}
	.left-section {
		flex: 1;
	}
	&.primary {
		border-bottom: 1px solid tint(@CL, 80%);
		background: white;
		.primary-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			color: @BB;
			width: 15px;
			height: 15px;
			font-size: 20px;
			margin-right: 8px;
		}
		.right-icon {
			color: @BB;
		}
	}
	&.secondary {
		border-bottom: 1px solid tint(@CL, 80%);
		background-color: tint(@CL, 90%);
		.right-icon {
			color: @BB;
		}
	}
	&__red {
		.left-section {
			span {
				color: @BR;
			}
			.icon {
				color: @BR;
			}
		}
	}
}
