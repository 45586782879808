
@import "~lessRoot/_mixins";

.horizontal-text-divider {
	display: flex;
	align-items: center;
	width: 100%;
	font-weight: 500;
	font-size: 0.875rem;

	&__line {
		background-color: tint(@CL, 60%);
		height: 2px;
		flex: 1;
	}

	&__text {
		color: shade(@CL, 20%);
		margin: 10px;
	}
}
