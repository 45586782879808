
@import '~@less/_mixins.less';

//This should affect sitewide this classname
.vwo-shipping-control {
	//display:none;
}

//TODO: MAKE SURE TO SWAP THIS BEFORE LAUNCH.
.vwo-shipping-variant {
	display: none;
}

#siteHeader {
	.header-icon {
		color: @BB;
	}
}
#siteHeaderTop {
	background-color: tint(@CL, 90%);
	padding: 10px;
}
#siteHeaderTopLeft {
	width: 100%;
	gap: 5px;
	justify-content: space-between;

	.tablet-and-up-styles({
		margin-right: 30px;
	});

	.tablet-styles({
		flex: 0 1 240px;
	});

	.desktop-only-styles({
		margin-left: 10px;
	});

	.header-hamburger-icon {
		display: inline-block;
		font-size: 30px;
		width: 30px;
	}
}
#siteHeaderTopRight {
	gap: 5px;

	.tablet-and-up-styles({
		gap: 20px;
		margin-right: 20px;
	});
}
#headerHomeLink {
	margin: 0 25px 0 0;
	max-width: 300px;
	width: 100%;

	.tablet-styles({
		margin: 0 0;
		max-width: 210px;
	});
}

.header-searchbar-mobile-wrapper {
	min-height: 41px;

	.desktop-min-only-styles({
		display: none;
	});
}

