
@import '~@less/_mixins.less';
.cart-wrapper {
	background: #E5E7F1; /* Old browsers */
	background: -moz-linear-gradient(top,  #E5E7F1 0%, rgb(234, 235, 241) 80%,  #F5F5F5 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #E5E7F1 0%,rgb(234, 235, 241) 80%,#F5F5F5 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #E5E7F1 0%,rgb(234, 235, 241) 80%,#F5F5F5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	color: #000;
	z-index: 2;
	position: relative;
}

.cart-header {
	background-color: #fff;
	padding: 10px;
}

.header-text {
	color: #656E97;
	letter-spacing: 0.56px;
	.left {
		color: #656E97;
		letter-spacing: 0.56px;
		align-self: flex-end;
	}
	.right {
		margin-left: 8px;
		color: #000;
	}
	.text {
		margin-top: 5px;
	}
	.icon {
		color: #98A1CA;
		font-size: 24px;
		margin-right: 5px;
	}
}

.price-amount-wrapper {
	display: inline-flex;
	align-items: flex-end;
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.dollar-sign {
	color: #000;
	vertical-align: text-bottom;
	font-weight: bold;
	font-size: 11px;
	padding: 0 1px 2px 0;
}

.scroll-hidden {
	max-height: 480px;
	overflow-y:scroll;
	overflow-x: hidden;
	position: relative;
	margin-bottom: 5px;
	&::-webkit-scrollbar { width: 0 !important }
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.scroll-hint {
	min-height: 60px;
	bottom: 0;
	position: sticky;
	background: rgb(245,245,245);
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background: linear-gradient(180deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 50%, rgba(245,245,245,1) 100%);
	color: #717BA9;
	font-size: 14px;
	border-bottom: 2px solid #E5E5E5;
	.icon-tiny {
		font-size: 7px;
		margin-left: 7px;
	}
}

.reward-callout {
	background-color: #FFF3D6;
	color: #666666;
	font-size: 14px;
	font-weight: 500;
	justify-content: space-between;
	padding: 13px 7px;
	max-height: 42px;
	text-decoration: none;
	.points {
		font-weight: bold;
		color: @DR;
		margin: 0 3px;
	}
	.icon {
		font-size: 24px;
		margin-right: 10px;
	}
	.icon-tiny {
		font-size: 7px;
		margin-right: 5px;
	}
}

.no-items-wrapper {
	padding: 0px 10px 5px 10px;
}

.no-items-text {
	border-top: 2px solid #E5E7F1;
	color: #666;
	line-height: 20px;
	padding: 15px 0;
	padding-bottom: 0;
	margin-bottom: 15px;
}
