
@import '~@less/_mixins.less';
.header-account-wrapper {

	.header-user-icon {
		display: inline-block;
		font-size: 30px;
		width: 30px;

		.tablet-and-up-styles({
			font-size: 40px;
			width: 40px;
		})
	}

	.header-account-menu {
		padding: 15px;
		width: 230px;

		li span {
			color: @LB;
			margin-right: 5px;
			font-size: 22px;
		}

		li a {
			color: @DB;
			padding: 8px 0;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.guest-callout {
		border-top: solid 3px #CBD0E4;
		font-weight: 600;
		color: #656E97;
		padding-top: 10px;
		a {
			color: @DB;
			font-weight: 400;
		}
	}
	#siteHeaderAccount {
		p.absolute {
			font-weight: 400;
			color: #656884;
			top: -5px;
		}

		p.fs-14 {
			color: shade(@BB, 40%);
		}

		.header-drop-menu__chevron-button {
			margin-top: 10px;
		}
	}
}
