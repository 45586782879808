
	@import '~lessRoot/_mixins';

.login-sidemenu {
	&__wrapper {
		padding: 20px 15px 30px 15px;
	}

	.horizontal-text-divider__text, .blain-text-field__label {
		color: #4C5271;
	}
}
