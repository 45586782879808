//STYLED WIDGET SHARED STYLES
.styled-widget {
	display: flex;
	flex-direction: column;
	max-width: 1440px;
	width: 100%;
	padding: 0 10px;
	margin: 16px 0;

	.tablet-and-up-styles( {
		padding: 0 12px;
	});

	.desktop-min-only-styles( {
		padding: 0 16px;
		margin: 24px 0;
	});

	.styles-at-min(1456px, {
		padding: 0;
	});

	&__white-link {
		border: 2px solid #fff;
		border-radius: 6px;
		padding: 8px 12px;
		font-size: .875rem;
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		transition: all .2s linear;

		&:hover {
			background-color: #fff;
			color: #014A77;
		}

		.desktop-min-only-styles({
			font-size: 1rem;
			padding: 10px 12px 8px 12px;
		});
	}

	&__title {
		font: normal normal 500 1.375rem/1.2 Roboto;
		padding-bottom: 10px;
		margin-bottom: 0;

		.desktop-min-only-styles( {
			font-size: 1.75rem;
		});
	}

	&__description {
		font-size: 1.125rem;
		line-height: 1.2;
		padding-bottom: 8px;
		margin-bottom: 0;

		.desktop-min-only-styles( {
			font-size: 1.375rem;
		});
	}

	.carousel {
		&__arrow {
			display: flex;
			font-size: 24px;
			padding: 8px;
			border: 1px solid rgba(255,255,255,0);
			border-radius: 50%;
			transition: border .1s linear;
		}

		&__button {
			display: none;
			color: #fff;
			background: rgba(0,0,0,0);
			border: 2px solid #fff;
			border-radius: 50%;
			margin-left: 8px;
			padding: 0;

			&:hover .carousel__arrow {
				border: 1px solid #fff;
			}

			&.carousel__button-blue,
			&.carousel__button-blue:hover:not([aria-disabled="true"]) .carousel__arrow {
				border-color: #0076B6;
				color: #0076B6;
			}

			&[aria-disabled="true"] {
				border-color: #8893B8;
				color: #8893B8;
				cursor: not-allowed;
			}

			&[aria-disabled="true"] .carousel__arrow {
				border: 1px solid rgba(255,255,255,0);
			}

			.desktop-min-only-styles( {
				display: flex;
			});
		}
	}
}

// -- HoverCards --
.hover-cards {
	&__container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 32px;
	}

	&__item {
		height: 280px;
		overflow: hidden;
		border-radius: 8px;
	}

	&__link {
		text-decoration: none;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		&:hover > .hover-cards__background-image {
			transform: scale(1.05);
		}
	}

	&__background-image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position-y: 80%;
		background-position-x: 70%;
		width: 100%;
		height: 100%;
		border-radius: 8px 8px 0 0;
		transition: all .2s linear;
	}

	&__title {
		width: 100%;
		margin: 0;
		padding: 16px;
		background-color: #000;
		color: #fff;
		font-size: 1.6rem;
		border-radius: 0 0 8px 8px;
		z-index: 2;
	}

	.tablet-styles( {
		&__item {
			aspect-ratio: 16/9;
			height: auto;
			width: 100%;

			@supports not (aspect-ratio: 16/9) {
				min-height: 180px;
			}
		}

		&__background-image {
			background-position-y: 50%;
		}

		&__container {
			grid-template-columns: 1fr 1fr;
			gap: 24px;
		}

		&__title {
			font-size: 1.4rem;
		}
	});


	.mobile-styles( {
		&__container {
			gap: 16px;
		}

		&__title {
			font-size: 1.1rem;
			padding: 12px 16px;
		}
	});

	.styles-at(576px, {
		&__container {
			grid-template-columns: 1fr;
			gap: 16px;
		}
	});
}

// -- NameTagLinks --
.name-tag-links {
	&__container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 16px 32px;
	}

	&__link {
		text-decoration: none;
		color: #000;
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #fff;
		transition: border .2s linear;
	}

	&__item {
		width: 100%;
		box-shadow: #0000001A 0 0 6px;
		border-bottom: 2px solid #B2B8D7;

		&:hover > .name-tag-links__link {
			border-bottom: 1px solid #B2B8D7;
		}
	}

	&__image {
		flex-shrink: 1;
		height: 104px;
		padding: 4px;
	}

	&__title {
		flex-shrink: 1;
		font-size: 1.4rem;
		font-weight: bold;
		margin: 0 24px;
	}

	.styles-at(1085px, {
		&__container {
			grid-template-columns: 1fr 1fr;
		}

		&__title {
			font-size: 1.2rem;
			margin: 0 0 0 16px;
		}
	}

	);

	.mobile-styles( {
		&__container {
			grid-template-columns: 1fr;
		}

		&__title {
			font-size: 1.1rem;
		}
	}

	);
}

// -- StepText --
.step-text {
	&__container {
		display: flex;
		background-color: #F2F3F8;
		padding: 32px 0;
		border-radius: 5px;
	}

	&__item {
		width: 100%;
		padding: 0 24px;
		border-right: 1px solid #7E89BC;

		&:last-of-type {
			border-right: none;
		}
	}

	&__title {
		display: flex;
		align-items: center;
		min-height: 2.2rem;

		&-number {
			padding: .45rem .6rem;
			text-align: center;
			font-weight: bold;
			position: absolute;

			&--container {
				flex-shrink: 0;
				height: 1.8rem;
				width: 1.8rem;
				background-color: #7E89BC;
				color: #fff;
				border-radius: 100%;
				margin-right: 16px;
				position: relative;
			}
		}

		&-text {
			flex-shrink: 1;
			font-size: 1.2rem;
			line-height: .9;
			font-weight: bold;
			margin-bottom: 0;
		}
	}

	&__description {
		margin-top: 16px;
	}

	.tablet-styles( {
		&__container {
			flex-direction: column;
			padding: 0 16px;
		}

		&__title {
			&-number {
				font-size: .8rem;

				&--container {
					height: 1.6rem;
					width: 1.6rem;
					margin-right: 12px;
				}
			}

			&-text {
				font-size: 1.1rem
			}
		}

		&__item {
			padding: 16px 0;
			border-right: none;
			border-bottom: 1px solid #7E89BC;

			&:last-of-type {
				border-bottom: none;
			}
		}

		&__description {
			margin-top: 8px;
		}
	}

	);
}

// -- HeroCarousel --
.hero-carousel {
	&__container {
		width: 100%;
		height: fit-content;
		display: flex;
		flex-direction: column;

		.tablet-and-up-styles({
			flex-direction: row;
		});
	}

	&__hero {
		width: 100%;
		height: 280px;
		flex-shrink: 0;
		display: flex;

		.tablet-and-up-styles({
			height: auto;
			width: 280px;
		});

		.desktop-min-only-styles({
			width: 400px;
		});
	}

	&__image {
		object-fit: cover;
		height: 100%;
		width: 100%;
		object-position: 50% 30%;

		.tablet-and-up-styles({
			object-position: 50% 50%;
		});
	}

	&__content {
		display: flex;
		flex-direction: column;
		flex-shrink: 1;
		justify-content: space-between;
		overflow: hidden;
		padding: 16px 24px;
		background-color: #01305F;
		color: #fff;

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 16px;
			position: sticky;
			left: 0;
		}

		.desktop-min-only-styles({
			padding:  24px 40px;
		});
	}

	&__controls {
		display: flex;
		align-self: flex-end;
	}

	&__arrow {
		display: flex;
		font-size: 24px;
		padding: 8px;
		border: 1px solid rgba(255,255,255,0);
		border-radius: 50%;
		transition: border .1s linear;
	}

	&__display {
		.list-slider-auto();

		.tablet-styles( {
			.list-slider-scroll();
		});
	}

	&__list {
		padding-bottom: 4px;
		.custom-horizontal-scrollbar(#B2B8D7, transparent, 4px);
	}

	&__product {
		padding-right: 8px;
		display: flex;

		.desktop-min-only-styles( {
			padding-right: 12px;
		});

		&:last-of-type {
			padding: 0;
		}
	}

	&-offset {
		.hero-carousel__content {
			width: calc(~'100% - 32px');
			margin: 0 16px;

			.tablet-and-up-styles({
				height: fit-content;
				width: 100%;
				margin: 32px 0;
			});
		}

		.hero-carousel__hero {
			margin-bottom: -64px;

			.tablet-and-up-styles({
				width: 400px;
				margin-right: -128px;
				margin-bottom: 0;
			});

			.desktop-min-only-styles({
				width: 500px;
			})
		}
	}
}

// -- ProductCard --
.product-card {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
	text-decoration: none;
	height: 18.75rem;
	width: 10.625rem;
	padding: 8px;
	border-bottom: 2px solid #B2B8D7;
	background-color: #fff;

	.tablet-and-up-styles({
		height: 20rem;
		width: 11.25rem;
	});

	.desktop-min-only-styles({
		height: 21.25rem;
		width: 11.875rem;
	});

	.price-alt-tip {
		display: none !important;
	}

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		border-bottom: 2px solid #B2B8D7;
		transition: all .2s linear;
	}

	&:active::after {
		border-bottom: 4px solid #B2B8D7;
	}

	.desktop-min-only-styles({
		&:hover::after {
			border-bottom: 4px solid #B2B8D7;
		}
	});

	&__image-container {
		display: flex;
		justify-content: center;
		overflow: hidden;
		margin-bottom: 8px;
		height: 9.375rem;
		.tablet-and-up-styles({
			height: 10rem;
		});
		.desktop-min-only-styles({
			height: 10.625rem;
		});
	}

	&__details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 50%
	}

	&__price {
		display: flex;
		align-items: center;
		color: black;
		margin-bottom: 16px;
	}

	.price-wrapper:has(> .price-alt) {
		padding-top: 0;
		margin-bottom: 4px;
	}

	.price-wrapper:has(.price-saved) {
		margin-bottom: 0;
	}

	&__description {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 60%;
	}

	&__brand {
		color: #666;
		font-size: 0.8rem;
		font-weight: 500;
		margin-bottom: 0;
		text-transform: uppercase;
	}

	&__name {
		color: black;
		font-size: .9rem;
		line-height: 1.1;
		margin-bottom: 0;
	}

	&__rating {
		margin-top: auto;
		margin-bottom: 4px;
	}

	.tablet-styles( {
		padding: 4px;

		&__price {
			margin-bottom: 0;
		}

		.price-wrapper {
			margin-top: 0 !important;
		}

		&__brand {
			font-size: 0.75rem;
			line-height: 1;
		}

		&__name {
			color: black;
			font-size: .8rem;
			line-height: 1.1;
			margin-bottom: 0;
		}
	}

	)
}

// -- Banner --
.banner {
	&__container {
		display: flex;
		flex-direction: column;

		.tablet-and-up-styles( {
			flex-direction: row;
		}

		);
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		flex-shrink: 0;
		min-height: 160px;
		width: 100%;
		padding: 16px;
		order: 1;

		.styled-widget__white-link {
			margin-top: 16px;

			.desktop-min-only-styles( {
				margin-top: 24px;
			}

			);
		}

		.tablet-and-up-styles( {
			order: unset;
			min-height: 240px;
			width: 360px;
			padding: 32px;
		}

		);

		.desktop-min-only-styles( {
			width: 460px;
			min-height: 280px;
			padding: 32px 40px;
		}

		);

		&.fall {
			background-color: @AAOCH
		}

		&.fall-2 {
			background-color: #985800
		}

		&.winter {
			background-color: shade(@LB, 20%);
		}

		&.winter-light {
			background-color: #E5F4FF;

			.styled-widget {
				&__title, &__description {
					color: black;
				}

				&__white-link {
					color: #01305f;
					border-color: #01305f;

					&:hover {
						background-color: #01305f;
						color: white;
					}
				}
			}
		}

		&.spring {
			background-color: #017470;
		}

		&.christmas-red {
			background-color: @DR;
		}

		&.dark {
			background-color: #32374B;
		}

		&.dark-blue {
			background-color: shade(@DB, 20%);
		}

		&.tools-dark-blue {
			background-color: #01305F;
		}
	}

	&__header {
		color: #fff;
	}

	&__image {
		height: 180px;
		background-position: left;
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		flex-shrink: 1;

		.tablet-and-up-styles( {
			height: unset;
		}

		);
	}
}

// -- CircleCarousel --
.circle-carousel {
	&.styled-widget {
		padding-right: 0;

		.desktop-min-only-styles( {
			padding-right: 16px;
		});

		.styles-at-min(1440px, {
			padding: 0;
		});
	}

	&__container {
		display: flex;
		align-items: center;
		width: 100%;

		.desktop-min-only-styles( {
			padding: 0 32px;
		});
	}

	.carousel__button {
		margin: 0;
	}

	&__display {
		.list-slider-auto();
		.list-slider-scroll();

		.desktop-min-only-styles( {
			margin: 0 24px;
			.list-slider(5);
		}

		);

		.styles-at-min(1150px, {
			.list-slider(6);
		}

		);

		.styles-at-min(1300px, {
			.list-slider(7);
		}

		);
	}

	&__list {
		padding: 8px 0;
		.custom-horizontal-scrollbar(#B2B8D7, transparent, 4px);

		.desktop-min-only-styles( {
			overflow-y: visible !important;
		}

		);
	}

	&__item {
		display: inline-flex;
		white-space: normal;
		vertical-align: top;
		padding-right: 8px;

		.desktop-min-only-styles( {
			width: 100%;
			padding: 0 8px;
			justify-self: center;
			align-self: center;
		}

		);
	}
}

// -- CircleContent --
.circle-content {
	display: flex;

	&__container {
		display: flex;
		border-radius: 50%;
		border: 2px solid #E5E7F0;

		&:active {
			border-color: #0076B6;
		}

		.desktop-min-only-styles({
			&:hover {
				border-color: #0076B6;
			}
		});
	}

	&__image {
		height: 92px;
		width: 92px;
		border-radius: 50%;
		padding: 4px;
		border: 1px solid transparent;

		&:active {
			border-color: #0076B6;
		}

		.tablet-and-up-styles({
			height: 112px;
			width: 112px;
		});

		.desktop-min-only-styles( {
			&:hover {
				border-color: #0076B6;
			}
			height: 100%;
			width: 100%;
		});
	}
}

// HangingCarousel
.hanging-carousel {
	&__container {
		display: flex;
		flex-direction: column;
	}

	&__banner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px 16px 80px;
		background-color: #01305F;
		color: #fff;

		.desktop-min-only-styles({
			flex-direction: row;
			align-items: flex-end;
			padding: 40px 40px 232px;
		});
	}

	&__buttons {
		display: flex;
	}

	&__link {
		margin: 20px 0 0;
		.desktop-min-only-styles({
			display: none;
		});
	}

	&__content {
		padding: 0 16px;
		overflow: hidden;
		background-color: transparent;
		margin-top: -60px;

		.desktop-min-only-styles({
			padding: 0 40px;
			margin-top: -200px;
		})
	}

	&__item {
		display: flex;
		padding-right: 8px;

		.desktop-min-only-styles({
			padding-right: 16px;
		});
	}

	&__viewport {
		.list-slider-auto();
		.list-slider-scroll();
		.desktop-min-only-styles({
			.list-slider-auto();
			[data-list-slider-track] {
				overflow-y: unset;
			}
		});
	}

	&__track {
		padding-bottom: 8px;
		.custom-horizontal-scrollbar(#B2B8D7, transparent, 4px);
	}
}

// PersonaCard
.persona-card {
	position: relative;
	border-radius: 6px;
	border: 1px solid #CBD0E4;
	text-decoration: none;
	color: #000;

	&::after {
		content: '';
		position: absolute;
		top: -1px;
		left: -1px;
		width: calc(~"100% + 2px");
		height: calc(~"100% + 2px");
		border-radius: 6px;
		border: 1px solid #CBD0E4;
		transition: all .2s linear;
	}

	&:active::after {
		border: 2px solid #CBD0E4;
	}

	.desktop-min-only-styles({
		&:hover::after {
			border: 2px solid #CBD0E4;
		}
	});

	&__container {
		height: 354px;
		width: 214px;
		.desktop-min-only-styles({
			height: 462px;
			width: 278px;
		});
	}

	&__image {
		border-radius: 5px 5px 0 0;
		height: 250px;
		width: 100%;
		object-fit: cover;

		.desktop-min-only-styles({
			height: 320px;
		});
	}

	&__label {
		padding: 10px 12px;

		.desktop-min-only-styles({
			padding: 14px;
		});
	}

	&__title {
		font-size: 1.1rem;
		line-height: 1.2;
		font-weight: bold;
		margin-bottom: 4px;

		.desktop-min-only-styles({
			font-size: 1.4rem;
		});
	}

	&__description {
		font-size: .9rem;
		line-height: 1.2;

		.desktop-min-only-styles({
			font-size: 1.1rem;
		});
	}
}

// -- SlenderCarousel --
.slender-carousel {
	&__buttons {
		.desktop-min-only-styles({
			display: flex;
			justify-content: flex-end;
			margin-right: 40px;
			padding-bottom: 16px;
		});
	}

	&__container {
		display: flex;
		flex-direction: column;
		background-color: #F2F3F8;

		.desktop-min-only-styles( {
			flex-direction: row;
		});
	}

	&__banner {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 32px 16px 16px;
	}

	&__title {
		font-size: 1.75rem;
		margin-bottom: 8px;
		text-align: center;

		.desktop-min-only-styles({
			font-size: 2rem;
			width: 400px;
		});
	}

	&__display {
		padding: 0 16px 16px;
		overflow: hidden;

		.desktop-min-only-styles({
			padding: 16px 0 8px 16px;
		});
	}

	&__viewport {
		.list-slider-auto();
		.list-slider-scroll();

		.desktop-min-only-styles( {
			margin-right: 40px;

			.list-slider-auto();

			[data-list-slider-track] {
				overflow-y: unset;
			}
		}

		);
	}

	&__track {
		padding-bottom: 8px;
		.custom-horizontal-scrollbar(#B2B8D7, transparent, 4px);
	}

	&__item {
		padding-right: 8px;

		.desktop-min-only-styles( {
			padding-right: 12px;
		});

		&:last-of-type {
			padding: 0;
		}
	}
}

//Featured Products Widget
.featured-widget {
	&__wrapper {
		display: grid;
		gap: 35px;

		.tablet-and-up-styles( {
			gap: 12px;
			grid-template-columns: 1fr 1fr;
		});

		.desktop-min-only-styles( {
			gap: 20px;
		});
	}

	&__item {
		border: 2px solid #E5E7F1;
		border-radius: 6px;
		display: flex;
		min-height: 240px;

		.styles-at-min(850px, {
			min-height: 280px;
		});

		.desktop-min-only-styles({
			min-height: 314px;
		});

		.blain-dropdown-arrow {
			left: 60px !important;

			.tablet-and-up-styles({
				left: 50px !important;
			});

			.desktop-min-only-styles({
				left: 175px !important;
			});
		}

		.blain-dropdown {
			left: -55px !important;

			.tablet-and-up-styles({
				left: -45px !important;
			});

			.desktop-min-only-styles({
				left: -170px !important;
			});
		}
	}

	&__item:first-child {

		.blain-dropdown {
			left: -27px !important;
		}

		.blain-dropdown-arrow {
			left: 30px !important;
		}
	}

	&__info {
		margin: 20px 0px 20px 20px;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;

		.red-btn {
			margin-top: 15px;
			background-color: @DR;
			border: 2px solid #C96E6F;
			border-radius: 6px;
			font: normal normal bold 1rem/normal Roboto;
			width: 100%;
			padding: 12px 18px;

			&:hover {
				background-color: tint(@BR, 10%);
			}

			&:active {
				background-color: @DR;
			}

			.mobile-and-up-styles({
				width: 150px;
			});

			.tablet-and-up-styles({
				font-size: 1.25rem;
			});

			.desktop-min-only-styles({
				width: 100%;
			});
		}
	}

	&__product-name {
		font: normal normal bold 1.125rem/1.25 Roboto;
		margin-bottom: 0px;

		.tablet-and-up-styles({
			font-size: 1.25rem;
		});

		.desktop-min-only-styles({
			font-size: 1.5rem;
		});
	}

	&__text {
		.price-wrapper {
			padding: 0px !important;
			margin: 0px !important;

			.price-alt {
				margin-top: 12px;
			}

			.price-prefix {
				margin-top: 12px;
			}
		}
	}

	&__image-container {
		flex: 1;
		max-width: 230px;

		.styles-at-min(850px, {
			max-width: 280px;
		});
	}

	&__image {
		background-position: 0 50%;
		background-repeat: no-repeat;
		background-size: 115%;
		height: 100%;
		width: 100%;
	}

	.promo-text {
		.tablet-and-up-styles( {
			margin-top: 12px;
		});
	}
}

.featured-widget-size-3 {
	.featured-widget {
		&__wrapper {
			grid-template-columns: 1fr;
			gap: 15px;

			.tablet-only-styles( {
				gap: 10px;
			});

			.styles-at-min(850px, {
				grid-template-columns: repeat(3, 1fr);
			});
		}

		&__product-name {
			.styles-at-min(850px, {
				font-size: 1rem;
			});

			.desktop-min-only-styles({
				font-size: 1.5rem;
			});
		}

		&__large-name {
			.desktop-min-only-styles({
				font-size: 1.25rem;
			});
		}

		//Styles for making the red button be at the bottom of the
		.ms-styles({
			&__item {
				position: relative;
			}

			&__info {
				margin-bottom: 75px;
			}

			&__image-container{
				max-width: 150px;
			}

			&__image {
				background-position: unset;
			}
		});
	}

	.red-btn {
		width: 100%;
		z-index: 2;

			.styles-at-min(850px, {
				width: 150%
			});

			.mobile-and-up-styles({
			width: 200px;
			});

		.ms-styles({
			position: absolute;
			width: auto;
			left: 15px;
			right: 15px;
			bottom: 15px;
		});
	}
}


//Hero Widget Styles
.hero-widget {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	.mobile-styles({
		background-position: top center;
		background-size: contain;
	});

	&__gradient {
		background: transparent linear-gradient(-180deg, #013B5F00 10%, #01305F 24%) 0% 0% no-repeat padding-box;

		.mobile-and-up-styles({
			background: transparent linear-gradient(-180deg, #013B5F00 10%, #01305F 29%) 0% 0% no-repeat padding-box;
		});

		.tablet-and-up-styles( {
			background: transparent linear-gradient(270deg, #013B5F00 10%, #01305F 50%) 0% 0% no-repeat padding-box;
		});

		.desktop-min-only-styles({
			background: transparent linear-gradient(270deg, #013B5F00 35%, #01305F 60%) 0% 0% no-repeat padding-box;
		});
	}

	&__container {
		margin: 0 auto;
		max-width: 1540px;
		padding: 200px 10px 20px 10px;

		.mobile-and-up-styles({
		padding-top: 220px;
		});

		.tablet-and-up-styles({
			padding: 100px 0 0;
		});
	}

	&__title {
		color: #fff;
		margin-bottom: 10px;
		font: normal normal 500 1.75rem/1.2 Roboto;

		.tablet-and-up-styles( {
			font-size: 2rem;
		});

		.desktop-min-only-styles({
			font-size: 2.25rem;
		});
	}

	&__big-text {
		color: #E31B23;
		font: normal normal bold 2.75rem/1 Roboto Condensed;

		.tablet-and-up-styles({
			font-size: 4.5rem;
		});

		.desktop-min-only-styles({
			font-size: 6.25rem;
		});
	}

	&__subtitle {
		color: #fff;
		margin-bottom: 15px;
		font: normal normal normal 1.375rem/1.3 Roboto;

		.tablet-and-up-styles( {
			width: 55%;
			font-size: 1.5rem;
		});
	}

	&__line {
		.tablet-and-up-styles({
			padding: 0 10px 20px 40px;
		});

		.styles-at-min(1540px, {
			border-left: 4px solid #fff;
		});
	}

	&__category-cards {
		display: grid;
		gap: 8px;
		grid-template-columns: 1fr;

		.tablet-and-up-styles({
			gap: 12px;
			grid-template-columns: repeat(5, minmax(auto, ~"min(136px, 18%)"));
		});

		.desktop-only-styles({
			grid-template-columns: repeat(auto-fit, minmax(auto, 10%));
		});
	}

	&__category-li {
		display: inline-flex;
	}

	&__card {
		position: relative;
		display: flex;
		background-color: white;
		padding: 6px 6px 8px 6px;
		border-radius: 6px 6px 0px 0px;
		text-decoration: none;
		text-align: left;
		align-items: center;
		width: 100%;

		.mobile-and-up-styles({
			width: 60%;
		});

		.tablet-and-up-styles({
			flex-direction: column;
			padding-bottom: 12px;
			text-align: center;
			width: 100%;
		});

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;
			border-bottom: 2px solid #B2B8D7;
			transition: all .2s linear;
		}

		&:hover:after {
			border-bottom: 4px solid #B2B8D7;
		}
	}

	&__hidden-card {
		display: none;

		.desktop-only-styles({
			display: inline-flex;
		});
	}

	&__category-image {
		max-width: 50px;
		max-height: 50px;

		.tablet-and-up-styles({
			max-width: 135px;
			max-height: 135px;
			width: 100%;
		});
	}

	&__category-name {
		text-transform: uppercase;
		font: normal normal bold 1rem/1.3 Roboto;
		margin-left: 8px;
		color: black;

		.tablet-and-up-styles({
			margin-left: 0px;
			margin-top: 8px;
			font-size: 0.875rem;
		});

		.desktop-only-styles({
			font-size: 1rem;
		});
	}

	&__view-all-wrapper {
		width: 100%;
		text-align: right;
		margin-right: 8px;

		.mobile-and-up-styles({
			text-align: left;
		});

		.desktop-only-styles({
			display: none;
		});
	}

	&__view-all-button{
		color: #fff;
		display: inline-flex;
		margin-top: 15px;

		&::after {
			border-color: #fff;
		}
	}

	&__view-all, &__view-less {
		font-size: 0.875rem;

		.icon {
			font-size: 0.75rem;
			padding-left: 4px;
		}
	}

	&__view-less {
		display: none;
	}

	&--redirect {
		.hero-widget {
			&__gradient {
				background: transparent linear-gradient(-180deg, #013B5F00 14%, #000 23%) 0% 0% no-repeat padding-box;

				.mobile-and-up-styles({
					background: transparent linear-gradient(-180deg, #013B5F00 10%, #000 35%) 0% 0% no-repeat padding-box;
				});

				.tablet-and-up-styles( {
					background: transparent linear-gradient(270deg, #013B5F00 10%, #000 50%) 0% 0% no-repeat padding-box;
				});

				.desktop-min-only-styles({
					background: transparent linear-gradient(270deg, #013B5F00 35%, #000 60%) 0% 0% no-repeat padding-box;
				});
			}

			&__container {
				.ms-styles({
					padding-top: 160px;
				});

				.tablet-and-up-styles({
					padding-top: 80px;
				});
			}

			&__title {
				font: normal normal 500 2rem/1 Roboto;

				.tablet-and-up-styles({
					font-size: 3.125rem;
				});

				.desktop-min-only-styles({
					font-size: 4.375rem;
				});
			}

			&__subtitle {
				font-size: 1.375rem;

				.tablet-and-up-styles({
					font-size: 1.5rem;
				});

				.desktop-min-only-styles({
					font-size: 1.75rem;
				});
			}

			&__buttons {
				padding-bottom: 10px;
				.mobile-styles( {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-auto-rows: 1fr;
					width: 75%;
					gap: 8px;
				});

				.ms-styles( {
					width: 100%;
				});
			}

			&__button-li {
				display: inline-flex;
			}

			&__btn-gray {
				background: #FFFFFF 0% 0% no-repeat padding-box;
				border: 2px solid tint(@CL, 80%);
				border-radius: 6px;
				text-align: center;
				color: @DB;
				display: inline-block;
				text-decoration: none;
				text-transform: uppercase;
				font: normal normal 500 1rem/1.3 Roboto;
				letter-spacing: 0.56px;
				padding: 9px 20px 7px 20px;
				margin-right: 15px;
				margin-top: 15px;
				width: 100%;

				&:hover {
					background-color: tint(@LB, 90%);
					border: 2px solid tint(@LB, 60%);
				}

				&:active {
					background-color: tint(@LB, 80%);
					border: 2px solid tint(@LB, 60%);
				}

				.tablet-styles( {
					font-size: 0.875rem;
					padding: 8px 15px 6px 15px;
					margin-right: 10px;
					margin-top: 10px;
				});

				.mobile-styles( {
					display: flex !important;
					align-items: center;
					justify-content: center;
					margin: 0px;
				});
			}

			&__shop-all-categories {
				color: #71C8FF;
				display: inline-flex;
				margin-top: 15px;
				font: normal normal 500 0.75rem/1.3 Roboto;

				&::after {
					border-color: #2998DE;
				}

				> span {
					font-size: 0.625rem;
					padding: 4px;
				}

				&:hover {
					color: #2998DE;
				}

				.desktop-min-only-styles( {
					font-size: 0.875rem;
				});
			}
		}
	}

	&--open {
		.hero-widget__hidden-card {
			display: inline-block;

			.mobile-and-up-styles({
				display: inline-flex;
			})
		}

		.hero-widget__gradient {
			.ms-styles({
				background: transparent linear-gradient(-180deg, #013B5F00 7%, #01305F 18%) 0% 0% no-repeat padding-box;
			});

			.mobile-styles({
				background: transparent linear-gradient(-180deg, #013B5F00 7%, #01305F 21%) 0% 0% no-repeat padding-box;
			});
		}

		.hero-widget__view-all{
			display: none;
		}

		.hero-widget__view-less {
			display: unset;
		}
	}
}