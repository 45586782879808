
@import "~lessRoot/_mixins.less";
.drawer-head {
	background-color: #fff;
	font-size: 18px;
	border-bottom: 2px solid tint(@CL, 80%);
	padding: 15px 10px 20px 10px;

	&__welcome {
		display: flex;
		justify-content: space-between;
	}

	.blue-link {
		color: @BB;
		cursor: pointer;
		font-size: 13px;
		font-weight: 500;
		text-decoration: none;
		align-self: end;
	}

	&__back-btn {
		.button-reset();
		cursor: pointer;
		color: @BB;
		font-size: 14px;
		font-weight: bold;
		max-width: 70px;
		.icon {
			font-weight: lighter;
			font-size: 11px;
		}
	}
	.drawer-head-top {
		margin-bottom: 20px;
		h2 {
			color: shade(@CL, 80%);
			font-size: 18px;
			font-weight: bold;
			line-height: 1;
			margin-bottom: 0;
		}
	}
	.uppercase-wide {
		color: shade(@CL, 40%);
		font-size: 12px;
		text-transform: uppercase;
		line-height: 1.25;
	}
	.store-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.local-store {
			font-size: 18px;
			line-height: 1.25;
			font-weight: bold;
			color: shade(@CL, 80%);
		}
	}
	.active-item {
		margin-top: 6px;
		font-size: 14px;
		line-height: 16px;
		color: #4c5271;
		white-space: nowrap;
		overflow: hidden;
	}
}
