
@import "~lessRoot/_mixins.less";

.connect-rewards-styles {

	&__header {
		color: #191B25;
		font: normal normal bold 1.5rem/1.25 Roboto;
		margin-bottom: 5px;
	}

	&__header:focus {
		outline: none;
	}

	&__subtitle {
		color: #4C5271;
		margin-bottom: 30px;
		font-size: 1rem;
		line-height: 1.25;
	}

	&__error-banner {
		margin-bottom: 15px !important;
		display: flex !important;
	}

	&__error-banner-text {
		color: black;
		letter-spacing: 0.42px;
		line-height: 1.29;
		font-size: 0.875rem;
	}

	&__form-area {
		background-color: #f2f3f8;
		color: black;
		padding: 20px 15px;
		margin-bottom: 30px;
	}

	&__connect-button {
		max-width: 100% !important;
		width: 100%;
		margin-top: 20px;
		font-size: 1rem !important;

		.mobile-and-up-styles({
			font-size: 1.125rem !important;
		});
	}

	&__footer-text {
		color: #4C5271;
		margin-bottom: 4px;
		font-size: 0.875rem;
	}

	&__link-button {
		font-size: 14px;
	}

	.blain-text-field__optional-notice, .horizontal-text-divider__text {
		color: #4C5271;
	}
}

