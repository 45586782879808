
@import "../../../less/_mixins.less";

.blain-text-field {
	position: relative;
	.desktop-min-only-styles({flex-grow: 1;});
	&__label {
		display: block;
		color: shade(@CL, 20%);
		font-size: 0.875rem;
		line-height: 1.3;
		margin-bottom: 3px;
	}

	&__inputs {
		display: flex;
		flex-direction: column;
		.desktop-min-only-styles(
			{flex-direction: row; align-items: flex-start;}
		);
	}

	&__text-input {
		padding: 10px 6px;
		font-size: 1rem;
		line-height: 1.3125;
		border: 1px solid tint(@CL, 20%);
		width: 100%;
		height: 40px;
		border-radius: 2px;
		&::placeholder {
			color: shade(@CL, 15%);
			opacity: 1;
		}

		&:focus {
			border-bottom: 2px solid black;
			padding-bottom: 9px;
		}

		&.error {
			border-bottom: 2px solid #b12f31;
			padding-bottom: 9px;
		}

		&--has-icon {
			padding-right: 38px;
		}

		&--has-two-icons {
			padding-right: 65px;
		}
	}

	&__field-state-icon {
		position: absolute;
		top: 9px;
		right: 10px;
		font-size: 21px;
		&.valid {
			color: @AAEME;
		}

		&.error {
			color: #b12f31;
		}
	}

	&__field-state-show {
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 9px;
		font-size: 21px;
		color: #086CAC;
	}

	&__field-state-both {
		right: 35px;
	}

	&__required-notice {
		color: @DR;
		font-style: italic;
		font-size: 0.875rem;
	}

	&__optional-notice {
		color: #6b749f;
		font-style: italic;
		font-size: 0.875rem;
	}

	textarea {
		min-height: 100px;
	}

	&__counter {
		&--max-exceeded {
			color: @DR;
		}
	}
}
