
@import '../../less/_mixins.less';
.blain-modal-vue {
	display: block !important;
	padding: 5%;

	.blain-modal-content {
		max-width: 100%;
	}

	/deep/ .blain-modal-clean-white {
		border: none;
	}

	.close-btn {
		position: absolute;
		right: 5px;
		top: -23px;
		color: @BB;
		font-size: 28px;
		line-height: 20px;
		font-weight: bold;
		background-color: transparent;
		border: none;
	}
}
.vue-modal-enter-active, .vue-modal-leave-active {
	transition: opacity 0.2s
}

.modal.vue-bs-modal {
	background-color: rgba(0,0,0,0.6);
	display: block;
	overflow-y: scroll;
}

.vue-modal-enter {
	opacity: 0;
}

.vue-modal-leave-active {
	opacity: 0;
}

.vue-bs-modal .modal-header h2,
.vue-bs-modal .modal-header h3,
.vue-bs-modal .modal-header h4 {
	margin-bottom: 0;
}

.blain-modal-content-rounded {
	border-radius: 8px;
}

.blain-modal-content {

	&.blain-modal-white {
		border: none;
		padding: 0;
	}

	&.blain-modal-clean-white {

		/deep/ .close-btn {
			font-size: 1rem;
			top: 14px;
			right: 14px;
		}

		.tablet-and-up-styles({
			/deep/ .close-btn {
				top: 20px;
				right: 14px;
			}
		});
	}
}
.clear-head {
	display: flex;
	justify-content: space-between;
	.modal-left-decor {
		width: 10px;
		height: 30px;
		&.color-grey {
			background-color:#EAECF4;
		}
	}
	span {
		color: #656E97;
	}
	.align-center {
		align-items: center;
		* {
			margin-right: 5px;
		}
		span {
			font-weight: 500;
			font-size: 14px;
			color: #656E97;
		}
		.icon {
			font-size: 22px;
			color: #98A1CA;
		}
	}
}
.hide-button {
	.close-btn {
		display: none;
	}
}
@media screen and(max-width: @mobile-small) {
	.blain-modal-content {
		padding: 10px;
	}
}
