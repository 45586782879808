.warning-message {
	display: flex;
	align-items: center;
	border-left: 4px solid #FFDB83;
	background-color: #FFF1CF;
	font-weight: 500;
	color: #A86700;
	font-size: 0.75rem;
    line-height: 1.167;
    padding: 3px 0;

	.icon {
		font-size: 2em;
	}
}

