
@import "../../../less/_mixins.less";

.blain-form-select {
	position: relative;

	.desktop-min-only-styles({
		flex-grow: 1;
	});

	&--disabled &__inner {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&__label {
		display: block;
		color: shade(@CL, 20%);
		font-size: 0.875rem;
		line-height: 1.3;
		margin-bottom: 3px;
	}

	&__label-text {
		font-weight: 500;
	}

	&__inside-label {
		font-size: 0.875rem;
		line-height: 1.36;
		font-weight: bold;
		position: absolute;
		padding-left: 6px;
		pointer-events: none;
	}

	&__inner {
		display: flex;
		align-items: center;
	}

	&__select {
		color: #000000;
		font-size: 1rem;
		line-height: 1.3125;
		padding: 8px 38px 8px 6px;
		border: 1px solid tint(@CL, 20%);
		border-radius: 2px;
		background-color: white;
		width: 100%;
		height: 40px;

		&:focus {
			border-bottom: 2px solid black;
			padding-bottom: 7px;
			outline: none;
		}

		&.error {
			border-bottom: 2px solid #B12F31;
			padding-bottom: 9px;
		}

		&::-ms-expand {
			display: none;
		}

		&:focus {
			outline: none;
		}

		&--placeholder {
			color: shade(@CL, 15%);
		}

		option:not([disabled]) {
			color: black;
		}
	}

	&__icon-wrapper {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		bottom: 0;
		right: 0;
		padding-right: 10px;
		pointer-events: none;
	}

	&__icon {
		font-size: 1.3125rem;
		color: #2487C4;
	}

	&--disabled &__icon {
		color: @CL;
	}

	&__required-notice {
		color: @DR;
		font-style: italic;
		font-size: 0.875rem;
	}

	&__optional-notice {
		color: #6b749f;
		font-style: italic;
		font-size: 0.875rem;
	}

}
