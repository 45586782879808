
@import '../../../less/_mixins.less';
.footer-store-info {
	max-width: 180px;
	width: 100%;

	h2, h3 {
		color: #4C5271;
	}

	h2 {
		margin-bottom: 8px;
	}

	table {
		max-width: 150px;
		width: 100%;
		color: #666;
	}

	td {
		padding: 3px 0;
	}

	a.footer-store-btn {
		border: solid 2px #A7CFE7;
		border-radius: 6px;
		color: @DB;
		padding: 0.6rem;
		transition: all 0.2s;

		&:hover {
			text-decoration: none;
			border-color: #2487c4;
		}
	}
}

.footer-store-body {
	border: solid 2px #E5E7F1;
	border-radius: 6px;
	padding: 10px;

	.footer-divider {
		background-color: #E5E7F1;
	}

	.hours-status{
		font-weight: 500;
		color: #008040;
		cursor: pointer;
	}
}
