/*=======================================================================
== Global Elements
========================================================================*/

html.modal-open,
html.menu-open {
	height: 100%;
	overflow: hidden;
	body {
		height: 100%;
		overflow: hidden !important;
		position: relative;
	}
}

body {
	display: block !important;
	font-family: @font-body;
	min-width: 320px;
	overflow-x: hidden;
	width: 100%;
	background-color: white;

	&.modal-open {
		height: 100%;
		overflow: hidden;
	}
	&.shadowed::after {
		content: "";
		background-color: rgba(0,0,0,0.5);
		display: block;
		position: fixed;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: 11;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: @font-headers;
	line-height: 1.3em;
}

h1, .h1 {
	font-size: 32px;
	margin-bottom: 20px;
}

h2, .h2 {
	font-size: 28px;
	margin-bottom: 20px;
}

h3, .h3 {
	font-size: 24px;
	margin-bottom: 15px;
}

h4, .h4 {
	font-size: 20px;
	margin-bottom: 15px;
}

h5, .h5 {
	font-size: 18px;
	margin-bottom: 15px;
}

p {
	font-size: 16px;
	line-height: 1.5em;
	margin-bottom: 15px;
}

p.small,
p.fine-print {
	font-size: 12px !important;
}

.mobile-styles({
	h1, .h1 {
		font-size: 28px;
	}

	h2, .h2 {
		font-size: 22px;
	}

	h3, .h3 {
		font-size: 18px;
	}

	h4, .h4 {
		font-size: 17px;
	}

	h5, .h5 {
		font-size: 16px;
	}
});
input[type='number'] {
	-moz-appearance: textfield !important;
}
input,
textarea {
	appearance: none;
	border: none;
	&:focus {
		outline: none;
	}
}

textarea {
	font-family: @font-body;
}

button {
	cursor: pointer;
	font-family: @font-buttons;
}

.tr-btn {
	background: transparent;
	border: none;
	padding: 0;
	margin: 0;
	appearance: none;
}

ul.bullets {
	list-style: disc;
	padding-left: 20px;
	li {
		margin-bottom: 10px;
	}
}

/*=======================================================================
== Global Classes
========================================================================*/

.clear-fix, .clearfix {
	.clearfix();
}

.block {
	display: block;
}

.block-tablet {
	.tablet-styles({
		display: block !important;
	});
}

.block-mobile {
	.mobile-styles({
		display: block !important;
	})
}

.block-ms {
	.ms-styles({
		display: block !important;
	})
}

.inline {
	display: inline;
}

.inline-block {
	display: inline-block;
}

.none {
	display: none;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

.relative {
	position: relative;
}

.container {
	margin: 0 auto;
	max-width: @desktop;
}

// Hide, but make available for screen readers
.hide-aria {
	position: absolute;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0;
	border: 0;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.visually-hidden {
	.visually-hidden();
}

// Color-based classes
.red {
	color: @red-main;
}

.rewards-red {
	color: #B12F31 !important;
}

.green {
	color: @green-main;
}

.blue {
	color: @blue-main;
}

.dark-blue {
	color: #3878AD;
}

.darkest-blue {
	color: #044A76;
}

.light-blue {
	color: @blue-light;
}

.light {
	color: #999 !important;
}

.white {
	color: #FFF;
}

.black {
	color: #000;
}

.check-green {
	color: #74AA50;
}

.orange {
	color: #EFA131;
}

.grey {
	color: #666;
}

.dark-grey {
	color: #777;
}

.light-grey {
	color: #CCC;
}

.aaoch {
	color: @AAOCH;
}

.ochre {
	color: @AAOCH;
}

.bg-red {
	background-color: @red-main;
}

.bg-blue {
	background-color: @blue-main;
}

.bg-blue-light {
	background-color: @blue-light;
}

.bg-blue-lighter {
	background-color: #E8EFF5;
}

.bg-white {
	background-color: #FFF;
}

.bg-grey {
	background-color: #EEE;
}

.bg-grey-main {
	background-color: @grey-main;
}

.bg-transparent {
	background-color: transparent;
}

.bold {
	font-weight: bold;
}

.medium-bold {
	font-weight: 500;
}

.normal {
	font-weight: normal;
}

.italic {
	font-style: italic;
}

.text-dec-none {
	text-decoration: none;
}

.underline {
	text-decoration: underline;
}

// Text alignment classes
.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.text-left {
	text-align: left !important;
}

.text-uc {
	text-transform: uppercase !important;
}


.float-left {
	float: left;
}

.float-right {
	float: right;
}

.line-through {
	text-decoration: line-through;
}

// Text Size
.fs-10 {
	font-size: 10px;
}

.fs-11 {
	font-size: 11px;
}

.fs-12 {
	font-size: 12px;
}

.fs-13 {
	font-size: 13px;
}

.fs-14 {
	font-size: 14px;
}

.fs-16 {
	font-size: 16px;
}

.fs-18 {
	font-size: 18px;
}

.fs-20 {
	font-size: 20px;
}

.fs-22 {
	font-size: 22px;
}

.fs-24 {
	font-size: 24px;
}

.tablet-styles({
	.fs-10-t {
		font-size: 10px !important;
	}

	.fs-11-t {
		font-size: 11px !important;
	}

	.fs-12-t {
		font-size: 12px !important;
	}

	.fs-14-t {
		font-size: 14px !important;
	}

	.fs-16-t {
		font-size: 16px !important;
	}

	.fs-18-t {
		font-size: 18px !important;
	}

	.fs-20-t {
		font-size: 20px !important;
	}

	.fs-22-t {
		font-size: 22px !important;
	}

	.fs-24-t {
		font-size: 24px !important;
	}
});

.mobile-styles({
	.fs-10-m {
		font-size: 10px !important;
	}

	.fs-11-m {
		font-size: 11px !important;
	}

	.fs-12-m {
		font-size: 12px !important;
	}

	.fs-14-m {
		font-size: 14px !important;
	}

	.fs-16-m {
		font-size: 16px !important;
	}

	.fs-18-m {
		font-size: 18px !important;
	}

	.fs-20-m {
		font-size: 20px !important;
	}

	.fs-22-m {
		font-size: 22px !important;
	}

	.fs-24-m {
		font-size: 24px !important;
	}
});

// Padding and margin

.no-margin {
	margin: 0 !important;
}
.no-margin-top, .no-mt {
	margin-top: 0 !important;
}
.no-margin-bottom, .no-mb {
	margin-bottom: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-nav {
	padding: 0 !important;

	nav {
		max-width: 1440px;
		margin: 0 auto;

		.breadcrumb {
			padding: 15px;
			margin-bottom: 0;
		}
	}
}

.margin-x-auto {
	margin-left: auto;
	margin-right: auto;
}

.margin-y-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.margin-auto {
	margin: auto;
}

.mb {
	margin-bottom: 15px;
}

.mb-sm {
	margin-bottom: 10px;
}

.mb-tiny {
	margin-bottom: 5px;
}

.mb-lg {
	margin-bottom: 20px;
}

.mb-xl {
	margin-bottom: 30px;
}

.mt {
	margin-top: 15px;
}

.mt-sm {
	margin-top: 10px;
}

.mt-tiny {
	margin-top: 5px;
}

.mt-lg {
	margin-top: 20px;
}

.mr {
	margin-right: 15px;
}

.mr-sm {
	margin-right: 10px;
}

.mr-tiny {
	margin-right: 5px;
}

.mr-lg {
	margin-right: 20px;
}

.ml {
	margin-left: 15px;
}

.ml-sm {
	margin-left: 10px;
}

.ml-tiny {
	margin-left: 5px;
}

.ml-lg {
	margin-left: 20px;
}

.mobile-styles({
	.mr-tiny-m{
		margin-right: 5px;
	}
});


// Image alignment

.alignleft {
	display: block;
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;

	.tablet-and-up-styles({
		margin-left: 0;
		margin-right: 15px;
		float: left;
	});
}

.alignright {
	display: block;
	margin-bottom: 15px;

	.tablet-and-up-styles({
		margin-left: 15px;
		margin-right: 0;
		float: right;
	});
}

// Checklist
.checklist,
.checklist li {
	padding: 0;
	margin: 0;
	list-style: none;
}

.checklist li {
	margin: 1em;
	margin-left: 8px;

	&:before {
		content: '\f00c';
		font-family: 'FontAwesome';
		float: left;
		margin-left: -1.5em;
		color: #090;
	}
}

.img-fill {
	img {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

img[data-blain-src] {
	display: none;
}


.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.no-select {
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
}

.no-decoration {
	text-decoration: none;
}

.icon.icon-tiny {
	font-size: 8px;
}

.icon.icon-small {
	font-size: 10px;
}

.inset {
	top: 0; right: 0; bottom: 0; left: 0;
}

.ellipsis {
	text-overflow: ellipsis;
	white-space: normal;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
}

/*=======================================================================
== Buttons
========================================================================*/

.btn-reset {
	.button-reset();
}

.confirm-btn {
	.red-btn();
	transition: background-color 0.3s;
	&:hover {
		background-color: @red-main-hover;
	}
}

.cancel-btn {
	background: #999;
	.block-btn(none, 8px);
	color: #fff;
	font-size: 18px;
	transition: background-color 0.3s;
	&:hover {
		background-color: #ccc;
	}
}

.blue-btn {
	.blue-btn();
}

.red-btn {
	.red-btn();
}

.light-blue-btn {
	.block-btn(none, 8px);
	border: solid 2px lighten(@LB, 35%);
	color: #1D6C9D;
}

.white-blue-btn {
	.block-btn();
	background: #fff;
	border: solid 2px @blue-main;
	color: @blue-main;
	font-weight: bold;
	transition: background-color 0.3s;
	&:hover {
		background-color: #f4f7f9;
	}
}

.white-outline-btn {
	background: transparent;
	border: solid 2px white;
	border-radius: 6px;
	color: white;
	display: inline-block;
	font-weight: 500;
	text-decoration: none;
	padding: 8px;
	transition: background-color 0.3s, color 0.3s;
	&:hover {
		background-color: white;
		color: @blue-main;
	}
}

.btn.red-outline-btn,
.btn.blue-outline-btn {
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	line-height: 21px;
	text-decoration: none;
	padding: 8px;
	transition: background-color 0.3s, color 0.3s;
	border-radius: 6px;

	.mobile-styles({
		font-size: 14px;
		line-height: 19px;
		padding: 7px;
	})
}

.btn.red-outline-btn {
	background: transparent;
	border: solid 2px @red-main;
	color: @red-main;

	&:hover {
		background-color: @red-main;
		color: white;
	}

	&.disabled {
		background-color: @red-main;
		color: white;
		opacity: 0.6;
	}
}

.btn.blue-outline-btn {
	background: transparent;
	border: solid 2px @blue-main;
	color: @blue-main;

	&:hover {
		background-color: @blue-main;
		color: white;
	}
}

.grey-btn {
	.block-btn();
	background: #aaa;
	color: #fff;
}

.grey-bg-btn {
	.block-btn();
	background-color: #f5f5f5;
    color: #024a76;
    font-weight: 700;
    font-size: 14px;
}

.grey-blue-btn {
	.block-btn();
	background: #fff;
	border: solid 4px #ddd;
	color: @blue-main;
	font-weight: bold;
}

.btn {
	.block-btn();
}

.btn.btn-sm {
	display: inline-block;
	font-size: 14px;
	padding: 8px;
}

.btn.btn-md {
	display: inline-block;
	font-size: 16px;
	padding: 12px 8px;
}

.btn.btn-lg {
	display: inline-block;
	font-size: 18px;
	padding: 16px 10px;
}

.btn.btn-block {
	display: block !important;
}

.btn-transparent {
	border: none;
	background: transparent;
}

.blue-underline-btn {
	border-bottom: 1px solid shade(@LB, 20%);
	color: shade(@LB, 20%);
	font-weight: 500;
	text-decoration: none;
}

// Some notes about this class:
// - Usable for any button or a meant to look like a link
// - Has a --padded variant for a new style of button-link that's been popping up more recently
// - Does not follow `btn` convention --- there's an existing class (`link-btn`) that is used
//   similarly, but has varying definitions in different Vue components
// - The --padded variant is similar to, but slightly different from `blue-underline-btn` above.
//   That one is used in a few places and I didn't want to replace it in case it's intentionally
//   different
.link-button {
	.button-reset();
	display: inline-block;
	text-decoration: underline;
	color: shade(@LB, 20%);
	font-size: 1rem; // normalize sizing between button and a. Users of this class will likely need to override this
	white-space: nowrap;

	&--padded {
		text-decoration: none;
		font-weight: 500;
		line-height: 1.35;
		position: relative;

		&::after {
			transition: 150ms ease-in-out;
			content: "";
			width: 100%;
			border-bottom: 1px solid shade(@LB, 20%);
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&:hover {
			&::after {
				transform: scaleY(2);
				transform-origin: bottom;
			}
		}
	}

	&[disabled] {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.pointer {
	cursor: pointer;
}

.inline-block {
	display: inline-block !important;
	width: auto !important;
}

.maxwidth-100,
.maxwidth-120,
.maxwidth-150,
.maxwidth-200,
.maxwidth-250,
.maxwidth-300,
.maxwidth-350,
.maxwidth-400 {
	margin-left: auto;
	margin-right: auto;
}

.maxwidth-100 {
	max-width: 100px;
}

.maxwidth-120 {
	max-width: 120px;
}

.maxwidth-150 {
	max-width: 150px;
}

.maxwidth-200 {
	max-width: 200px;
}

.maxwidth-250 {
	max-width: 250px;
}

.maxwidth-300 {
	max-width: 300px;
}

.maxwidth-350 {
	max-width: 350px;
}

.maxwidth-400 {
	max-width: 400px;
}

.maxwidth-600 {
	max-width: 600px;
}

.maxwidth-800 {
	max-width: 800px;
}

/*=======================================================================
  Media-Break Display Classes
========================================================================*/

.hide-desktop {
	.desktop-min-only-styles({
		display: none !important;
	});
}

.hide-tablet {
	.tablet-styles({
		display: none !important;
	});
}

.hide-mobile {
	.mobile-styles({
		display: none !important;
	});
}

.hide-ms {
	.ms-styles({
		display: none !important;
	});
}

.hide-iphone {
	.iphone-styles({
		display: none !important;
	});
}

.show-mobile {
	.tablet-and-up-styles({
		display: none !important;
	});
}

//NOTE: This is not the same as the media query desktop min. To be removed later. (Use .hide-desktop instead)
.hide-desktop-min {
	.styles-at(1220px, {
		display: none !important;
	});
}

//NOTE: This is not the same as the media query desktop min. To be removed later. (Use .hide-tablet instead)
.show-desktop-min {
	.styles-at-min(1220px, {
		display: none !important;
	});
}

/*=======================================================================
== Flex Classes
========================================================================*/

.flex {
	display: flex;
}

.flex-row {
	align-items: center;
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-column-tablet {
	.tablet-styles({
		flex-direction: column;
	})
}

.flex-column-mobile {
	.mobile-styles({
		flex-direction: column;
	})
}

.flex-center {
	justify-content: center;
}

.flex-align-center {
	align-items: center;
}

.flex-align-stretch {
	align-items: stretch;
}

.flex-center-all {
	align-items: center;
	justify-content: center;
}

.flex-align-end {
	align-items: flex-end;
}

.flex-align-start {
	align-items: flex-start;
}

.flex-align-stretch {
	align-items: stretch;
}

.flex-end-both {
	justify-content: flex-end;
	align-items: flex-end;
}

.flex-end {
	justify-content: flex-end;
}

.flex-start {
	justify-content: flex-start;
}

.flex-around {
	justify-content: space-around;
}

.flex-between {
	justify-content: space-between;
}

.flex-stretch {
	justify-content: stretch;
}

.flex-evenly {
	justify-content: space-evenly;
}

.flex-justify-left {
	justify-content: left;
}

.flex-grow {
	flex: 1 0 auto;
}

.flex-shrink {
	flex: 0 1 auto;
}

.flex-adapt {
	flex: 1 1 auto;
}

.flex-auto {
	flex: 0 0 auto;
}

.flex-wrap, .wrap {
	flex-wrap: wrap;
}

.items-center {
	align-items: center;
}

/*=======================================================================
== Site Wrapper
========================================================================*/

#wrapper {
	#content {
		margin: 0 auto;
		max-width: 1440px;
		padding: 15px;
		min-height: 90vh;
		.mobile-styles({
            padding: 10px;
        });
	}
	#content.full-width {
		max-width: 100%;
	}
}

#contentWrapper {
	&.shadowed {
		position: relative;
		&:after {
			background-color: rgba(0,0,0,0.5);
			display: block;
			content: "";
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			z-index: 11;
		}
	}
}

.full-width {
	width: 100%;
}

.wf-loading .fa {
	visibility: hidden;
}

.wf-active .fa {
	visibility: visible;
}

/*=======================================================================
== Breadcrumbs
========================================================================*/

#breadcrumbs {
    white-space: nowrap;
	overflow-x: auto;
    position: relative;
	padding: 5px 15px 10px 15px;
	margin-bottom: 10px;
	.custom-horizontal-scrollbar(shade(@LB, 20%));
}

.breadcrumb {

	li {
		display: inline;
		font-size: 14px;

		.tablet-styles({
			font-size: 13px;
		});

		&.home-link {
			align-self: baseline;
		}

		&.divider-before {

			&::before {
				margin: 0 0.25em 0 0.5em;
				color: shade(@CL, 20%);
				content: '/';
			}
		}

		a {
			color: @BB;

			&.no-underline {
				text-decoration: none;
			}

			&.current-page {
				color: shade(@CL, 30%);
			}
		}

		.icon {
			font-size: 22px;
			width: 22px;
			height: 22px;
		}
	}
}

/*============================================================
 == Skeleton Loader
/============================================================*/

.skeleton-loader-global {
	background-image: none;
	animation: skeletonBackgroundAnimation 2s ease-in-out;
	animation-iteration-count: infinite;
	display: block;
	background-color: @CL;
	opacity: 0.3;
}

@keyframes skeletonBackgroundAnimation {
    0%   { opacity: 0.3; }
    50%  { opacity: 0.1; }
    100% { opacity: 0.3; }
}

/*=======================================================================
== Large Spinner
========================================================================*/

// spinner scaling... any units
@spinner-width: 125px;

.spinner {
	margin: 0 auto;
}
p.spinner-title {
	font-size: 24px;
	.mobile-styles({
		font-size: 18px;
	});
}
.ie-10-spinner {
	display: none;
	border: 8px solid #f3f3f3; /* Light grey */
	border-top: 8px solid @blue-main; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: iespin 1s linear infinite;
	margin: 10px auto;
}

html.ie10 .ie-10-spinner {
	display: block;
}

@keyframes iespin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.spinner-loader {
	position: relative;
	margin: 0 auto;
	width: @spinner-width;
	&:before {
		content: '';
		display: block;
		padding-top: 100%;
	}
}

.spinner-circular {
	animation: rotate 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.spinner-path {
	stroke: @blue-main;
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: dash 1.5s ease-in-out infinite;
	stroke-linecap: round;
}

html.ie10 .spinner-loader {
	display: none;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

/*=======================================================================
  Visual Department Nav
========================================================================*/

#visualDeptNav {
	margin: 30px 0.5rem;
	h2 {
		text-align: center;
	}
	a.dept-vis {
		display: block;
		margin-bottom: 20px;
		text-decoration: none;
		div {
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
			height: 150px;
			width: 150px;
		}
		h3 {
			color: #222;
			font-size: 18px;
			font-weight: bold;
			line-height: 1.3em;
			text-align: center;
			text-decoration: none;
			.mobile-styles({
				font-size: 16px;
			});
		}
		img {
			margin-bottom: 10px;
			width: 100%;
		}
	}
}

.page-error-banner {
	background-color: #fff0f1;
	border: solid 2px @red-main;
	color: @red-main;
	margin-bottom: 10px;
	padding: 10px;
}

/*======================================================
  Sliver Banner
=======================================================*/

#sliverBanner {
	background: @green-main;
	padding: 10px 40px;
	position: relative;
	#sliverInner {
		text-align: center;
	}
	p {
		color: #fff;
		font-family: @font-body;
		margin-bottom: 0;
	}
	#closeBanner {
		color: #ffffff;
		cursor: pointer;
		font-size: 20px;
		padding: 8px;
		position: absolute;
		top: 3px;
		right: 5px;
	}
}

/*======================================================
  Cart & Checkout Gift Card Payment Section
=======================================================*/

.rewards-gift-cards-container {
	.rewards-gift-card-form {
		flex: 1 0 70%;
		padding: 13px;
		background-color: #F5F5F5;
		margin: 10px;

		.gift-card-balance {
			div {
				color: #686868;
				margin-left: 10px;
				font-size: 13px;
			}
		}
	}
}
.gift-card-divider {
	.gift-card-divider-line {
		flex: 1 0 44%;
		border-bottom: 2px solid #DDDDDD;
	}
	.gift-card-divider-or {
		flex: 1 0 6%;
		padding: 10px;
	}
}
#giftCardForm {
	padding: 5px 5px 5px 0px;
	.gift-card-content {
		flex-direction: column;
		.form-group {
			width: 100%;
		}
		div {
			width: 100%;
			padding-left: 5px;
			.form-group {
				flex: 1 0 77%;
			}
			.gift-card-apply {
				flex: 1 0 23%;
			}
		}
	}
}
.gc-header {
	margin: 10px 0px 0px 0px;
	text-align: center;
}

.auto-services-container {
	a { text-decoration: none; }
	h2.services-header {
		background-color: #F5F5F5;
		font-size: 16px;
		padding: 12px;
		border-bottom: 5px solid @blue-main;
		font-weight: bold;
	}
	.row {
		padding: 16px 15px;
		margin: 0 auto;
		align-items: flex-start;
	}
    .dept-vis {
		width: 30%;
        font-weight: 700;
		color: #000;
		padding-bottom: 40px;
		.styles-at(1250px, {
			width: 40%;
		});
		.styles-at(425px, {
			width: 100%;
		});
		.service-text {
			font-size: 15px;
			font-weight: bold;
			 .tablet-styles({
				font-size: 14px;
			});
			 .mobile-styles({
				font-size: 20px !important;
			});
		}
		.service-image {
			width: 100%;
			height: 200px;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
			margin-bottom: 10px;
		}
		.services-list-container {
			margin: 0 0 20px 10px;
			height: 110px;
		}
		.service-list {
			font-size: 14px;
			color: #666666;
			font-weight: 500;
			line-height: 22px;
		}
		a {
			width: 100%;
			background-color: #F5F5F5;
			color: @blue-main;
			font-weight: bold;
			font-size: 14px;
			.styles-at(1250px, {
				width: 80%;
			});
			.mobile-styles({
				width: 100% !important;
			});
		}
    }
}

.bs-links {
	margin: 10px 0 20px;
	padding: 10px 0;
	border-top: 1px solid #CCC;
	border-bottom: 1px solid #CCC;
	.styles-at(425px, {
		display: block;
	});
	.bs-links-item {
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		border: 5px solid #015f99;
		p {
			color: #FFF;
			font-size: 18px;
			margin-left: 20px;
			margin-bottom: 0;
			.tablet-styles({
				font-size: 14px;
			});
		}
		background-color: @blue-main;
		width: 250px;
		&:nth-child(2) {
			margin: 0 2%;
		}
		img {
			height: 60px;
			width: auto;
			.tablet-styles({
				height: 30px;
			});
		}
		.styles-at(425px, {
			width: 100%;
			margin: 0 0 10px 0 !important;
		});
	}
}

//Select List dropdown arrow fix
select {
	-webkit-appearance: none;
	-moz-appearance: none;
}

.ordersummary-border-box {
	border-radius: 8px;
	border: 1px solid #CBD0E4;
}

/*======================================================
  Scrollable
=======================================================*/

.scrollable-container {
	.desktop-min-only-styles({
		padding: 1px 0;
		max-height: 250px;
		overflow-y: auto;
	});
}

.scrollable-container::-webkit-scrollbar-track {
	background-color: transparent;
}

.scrollable-container::-webkit-scrollbar {
	width: 2px;
	background-color: transparent;
}

.scrollable-container::-webkit-scrollbar-thumb {
	background-color: transparent;
	border-right: 2px solid @DB;
}

img[data-lazy-src] {
	opacity: 0;
	transition: opacity 0.3s;
}

.review-stars {
	display: inline-block;
	position: relative;
	color: #BC7B00;

	&[disabled] {
		cursor: default;
	}

	.visually-hidden {
		// WAVE from WebAIM isn't smart enough to know that this is visually hidden content and yells about contrast. This fixes it
		color: black;
	}

	&__empty,
	&__filled {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: var(--review-stars-gap, 0px);
		font-size: var(--review-stars-font-size, 16px);
	}

	&__empty {
		white-space: nowrap !important;
	}

	&__filled {
		position: absolute;
		overflow: hidden;
		white-space: nowrap !important;
		left: 0;
		top: 0;
		right: 0;
	}
}

.review-stars-count {
	color: #000;
	font-size: 12px;
	margin-left: 5px;
}

/* Chat bubble */
#iframemaindiv {
	/* overiding inline z-index of ####*/
	z-index: 10 !important;
	bottom: 0px;
}

/* Hide chat when mobile menu is open */
body.shadowed {
	#iframemaindiv {
		display: none;
	}
}

#cloudlink-chat-overlay-buttons {
	position: fixed !important;
}


/* Win your wishlist callout */
.win-wishlist-callout {
	display: inline-flex;
	align-items: center;
	box-shadow: 1px 0px 3px #00000024;
	border: 1px solid #B2B8D7;
	border-radius: 8px;
	background-color: white;
	padding: 12px 15px;
	margin: 20px 0 50px;

	.wishlist-icon {
		background-color: rgba(255, 220, 72, 0.5);
		border-radius: 50%;
		margin-right: 10px;
		padding: 7px;
	}

	.icon-listWish {
		color: #7C6600;
		font-size: 36px;
		line-height: 36px;
	}

	.wishlist-text {
		padding-right: 32px;
		text-align: left;
	}

	h5 {
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0.36px;
		color: #7C6600;
		text-transform: uppercase;
		margin: 0;
	}

	p {
		font-size: 16px;
		letter-spacing: 0px;
		color: #4C5271;
		margin: 0;
	}

	.learn-more {
		background-color: #2079B0;
		border: 2px solid #509FD0;
		border-radius: 6px;
		color: white;
		padding: 10px 15px;
		text-decoration: none;
		display: inline-block;
	}

	.mobile-styles( {
		flex-direction: column;
		justify-content: center;
		margin: 30px 15px;

		div {
			display: block;
		}

		h5 {
			font-size: 16px;
		}

		p {
			font-size: 14px;
		}

		.wishlist-icon {
			margin-bottom: 10px;
		}

		.wishlist-text {
			padding: 0;
			text-align: center;
		}
	}

	);
}


/*======================================================
  Misc global elements
=======================================================*/

.global-alert-message {
	border: 2px solid #C90;
	background-color: #FEC;
	padding: 15px;
	margin: 0 0 20px;
	line-height: normal;
}

// Javascript-only links (without href attributes)
a.jslink {
	cursor: pointer;
}

a.jslink-underlined {
	cursor: pointer;
	text-decoration: underline;
}


/*======================================================
  Optimove Popup
=======================================================*/

.optimove-newsletter-dimmer {
	background-color: rgba(0, 0, 0, .5);
	position: fixed;
	inset: 0;
	z-index: -100;

	//For fade in
	opacity: 0;
	transition: opacity 400ms;
}

.optimove-newsletter-dimmer--show {
	opacity: 1;
	z-index: 999999999;
}

.optimove-newsletter-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999999999;
	max-width: 500px;
	min-width: 320px;
	width: 95%;
	max-height: 100%;
	overflow: auto;
	background-color: white;
	border-radius: 8px;
}

.optimove-newsletter-wrapper {
	.ms-styles({
		padding: 10px !important;
	});
}

.optimove-newsletter-close {
	.ms-styles({
		margin: 10px !important;
	});
}