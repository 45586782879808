
@import '~@less/_mixins.less';
.header-cart-wrapper {

	.header-cart-icon {
		display: inline-block;
		font-size: 30px;
		width: 30px;

		.tablet-and-up-styles({
			font-size: 40px;
			width: 40px;
		});
	}

	#quickCartDropdown {
		max-width: 100%;
		width: 380px;
	}
}
