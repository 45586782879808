
@import '~@less/_mixins.less';
.notif-wrapper {
	padding: 10px 10px 5px 10px;

	.btn-wrapper {
		padding-top: 5px;

		a {
			max-width: 100%;
			font-size: 14px;
			font-weight: 500;
			margin-top: 5px;
			padding: 3px;
			color: @LB;
			.fa {
				font-size: 8px;
				vertical-align: middle;
			}
		}
	}
}
.notifications-list {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	overflow: hidden;

	&:first-of-type {
		padding-top: 0;
	}

	.notif-indicator {
		display: block;
		font-size: 10px;
		font-weight: 500;
		color: #717BA9;
		text-align: left;
		text-transform: uppercase;
		letter-spacing: 0.4px;
		line-height: 20px;
		.db-link {
			color: @DB;
			font-size: 12px;
			text-transform: capitalize;
			font-weight: 400;
		}
	}
}
.no-items-wrapper {
	padding-bottom: 10px;
	.no-items-text {
		border-top: 2px solid #E5E7F1;
		font-size: 14px;
		text-align: center;
		color: #666;
		line-height: 20px;
		font-weight: 500;
		padding: 15px 0;
		padding-bottom: 0;
		margin-bottom: 15px;
	}
}
.notif-header {
	background-color: #fff;
	margin-bottom: 10px;
	> div {
		color: #656E97;
		letter-spacing: 0.56px;
		.text {
			margin-top: 5px;
		}
		.icon {
			color: #98A1CA;
			font-size: 24px;
			margin-right: 5px;
		}
	}
}
