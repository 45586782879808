
@import '../../../../less/_mixins.less';
.mobile-app-callout {
	background-color: #FFF;
}

.mobile-app-callout > div {
	max-width: 660px;
	margin: 0 auto;
	padding: 20px;
	border-top: 2px solid #E5E7F1;
	.mobile-styles({
		max-width: 295px;
		padding: 20px 0;
	});
}

.mac-content {
	flex: 0 0 300px;
	.mac-header p {
		color: #4C5271;
		font-weight: 200;
		margin-bottom: 5px;
	}
	.mac-logo {
		border-radius: 4px;
		box-shadow: 2px 2px 4px #00000029;
		background: #e12830;
		.icon {
			font-size: 40px;
			color: #fff;
		}
	}
	.mac-text {
		p {
			color: #656E97;
			font-size: 15px;
		}
	}
}

.mac-button a.ui-btn {
	background: transparent;
	max-width: 220px;
	font-weight: bold;
	&:hover {
		text-decoration: none;
	}
	.tablet-styles({
		font-size: 16px;
	});
	.mobile-styles({
		font-size: 14px;
	});
}
