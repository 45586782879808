
@import "~lessRoot/_mixins.less";

.rewards-help-styles {
	text-align: center;

	&__header-help-image {
		width: 80px;
		margin-top: 60px;
		margin-bottom: 10px;

		.mobile-and-up-styles({
			margin-top: 80px;
		});
	}

    &__header {
        font: normal normal bold 1.5rem/1.25 Roboto;
        color: #191B25;
        margin-bottom: 10px;
    }

	&__header:focus {
		outline: none;
	}

    &__subtitle {
        font: normal normal normal 0.875rem/1.3 Roboto;
        color: #4C5271;
        margin-bottom: 30px;
		padding: 0 20px;
    }

    &__call {
        margin: 0 auto;
        max-width: unset;
        width: unset;
        margin-bottom: 30px;
        padding: 10px 24px !important;
		display: inline-block;
		font-size: 1rem !important;

		.mobile-and-up-styles({
			font-size: 1.125rem !important;
		});
    }

    &__link-button {
        font-size: 0.875rem;
		display: flex;
		margin: 0 auto;
    }
}
