@import '../_variables.less';
@import '../_mixins.less';

/*=======================================================================
== Modal Components
========================================================================*/

@blainmodal-margin-sides-mobile: 10px;
@blainmodal-zindex: 10001;
@blainmodal-width-default: 720px;
@blainmodal-width-narrow: 415px;
@blainmodal-width-wide: 900px;

/* Body class when modal is open */
/*
.blain-modal-open {
	overflow: hidden;
}*/

//new modal
.blain-modal-new {
    display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	align-items: center;
    z-index: @blainmodal-zindex; /* Sit on top */
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    &.show {
        display: flex;
        flex-direction: column;
    }
}

// Modal Content/Box
.blain-modal-content-new {
    flex: 0 0 auto;
    position: relative;
    background-color: #F5F5F5;
    margin: auto;
    width: 100%; /* Matt changed this...found it to be "auto" before.  Old note: Could be more or less, depending on screen size */
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.4);
	max-width: 90%;
	&.bm-padding {
		padding: 20px;
	}
	.spinner {
		margin-top: 20px;
	}

	h3 {
		margin-bottom: 20px !important;
	}

    .tablet-and-up-styles({
        width: @blainmodal-width-default;
        &.blain-modal-narrow {
            width: @blainmodal-width-narrow;
        }
    });

    &.blain-modal-wide {
        width: auto;
        .desktop-min-only-styles({
            width: @blainmodal-width-wide;
        });
    }

    // The Close Button
    span.close {
        z-index: 1;
        position: absolute;
        top: 10px;
        right: 10px;
        color: #CCC;
        font-size: 28px;
        line-height: 20px;
        font-weight: bold;

        &:hover,
        &:focus {
            color: #EEE;
            text-decoration: none;
            cursor: pointer;
        }
    }

    pre {
        margin: 10px 0;
        height: 200px;
        border: 1px solid #ccc;
        overflow: scroll;
        padding: 15px;
        white-space: pre;
        background-color: #F5F5F5;
        color: #000;
        font-size: 11px;
        line-height: 14px;
    }
}

/* The Modal (background) */
.blain-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: @blainmodal-zindex; /* Sit on top */
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

	&.show {
		display: flex;
		flex-direction: column;
	}

}

// Modal Content/Box
.blain-modal-content {
	flex: 0 0 auto;
	position: relative;
	background-color: #fefefe;
	border-bottom: solid 26px @blue-light;
	border-top: solid 26px @blue-light;
	margin: auto;
    padding: 20px;
    width: 100%; /* Matt changed this...found it to be "auto" before.  Old note: Could be more or less, depending on screen size */
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.4);
	max-width: 90%;

	.tablet-and-up-styles({
		width: @blainmodal-width-default;
		&.blain-modal-narrow {
			width: @blainmodal-width-narrow;
		}
	});

	&.blain-modal-wide {
		width: auto;
		.desktop-min-only-styles({
			width: @blainmodal-width-wide;
		});
	}

	// The Close Button
	span.close {
		position: absolute;
		right: 5px;
		top: -23px;
		color: @blue-main;
		font-size: 28px;
		line-height: 20px;
		font-weight: bold;

		&:hover,
		&:focus {
			color: #FFF;
			text-decoration: none;
			cursor: pointer;
		}
	}

	pre {
		margin: 10px 0;
		height: 200px;
		border: 1px solid #ccc;
		overflow: scroll;
		padding: 15px;
		white-space: pre;
		background-color: #F5F5F5;
		color: #000;
		font-size: 11px;
		line-height: 14px;
	}
}

.blain-modal-btns {
	display: flex;
	margin: 0 180px;

	@media only screen and (max-width:765px) {
		margin: 0 100px;
	}

	@media only screen and (max-width:545px) {
		margin: 0;
	}

	button {
		flex: 1
	}
}

.spinner {
	margin-bottom: 5px;
}

.loyaltyMember {
	a:hover {
		cursor: default !important;
	}
}
