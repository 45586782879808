
@import '~lessRoot/_mixins';

.login-sidemenu-spinner-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 475px;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1;

	.styles-at(475px, {
		width: 100%;
	});

	.spinner{
		position: absolute;
		inset: 50% auto auto 50%;
		transform: translate(-50%, -50%);
	}

	.spinner-loader {
		width: 60px;
	}
}

