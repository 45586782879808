
@import '~@less/_mixins.less';
#mobileMenu {
	background-color: tint(@CL, 90%);
	position: fixed;
	height: 100%;
	bottom: 0;
	top: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14);
	width: 275px;
	z-index: 12;
	ul {
		overflow-x: hidden;
	}
}

#closeMobileMenuBtn {
	background-color: #0D5DA9;
	border: solid 2px tint(@CL, 90%);
	border-radius: 50%;
	color: #FFF;
	font-size: 16px;
	padding: 5px 5px 3px;
	line-height: 1;
	position: fixed;
	left: 283px;
	top: 10px;
	z-index: 13;
}
