@import '../_variables.less';
@import '../_mixins.less';

.container {
  margin-right: auto;
  margin-left: auto;
}

.row {
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-webkit-flex: 0 1 auto;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -0.5rem;
	margin-left: -0.5rem;
}

.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
.col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
.col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
.col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
.col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
.col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
.col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
.col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
.col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
.col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
.col-xs-12, .col-sm-12, .col-md-12, .col-lg-12,
.col-ms-1, .col-ms-10, .col-ms-11, .col-ms-12,
.col-ms-2, .col-ms-3, .col-ms-4, .col-ms-5,
.col-ms-6, .col-ms-7, .col-ms-8, .col-ms-9 {
  position: relative;
  min-height: 1px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-xs {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 0;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	max-width: 100%;
}

.col-xs-1 {
	-webkit-flex-basis: 8.333%;
	-ms-flex-preferred-size: 8.333%;
	flex-basis: 8.333%;
	max-width: 8.333%;
}

.col-xs-2 {
  -webkit-flex-basis: 16.666%;
  -ms-flex-preferred-size: 16.666%;
  flex-basis: 16.666%;
  max-width: 16.666%;
}

.col-xs-3 {
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -webkit-flex-basis: 33.333%;
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  -webkit-flex-basis: 41.667%;
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -webkit-flex-basis: 58.333%;
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  -webkit-flex-basis: 66.667%;
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  -webkit-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -webkit-flex-basis: 83.333%;
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  -webkit-flex-basis: 91.667%;
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (min-width:30em) {
  .col-ms,
  .col-ms-1,
  .col-ms-10,
  .col-ms-11,
  .col-ms-12,
  .col-ms-2,
  .col-ms-3,
  .col-ms-4,
  .col-ms-5,
  .col-ms-6,
  .col-ms-7,
  .col-ms-8,
  .col-ms-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .col-ms {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-ms-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-ms-2 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%;
  }

  .col-ms-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-ms-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-ms-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-ms-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-ms-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-ms-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-ms-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-ms-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-ms-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-ms-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

}

@media only screen and (min-width: 48em) {
  /*.container {
    width: 49rem;
  }*/

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%;
  }

  .col-sm-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 64em) {
  /*.container {
    width: 65rem;
  }*/

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%;
  }

  .col-md-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 75em) {
  /*.container {
    width: 76rem;
  }*/

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%;
  }

  .col-lg-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}