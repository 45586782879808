@import '_variables.less';

/*====================================
=            Media Breaks            =
====================================*/

.styles-at(@width, @rules) {
    @media only screen and (max-width: @width) {
        @rules();
    }
}

.styles-at-min(@width, @rules) {
    @media only screen and (min-width: @width) {
        @rules();
    }
}

.desktop-only-styles(@rules) {
    @media only screen and (min-width: @desktop) {
        @rules();
    }
}

.desktop-min-only-styles(@rules) {
    @media only screen and (min-width: @desktop-min) {
        @rules();
    }
}

.tablet-and-up-styles(@rules) {
    @media only screen and (min-width: @tablet-min) {
        @rules();
    }
}

.tablet-only-styles(@rules) {
    @media only screen and (min-width: @tablet-min) and (max-width: @tablet) {
        @rules();
    }
}

.tablet-styles(@rules) {
    @media only screen and (max-width: @tablet) {
        @rules();
    }
}

.phablet-styles(@rules) {
    @media only screen and (max-width: @phablet) {
        @rules();
    }
}

.mobile-styles(@rules) {
    @media only screen and (max-width: @mobile) {
        @rules();
    }
}

//Allows dev to target everything above ms-styles
.mobile-and-up-styles(@rules) {
	@media only screen and (min-width: @mobile-small) {
		@rules();
	}
}

.iphone-ls-styles(@rules) {
    @media only screen and (max-width: @iphone-ls) {
        @rules();
    }
}

.ms-styles(@rules) {
	@media only screen and (max-width: @mobile-small) {
		@rules();
	}
}

.iphone-styles(@rules) {
    @media screen and (max-width: @iphone) {
        @rules();
    }
}

/*=====================================================
=                Container Query Utils                =
=====================================================*/

.container-w-min(@width, @rules, @name: ~'') {
	@container @name (~"width >= @{width}") {
		@rules();
	}
}

.container-w-max(@width, @rules, @name: ~'') {
	@container @name (~"width <= @{width}") {
		@rules();
	}
}

/*=====  End of Media Breaks  ======*/

/*=====================================================
=            Layout, Size, and Positioning            =
=====================================================*/

.absolute(@top: 0, @right: 0, @bottom: 0, @left: 0) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.aspect-ratio(@width, @height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (@height / @width) * 100%;
    }

    > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}



.centered-block(@maxWidth) {
    display: block;
    margin: 0 auto;
    max-width: @maxWidth;
}

.clearfix() {
    &:after {
        clear: both;
        content: "";
        display: table;
    }
}

.container() {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
}

.fixed(@top: 0, @right: 0, @bottom: 0, @left: 0) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.responsive-block(@width, @maxWidth: 767px) {
    display: block;
    float: left;
    width: @width;
    @media screen and (max-width: @maxWidth) {
        float: none;
        width: 100%;
    }
}

/*=====  End of Layout, Size, and Positioning  ======*/

/*==================================
=            Background            =
==================================*/

.bg-lr-gradient(@color1, @position1, @color2, @position2) {
    background: -moz-linear-gradient(left,  @color1 @position1, @color2 @position2);
    background: -webkit-linear-gradient(left,  @color1 @position1, @color2 @position2);
    background: linear-gradient(to right,  @color1 @position1, @color2 @position2);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@color1', endColorstr='@color2',GradientType=1);
}

.bg-overlay(@bgColor) {
    position: relative;

    &:after {
        background-color: @bgColor;
        content: "";
        display: block;
        .fixed-full;
    }
}

.bg-img-fill(@width, @height) {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (@height / @width) * 100%;
    }

    > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

}

/*=====  End of Background  ======*/

/*==========================================
=            Margin and Padding            =
==========================================*/

.margin-x(@px) {
    margin-left: @px;
    margin-right: @px;
}

.margin-y(@px) {
    margin-top: @px;
    margin-bottom: @px;
}

.pad-x(@px) {
    padding-left: @px;
    padding-right: @px;
}

.pad-y(@px) {
    padding-top: @px;
    padding-bottom: @px;
}

/*=====  End of Margin and Padding  ======*/

/*========================================
=            Special Elements            =
========================================*/

.block-btn(@maxWidth: none, @padding: 8px) {
    appearance: none;
	font-family: @font-buttons;
    border: none;
    box-sizing: border-box;
	cursor: pointer;
    display: block;
    max-width: @maxWidth;
    padding: @padding;
    text-align: center;
    width: 100%;
	text-decoration: none;
}

.red-btn(@maxWidth: none, @padding: 8px) {
    background: @red-main;
    .block-btn(@maxWidth, @padding);
    color: #FFF;
	font-size: 18px;
	transition: background 0.2s;
	font-weight: 700;
	&:hover {
		background: @red-main-hover;
	}
}

.blue-btn(@maxWidth: none, @padding: 8px) {
    background: @blue-main;
    .block-btn(@maxWidth, @padding);
    color: #FFF;
	font-size: 18px;
	transition: background 0.2s;
	&:hover {
		background: @blue-main-hover;
	}
}

/*=====  End of Special Elements  ======*/

.fa-align-middle(@fontSize, @right: inherit, @left: inherit) {
    position: relative;
    & > i.fa {
        font-size: @fontSize;
        left: @left;
        line-height: @fontSize;
        margin-top: (@fontSize / -2);
        position: absolute;
        right: @right;
        top: 50%;
    }
}

.placeholder(@val) {
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: @val;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		color: @val;
	}
	&:-ms-input-placeholder { /* IE 10+ */
		color: @val;
	}
	&:-moz-placeholder { /* Firefox 18- */
		color: @val;
	}
}

.custom-horizontal-scrollbar(@color, @background-color: transparent, @height: 8px) {
    scrollbar-color: @color transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
        background-color: @background-color;
    }

    &::-webkit-scrollbar {
        height: @height;
        background-color: @background-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: @background-color;
        border-bottom: @height solid @color;
    }
}

.button-reset() {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0; // iOS Safari adds 2px of margin
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
}

.custom-scrollbar(@color, @background-color: transparent, @width: 8px) {
    scrollbar-color: @color transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
        background-color: @background-color;
    }

    &::-webkit-scrollbar {
        width: @width;
        background-color: @background-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: @background-color;
        border-right: 6px solid @color;
    }
}

.visually-hidden() {
    position: absolute !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
    clip: rect(1px 1px 1px 1px) !important; /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px) !important;
    white-space: nowrap !important;
}

.focus-outline() {
    outline: Highlight auto;
    outline: -webkit-focus-ring-color auto;
}

.full-screen-width() {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

/*=====  End of LESS Parametric Mixins  ======*/

/* List Slider & Carousels
============================================*/

.list-slider-base() {
	list-slider-viewport, [data-list-slider-viewport] {
		> ul,
		> [data-list-slider-track] {
			display: grid;
			transition: transform 0.4s ease-in-out;
			grid-auto-flow: column;
			grid-auto-columns: min-content;
			gap: var(--list-slider-item-gap, 0);
		}
	}
}

.list-slider-controlled() {
	list-slider-viewport, [data-list-slider-viewport] {
		overflow-x: hidden;
		overflow-y: hidden;
	}
}

.list-slider-scroll-new() {
	.list-slider-base();
	list-slider-viewport, [data-list-slider-viewport] {
		overflow-y: hidden;
		overflow-x: scroll;

		> ul > li,
		> [data-list-slider-track] > [data-list-slider-item] {
			display: inline-flex;
			white-space: normal;
			vertical-align: top;
		}
	}
}

// @fullItemsPerSlide must be an integer of at least 1
// @itemPeeking must be a number between 0 and 1 with at most two decimal places
//
// Once these styles are applied, you can modify the sizing at different breakpoints using the following custom properties:
// --list-slider-item-peeking
// --list-slider-full-items-per-slide
// without having to apply these styles again
.list-slider-sized(@fullItemsPerSlide: 1, @itemPeeking: 0) {
	--list-slider-item-peeking: @itemPeeking;
	--list-slider-full-items-per-slide: @fullItemsPerSlide;
	--list-slider-items-per-slide: calc(var(--list-slider-full-items-per-slide) + var(--list-slider-item-peeking, 0));


	// kind of a hacky way to determine the number of visible gaps. we start with the number of full items visible per slide and remove one.
	// the tricky bit, then, is to determine whether or not to count another gap for a peeking item. we need a 0 for no peeking item, which is
	// easy -- that'd be the value of --list-slide-item-peeking -- but 1 if there is a peeking item. That's harder because we have a decimal number
	// between 0 and 1 and there's not enough browser support for a ceiling function to round up to 1. So we can fake it with this combination of min and max
	//
	// Here's how it simplifies:
	// In the case of `--list-slider-item-peeking: 0`:
	// min(0.001, max(var(--list-slider-item-peeking, 0), 0)) * 1000
	// min(0.001, max(0, 0)) * 1000
	//           |
	//           -> 0
	// min(0.001, 0) * 1000
	// 0 * 1000
	// 0
	//
	// In the case of `--list-slider-item-peeking: 0.42` (or another decimal between 0 and 1; max two places):
	// min(0.001, max(var(--list-slider-item-peeking, 0), 0)) * 1000
	// min(0.001, max(0.42, 0)) * 1000
	//           |
	//           -> 0.42
	// min(0.001, 0.42) * 1000
	// 0.001 * 1000
	// 1
	--list-slider--PRIVATE-peeking-item-gaps: ~"min(0.001, max(var(--list-slider-item-peeking, 0), 0)) * 1000";
	--list-slider--PRIVATE-num-visible-gaps: calc(var(--list-slider-full-items-per-slide) - 1 + var(--list-slider--PRIVATE-peeking-item-gaps));

	.list-slider-base();

	list-slider-viewport, [data-list-slider-viewport] {
		> ul,
		> [data-list-slider-track] {
			@desired-item-width: calc(100% / var(--list-slider-items-per-slide));
			@per-item-gap-adjustment: calc(var(--list-slider--PRIVATE-num-visible-gaps) * var(--list-slider-item-gap, 0px) / var(--list-slider-full-items-per-slide));
			grid-auto-columns: calc(@desired-item-width - @per-item-gap-adjustment);

			& > * {
				width: auto !important; // size determined by grid
			}
		}
	}
}

// TODO: in a later PR,  remove the `new` prefix from the new mixins above
// and
// 1. rename these and all usages to include a prefix of `old`
// OR
// 2. refactor all usages of these to use the new ones above and remove these
.list-slider(@itemsPerSlide: 1) {
	--list-slider-items-per-slide: @itemsPerSlide;
	overflow: hidden;

	> ul,
	> [data-list-slider-track] {
		display: flex;
		flex-wrap: nowrap;
		transition: transform 0.4s ease-in-out;
		overflow-x: visible;
		overflow-y: visible;
	}

	> ul > li,
	> [data-list-slider-track] > [data-list-slider-item] {
		flex: 0 0 ~"calc(100% / var(--list-slider-items-per-slide))";
		width: ~"calc(100% / var(--list-slider-items-per-slide))";
	}
}

.list-slider-auto() {
    overflow: hidden;

	> ul,
	> [data-list-slider-track] {
		display: flex;
		flex-wrap: nowrap;
		transition: transform 0.4s ease-in-out;
		overflow-x: visible;
		overflow-y: visible;
	}
}

.list-slider-scroll() {
	> ul,
	> [data-list-slider-track] {
		display: block;
		white-space: nowrap;
		scroll-behavior: smooth;
		overflow-y: hidden;
		overflow-x: scroll;
	}

	> ul > li,
	> [data-list-slider-track] > [data-list-slider-item] {
		display: inline-flex;
		white-space: normal;
		vertical-align: top;
	}
}

.list-slider-items-per-slide(@itemsPerSlide) {
	--list-slider-items-per-slide: @itemsPerSlide;
}