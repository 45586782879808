@import '_variables.less';
@import '_mixins.less';

/*======================================================
  Rewards Banner
=======================================================*/
.loyaltyJoin {
	&:hover {
		cursor: pointer;
	}
}

.rewards-banner {
	background-color: #FEEFCB;
	border: 2px solid #FEC230;
	padding: 5px;
	align-items: center;

	.styles-at(321px,{
		padding: 5px 0;
	});

	p { margin-bottom: 0; }

	.rb-image {
		.hd-icon {
			height: 32px;
			width: 32px;
			margin-right: 5px;
		}
	}

	.rb-header {
		flex: 4;

		.rb-header-text {
			font-weight: bold;
			font-size: 16px;
			color: #3878AD;
			line-height: 1.1em;
			margin-top: 2px;
			.mobile-styles({
				font-size: 15px;
			});
		}

		.rb-text {
			font-size: 12px !important;
			color: #3878AD;
		}

		.rb-mobile-text {
			font-size: 12px !important;
			color: #3878AD;

			@media only screen and (min-width:768px) {
				display: none;
			}
		}
	}

	.rb-rewards-cc {

		p {
			font-size: 14px !important;
			color: #666;
		}
	}

	.rb-side-text {
		flex: 4;
		text-align: right;
		margin: 2px 10px 0px 0px;

		p {
			font-size: 14px !important;
			color: #666;
		}

		@media only screen and (max-width:1146px) {
			display: none;
		}
	}

	.rb-rewards {
		p {
			font-size: 14px !important;
			color: #666;
		}
		span {
			color: #3878AD !important;
		}
	}

	.rb-icon {
		flex: .25;
		margin-left: 4px;
		@media only screen and (min-width:768px) {
			flex: 0 1 auto;
		}
	}
}

@media only screen and (max-width:1029px) {
	.cart-loyalty-giftcard-banner {
		margin-right: 0 !important;
	}

	.rewards-banner-cart-wrapper {
		padding-right: 0;
	}
}

.rewards-banner-cart-wrapper {
	margin-bottom: 20px;
}

.under-banner {
	background-color: #EEE;
	text-align: center;
	padding: 6px 0px;

	p {
		color: #666;
		font-size: 14px !important;;
		margin-bottom: 0px;
	}
}

#quickCart {
	.rewards-banner {
		padding: 4px !important;
	}
}

.rewards-banner-cc {
	display: block;
	margin-bottom: 20px;
	min-height: 1px;
	img {
		height: auto;
		width: 100%;
	}
	#mobileImage { display: none; }
	.mobile-styles({
		#desktopImage { display: none; }
		#mobileImage { display: block; }
	})
}