
@import "~lessRoot/_mixins.less";

.forgot-password {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;

	&__title {
		color: #191B25;
		margin-bottom: 20px;
		font-size: 1.5rem;
		line-height: 1.25;
		font-weight: bold;
		text-align: center;
	}

	&__title:focus {
		outline: none;
	}

	&__detail {
		font-size: 0.875rem;
		line-height: 1.25;
		margin-bottom: 30px;
		padding: 0 10px;
		text-align: center;
		color: #4C5271;
	}

	&__image {
		width: 75px;
		min-height: 60px;
		margin-bottom: 20px;
	}

	&__form {
		background-color: #F2F3F8;
		padding: 20px 15px;
		width: 100%;
		margin-bottom: 30px;
		text-align: left;

		.blain-text-field__label {
			color: #4C5271;
		}
	}

	&__email {
		margin-bottom: 20px;
	}

	&__submit.ui-btn-full {
		font-size: 1rem !important;
		padding: 11px !important;

		.desktop-min-only-styles({
			font-size: 1.125rem !important;
		});
	}

	&__button {
		margin-bottom: 30px;
	}

	&__error {
		margin-bottom: 20px;
		width: 100%;
	}

	&__error-banner-text {
		color: black;
		letter-spacing: 0.42px;
		line-height: 1.29;
		font-size: 0.875rem;
	}

	&__link-button {
		font-size: 0.875rem;

		.mobile-and-up-styles({
			font-size: 1rem;
		});
	}
}
