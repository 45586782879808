
@import '~@less/_mixins.less';

.mega-menu {
	position: absolute;
	background-color: white;
	border-top: 2px solid #CBD0E4;
	height: 510px;
	width: 320px;
	z-index: 12;
	top: 35px;
	left: 0px;

	&__items {
		padding: 12px 10px;

		> ul > li {
			padding: 0 16px;
		}
	}

	&__anchor {
		border-radius: 6px;
		cursor: pointer;
		display: flex;
		text-decoration: none;
		font-size: 16px;
		padding: 9px 5px 9px 0;
		flex-grow: 1;
		color: #01305F;
	}

	&__trigger {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: normal;

		&--active {
			background-color: tint(@CL, 90%);
			border-radius: 12px;

			> .mega-menu__anchor {
				color: @BB;
				font-weight: bold;
			}
		}
	}

	&__bold {
		font-weight: bold;
	}

	&__lower-icon {
		margin-right: 5px;
	}

	&__chevron-icon {
		margin-top: 2px;
		color: #01305F;
	}

	&__chevron-button {
		appearance: none;
		.button-reset();
	}

	&__lower-links {
		border-top: solid 1px #CBD0E4;
		margin-top: 17px;
		padding-top: 17px;
	}

	&__gift-cards:hover {
		text-decoration: underline;
	}
}

