
.field-note {
	border-left: 4px solid #000000;

	@apply text-xs px-10px py-6px leading-snug;

	&.red {
		background-color: #FCEAEA;
		border-color: #B12F31;
		color: #000000;
	}

	&.allred {
		background-color: #FCEAEA;
		border-color: #B12F31;
		color: #B12F31;
	}


	&.orange {
		background-color: #FFECDA;
		border-color: #FFA245;
		color: black;
	}

	&.blue {
		background-color: #E5E7F1;
		border-color: #B2B8D7;
		color: #4C5271;
	}

	&.light-blue {
		@apply bg-cl-25 border-cl-300 text-cl-700;
	}

	&__header {
		@apply font-bold text-xs pb-3px;
	}

	&__body {
		@apply flex items-center;
	}
}
