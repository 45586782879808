
.create-password {
    &__password-requirements {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        list-style-type: none;
		margin-top: 12px;
    }

    &__requirement {
        color: #4C5271;
        font-size: 0.813rem;

        &--met {
            color: #007729;
        }
    }
}
