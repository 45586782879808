/*======================================
=            LESS Variables            =
======================================*/
/* 6+ landscape */
/* Flexbox Grid ms class */
/* 6+ portrait */
/*=====  End of LESS Variables  ======*/
/*====================================
=            Media Breaks            =
====================================*/
/*=====================================================
=                Container Query Utils                =
=====================================================*/
/*=====  End of Media Breaks  ======*/
/*=====================================================
=            Layout, Size, and Positioning            =
=====================================================*/
/*=====  End of Layout, Size, and Positioning  ======*/
/*==================================
=            Background            =
==================================*/
/*=====  End of Background  ======*/
/*==========================================
=            Margin and Padding            =
==========================================*/
/*=====  End of Margin and Padding  ======*/
/*========================================
=            Special Elements            =
========================================*/
/*=====  End of Special Elements  ======*/
/*=====  End of LESS Parametric Mixins  ======*/
/* List Slider & Carousels
============================================*/
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
.list-category,
.landing-page-section {
  display: flex;
  flex-direction: column;
}
.list-category .list-header-container,
.landing-page-section .list-header-container {
  flex: 1 1 auto;
  border: none;
  margin: 0;
}
.hawk-bannerLink {
  display: block;
  width: 100%;
}
.hawk-bannerLink > img {
  width: 100%;
}
.desktop-promo {
  display: block;
  margin-bottom: 1rem;
}
.desktop-promo img {
  display: block;
  height: auto;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .desktop-promo {
    display: none;
  }
}
.mobile-promo {
  display: none;
}
.mobile-promo img {
  display: block;
  height: auto;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .mobile-promo {
    display: block;
    margin-bottom: 30px;
  }
}
.list-category h1 {
  text-align: center;
  margin-top: 10px;
}
@media only screen and (min-width: 1024px) {
  .list-category h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .list-category h1 {
    display: none;
  }
}
.list-category-body {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
@media only screen and (min-width: 768px) {
  .list-category-body {
    margin-bottom: 40px;
  }
}
.list-category-body .list-category-item {
  display: block;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 700;
  text-decoration: none;
  color: black;
  max-width: 233px;
}
.list-category-body .list-category-item h2 {
  font-size: 16px;
}
.list-category-body .list-category-item img {
  width: 100%;
  height: auto;
  max-width: 180px;
}
.list-category-body span {
  padding: 10px 10px 20px 10px;
}
.category-carousels {
  padding-top: 30px;
}
@media only screen and (min-width: 768px) {
  .category-carousels {
    border-top: 1px solid #e3e1df;
    margin-left: 10px;
  }
}
.category-carousels h2 {
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  .category-carousels h2 {
    font-size: 35px;
  }
}
.category-carousels .carousel-wrapper {
  margin-bottom: 20px;
}
.category-carousels .carousel-wrapper .carousel-title {
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #666;
  font-size: 28px;
}
@media only screen and (max-width: 767px) {
  .category-carousels .carousel-wrapper .carousel-title {
    font-size: 22px;
  }
}
.list-body-text {
  padding: 20px;
  font-size: 14px;
}
.list-body-text-section {
  border-top: 2px solid #E5E7F2;
  padding-top: 15px;
  line-height: normal;
}
.list-body-text-section h2 {
  color: #191B26;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin-bottom: 12px;
}
.list-body-text-section .body-text {
  color: #4C5271;
  font-size: 0.75rem;
  margin-bottom: 12px;
}
.list-body-text-section .body-text h3,
.list-body-text-section .body-text h4 {
  font-weight: 700;
  margin: 12px 0;
}
.list-body-text-section .body-text h3 {
  font-size: 1rem;
}
.list-body-text-section .body-text h4 {
  font-size: 0.875rem;
}
.list-body-text-section .body-text p,
.list-body-text-section .body-text div {
  font-size: 0.75rem;
  margin-bottom: 12px;
}
.list-body-text-section .body-text ol,
.list-body-text-section .body-text ul {
  font-size: 0.75rem;
  margin-bottom: 12px;
  padding-left: 1rem;
  line-height: 1.2;
}
.list-body-text-section .body-text ol li[data-list="bullet"] {
  list-style: disc;
}
.list-body-text-section .body-text ol li[data-list="ordered"] {
  list-style: decimal;
}
.list-body-text-section .body-text em {
  font-style: italic;
}
.list-body-text-section .link-button {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #0054A4;
}
.list-body-text-section .link-button .icon {
  font-size: 0.625rem;
}
.list-gift-background {
  background-image: url("/assets/images/banners/giftIdeaHeader.jpg");
  background-position: center;
  background-size: cover;
  height: 300px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1023px) {
  .list-gift-background {
    height: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .list-gift-background {
    height: 200px;
  }
}
@media only screen and (max-width: 460px) {
  .list-gift-background {
    justify-content: center;
  }
}
.list-gift-background h1.gift {
  color: white;
  font-weight: 700;
  margin-right: 20px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.55);
  font-size: 48px;
}
@media only screen and (max-width: 1023px) {
  .list-gift-background h1.gift {
    font-size: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .list-gift-background h1.gift {
    font-size: 30px;
  }
}
@media only screen and (max-width: 460px) {
  .list-gift-background h1.gift {
    margin-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .list-gift-background h1.gift {
    display: block;
  }
}
.list-container.gifts .mobile-header {
  display: none;
}
/* DEFAULT STYLED CATEGORY STYLES.*/
.category-page .container {
  max-width: 1440px;
}
.category-page header,
.category-page section {
  margin-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .category-page header,
  .category-page section {
    margin-bottom: 32px;
  }
}
.category-page .section-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #cbd0e4;
}
.category-page .section-title h2 {
  color: #484848;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  text-transform: capitalize;
  padding: 0 0 3px 0;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .category-page .section-title h2 {
    font: normal normal normal 16px/17px Roboto;
  }
}
/*=======================================================================
== Landing Page
========================================================================*/
.landing-page-video-section {
  height: auto;
  width: 100%;
  margin-top: 25px;
}
.landing-page-hierlist {
  font-weight: 400;
  margin: 6px 5px 0;
  padding-left: 5px;
}
.landing-page-hierlist ul {
  list-style: square;
  margin: 0 0 0 18px;
}
.landing-page-hierlist li {
  padding: 5px 0;
}
.landing-page-hierlist li a {
  text-decoration: none;
}
.landing-page-item-abstract {
  line-height: 17px;
  font-weight: 400;
  margin: 10px 5px 20px;
  padding-left: 5px;
}
.landing-page-item-abstract li {
  padding: 5px 0 5px 10px;
}
@media only screen and (min-width: 1440px) {
  .landing-page-item-abstract {
    margin: 10px 5px 20px;
  }
}
@media only screen and (min-width: 1023px) {
  .landing-page-item-abstract {
    margin: 10px 5px 20px;
  }
}
.landing-page-item {
  height: auto;
  margin-bottom: 20px;
}
.landing-page-item-container {
  padding: 0 10px 20px 10px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .landing-page-item-container {
    padding: 0 0 20px 0;
  }
}
.landing-page-item-container .landing-page-item-header a {
  text-decoration: none;
}
.landing-page-footer {
  bottom: 0;
  font-weight: 400;
  padding: 5px 5px 5px 10px;
  font-weight: 700;
  height: 20px;
  vertical-align: top;
}
.landing-page-footer a {
  text-decoration: none;
}
.landing-page-section {
  padding: 0 15px 15px 15px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .landing-page-section {
    padding: 0 0 10px;
  }
}
.landing-page-section .desktop-promo {
  display: block;
  margin-bottom: 10px;
  aspect-ratio: 2.66349206;
  width: 100%;
}
.landing-page-section .desktop-promo.short-banner {
  aspect-ratio: 4.79428571;
}
.landing-page-section .desktop-promo img {
  max-width: 100%;
  height: auto;
  aspect-ratio: 2.66349206;
}
.landing-page-section .desktop-promo img.short-banner {
  aspect-ratio: 4.79428571;
}
@media only screen and (max-width: 414px) {
  .landing-page-section .desktop-promo {
    display: none;
  }
}
.landing-page-section .mobile-promo {
  display: none;
  margin-bottom: 10px;
  aspect-ratio: 1.6;
}
.landing-page-section .mobile-promo img {
  aspect-ratio: 1.6;
}
@media only screen and (max-width: 414px) {
  .landing-page-section .mobile-promo {
    display: block;
  }
}
.landing-page-section-body {
  margin-bottom: 20px;
}
.landing-page-section-body img {
  height: auto;
  width: 100%;
  margin: auto;
}
@media only screen and (max-width: 768px) {
  .landing-page-section-body img {
    height: auto;
    width: 100%;
  }
}
.landing-page-video-container {
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
  width: 100%;
}
.landing-page-video-container div {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 414px) {
  .landing-page-video-container {
    display: block;
  }
}
.landing-page-video-container .landing-page-video-img {
  width: 33.3%;
  height: auto;
  max-height: 315px;
  max-width: 279px;
}
@media only screen and (max-width: 414px) {
  .landing-page-video-container .landing-page-video-img {
    display: none;
  }
}
.landing-page-video-container .landing-page-video-mobileimg {
  display: none;
}
@media only screen and (max-width: 414px) {
  .landing-page-video-container .landing-page-video-mobileimg {
    width: 100%;
    height: auto;
    display: block;
  }
}
.landing-page-video-container img {
  height: 100%;
  width: 100%;
}
.landing-page-video-container .landing-page-video {
  width: 66.6%;
}
@media only screen and (max-width: 414px) {
  .landing-page-video-container .landing-page-video {
    width: 100%;
    position: relative;
    padding-bottom: 44.25%;
    padding-top: 25px;
    height: 0;
  }
  .landing-page-video-container .landing-page-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.landing-page-sidebar h4 {
  font-size: 1em;
}
@media only screen and (max-width: 768px) {
  .list-sidebar .landing-page-sidebar {
    display: none;
  }
}
.list-sidebar .sidebarlinks {
  padding: 0 8px;
  margin-bottom: 30px;
}
.list-sidebar .sidebarlinks > li {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  padding-top: 5px;
  padding-left: 10px;
}
@media only screen and (max-width: 768px) {
  .list-sidebar .sidebarlinks > li {
    margin: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    height: 40px;
  }
}
.list-sidebar .sidebarlinks > li a {
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .list-sidebar .sidebarlinks > li a {
    font-weight: 300;
    font-size: 18px;
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .list-sidebar .sidebarlinks {
    margin-bottom: 0;
  }
}
.landing-page-section-body {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
.landing-page-section-body .landing-page-item {
  flex: 0 1 25%;
  text-align: center;
  font-weight: 700;
  text-decoration: none;
  color: black;
}
@media only screen and (max-width: 1200px) {
  .landing-page-section-body .landing-page-item {
    flex: 0 1 33.333%;
  }
}
@media only screen and (max-width: 1023px) {
  .landing-page-section-body .landing-page-item {
    flex: 0 1 50%;
  }
}
@media only screen and (max-width: 479px) {
  .landing-page-section-body .landing-page-item {
    flex: 0 1 100%;
  }
}
.landing-page-section-body img,
.landing-page-section-body span {
  padding: 10px;
  display: block;
}
.landing-page-section-video {
  width: 100%;
  position: relative;
  padding-bottom: 44.25%;
  padding-top: 25px;
  height: 0;
  display: block;
}
.landing-page-section-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.landing-page-side-promo {
  width: 100%;
  padding-bottom: 5px;
}
.landing-page-side-promo img {
  width: 100%;
}
.sidebar-group {
  padding-top: 10px;
  padding-bottom: 10px;
}
.sidebar-group:not(:last-child) {
  border-bottom: 2px solid #70a9db;
}
@media only screen and (max-width: 768px) {
  .sidebar-group:not(:last-child) {
    border: none;
  }
}
@media only screen and (max-width: 768px) {
  .sidebar-group {
    padding-bottom: 5px;
    padding-top: 0;
    border-top: none;
  }
}
@media only screen and (max-width: 768px) {
  .sidebar-group .sidebar-collapsed {
    display: none !important;
  }
}
/*=======================================================================
== brands Page
========================================================================*/
#brandList .brand-header {
  border-bottom: 1px solid #e3e1df;
  padding-bottom: 10px;
}
#brandList .brand-area {
  column-count: 4;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1023px) {
  #brandList .brand-area {
    column-count: 3;
  }
}
@media only screen and (max-width: 767px) {
  #brandList .brand-area {
    column-count: 2;
  }
}
@media screen and (max-width: 414px) {
  #brandList .brand-area {
    column-count: 1;
  }
}
#brandList .brand-area li {
  padding-left: 20px;
}
#brandList .brand-area li i {
  top: 6px;
  left: 0;
}
#brandList .brand-area a {
  display: block;
  width: 100%;
  line-height: 27px;
}
#brandList #brandKey {
  font-family: 'Roboto', arial, sans-serif;
  margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  #brandList #brandKey {
    flex-wrap: wrap;
  }
}
#brandList #brandKey .key {
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  #brandList #brandKey .key {
    flex: 1 1 10%;
    margin-bottom: 8px;
    text-align: center;
  }
}
#noResults #visualDeptNav h2 {
  text-align: center;
}
.hawk-banner img {
  width: 100%;
  height: auto;
}
/*=======================================================================
== Tires / Auto Services Listings
========================================================================*/
.list-auto-item {
  border-bottom: solid 1px #eee;
  padding: 25px 0;
  width: 100%;
}
.list-auto-item img {
  max-width: 100%;
}
.list-auto-item .list-item-price {
  flex: 0 0 200px;
}
@media only screen and (max-width: 479px) {
  .list-auto-item .list-item-price {
    flex: 0 0 105px;
  }
}
.list-auto-item .list-item-name {
  font-size: 22px;
  line-height: 1.3em;
}
@media only screen and (max-width: 1023px) {
  .list-auto-item .list-item-name {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .list-auto-item .list-item-name {
    font-size: 14px;
  }
}
@media only screen and (max-width: 479px) {
  .list-auto-item .list-item-name {
    font-size: 12px;
  }
}
.list-auto-item .list-item-price-body {
  text-align: right;
}
.list-auto-item .list-item-price-body .red-btn {
  margin-bottom: 10px;
}
@media only screen and (max-width: 479px) {
  .list-auto-item .list-item-price-body .red-btn {
    display: none;
  }
}
@media only screen and (max-width: 479px) {
  .list-auto-item .list-item-price-body .blue-btn {
    display: none;
  }
}
.list-auto-item .red-btn {
  margin-bottom: 0;
}
.list-auto-item .reg-price,
.list-auto-item .sale-price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
@media only screen and (max-width: 479px) {
  .list-auto-item .reg-price,
  .list-auto-item .sale-price {
    font-size: 14px;
  }
}
.list-auto-item .original-price {
  text-decoration: line-through;
  margin-bottom: 5px;
}
.list-auto-item .sale-price {
  color: #b22b2c;
}
@media only screen and (max-width: 479px) {
  .list-auto-item .light {
    font-size: 12px;
  }
}
.list-auto-item .promotion-badge {
  top: 0;
  padding: 5px;
  min-width: 100px;
  font-size: 80%;
}
@media screen and (max-width: 414px) {
  .list-auto-item .promotion-badge {
    min-width: 100%;
    font-size: 12px;
  }
}
.list-auto-item .restriction-badge {
  background-color: #757575;
  bottom: 0;
  padding: 5px;
  font-size: 80%;
}
@media only screen and (max-width: 479px) {
  .list-auto-item-mobile-btns {
    display: block;
    margin: 20px auto 0;
  }
  .list-auto-item-mobile-btns .red-btn {
    margin-bottom: 10px;
  }
}
.list-auto-item-body ul {
  list-style: disc;
  padding-left: 40px;
}
.list-auto-item-body li {
  font-size: 15px;
  line-height: 1.3em;
}
.list-auto-item-img-container {
  flex: 0 1 200px;
  margin-right: 20px;
}
.list-item-img-bg {
  padding-top: 100%;
  background-size: 165% !important;
  background-position: center !important;
  overflow: hidden;
}
.list-auto-item-details {
  margin-top: 10px;
}
@media only screen and (max-width: 1200px) {
  .list-auto-item-details {
    display: none;
  }
}
.mile-warranty span {
  font-size: 30px;
  margin-right: 15px;
}
.tire-att {
  margin-right: 15px;
}
.tire-att img {
  height: 40px;
  margin-right: 10px;
  width: 40px;
}
#tireNoStock,
#batteryNoStock {
  text-align: center;
  margin-bottom: 50px;
  line-height: 1.5em;
}
@media only screen and (max-width: 767px) {
  #tireNoStock br,
  #batteryNoStock br {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  #tireNoStock > div,
  #batteryNoStock > div {
    padding: 0 15px;
  }
}
#tireNoStock > span,
#batteryNoStock > span {
  margin-top: 8px;
  font-size: 22px;
  display: inline-block;
}
#tireNoStock .please-call,
#batteryNoStock .please-call {
  margin-top: 5px;
}
#tireNoStockHeader,
#batteryNoStockHeader {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  #tireNoStockHeader,
  #batteryNoStockHeader {
    font-size: 22px;
    line-height: 26px;
  }
}
/*=======================================================================
== (Legacy) Product Listings - ex: /promo-deal/
========================================================================*/
#NonHawkList .list-products {
  margin: 0;
}
#NonHawkList .list-wrapper {
  width: 100%;
  max-width: 100%;
}
@media only screen and (max-width: 549px) {
  #NonHawkList .list-item {
    flex: 0 1 50%;
  }
}
@media only screen and (min-width: 550px) {
  #NonHawkList .list-item {
    flex: 0 1 33.3%;
  }
}
@media only screen and (min-width: 650px) {
  #NonHawkList .list-item {
    flex: 0 1 25%;
  }
}
@media only screen and (min-width: 769px) {
  #NonHawkList .list-item {
    flex: 0 1 33.3%;
  }
}
@media only screen and (min-width: 1000px) {
  #NonHawkList .list-item {
    flex: 0 1 25%;
  }
}
@media only screen and (min-width: 1250px) {
  #NonHawkList .list-item {
    flex: 0 1 20%;
  }
}
@media only screen and (min-width: 1440px) {
  #NonHawkList .list-item {
    flex: 0 1 16.6%;
  }
}
.list-item {
  height: auto;
  padding: 15px 0;
  outline: none;
}
@media only screen and (max-width: 768px) {
  .list-item {
    padding: 10px 0;
  }
}
.list-item > div {
  margin: 0 auto;
  position: relative;
  width: 200px;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .list-item > div {
    width: 140px;
  }
}
.list-item > div:active,
.list-item > div:hover,
.list-item > div:visited {
  color: inherit;
  text-decoration: none;
}
.list-item a {
  color: inherit;
  display: block;
  outline: none;
  text-decoration: none;
}
.list-item .list-item-img-container {
  position: relative;
  height: 200px;
  width: 200px;
}
@media only screen and (max-width: 768px) {
  .list-item .list-item-img-container {
    height: 120px;
    width: 120px;
  }
}
.list-item .list-item-img:not(.swatch-img),
.list-item img:not(.swatch-img) {
  height: 200px;
  width: 200px;
}
@media only screen and (max-width: 768px) {
  .list-item .list-item-img:not(.swatch-img),
  .list-item img:not(.swatch-img) {
    height: 120px;
    width: 120px;
  }
}
.list-item .swatches {
  padding: 5px 0;
  height: 35px;
}
@media only screen and (max-width: 767px) {
  .list-item .swatches .swatch-body {
    justify-content: center;
  }
}
.list-item .swatches .swatch-body .swatch-plus {
  display: none;
}
.list-item .swatches .swatch-body.count-5 .swatch-plus {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .list-item .swatches .swatch-body.count-2 .swatch-plus {
    display: none;
  }
  .list-item .swatches .swatch-body.count-3 .swatch-plus {
    display: none;
  }
  .list-item .swatches .swatch-body.count-5 .swatch:nth-of-type(3),
  .list-item .swatches .swatch-body.count-4 .swatch:nth-of-type(3),
  .list-item .swatches .swatch-body.count-5 .swatch:nth-of-type(4),
  .list-item .swatches .swatch-body.count-4 .swatch:nth-of-type(4) {
    display: none;
  }
  .list-item .swatches .swatch-body.count-5 .swatch-plus,
  .list-item .swatches .swatch-body.count-4 .swatch-plus {
    display: flex;
  }
}
.list-item .swatches .swatch {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  transition: box-shadow 150ms;
}
.list-item .swatches .swatch img {
  outline: solid 1px #cccccc;
  height: 25px;
  width: 25px;
}
.list-item .swatches .swatch.selected {
  background: #FFF;
  outline: solid 2px #222;
  padding: 1px;
}
.list-item .swatches .swatch.selected img {
  height: 23px;
  width: 23px;
}
.list-item .swatches .swatch-plus {
  color: #FFF;
  background-color: #70a9db;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-item .list-item-price {
  height: 40px;
  margin-top: 25px;
  margin-bottom: 5px;
}
.list-item .list-item-name {
  margin-bottom: 25px;
  margin-top: 5px;
  font-weight: 300;
  line-height: 17px;
}
.list-item .list-item-name.low-stock {
  margin-bottom: 42px;
}
.list-item .list-item-stock {
  bottom: 24px;
}
.list-item .original-price {
  position: absolute;
  transform: translateY(-17px);
  font-size: 14px;
  text-decoration: line-through;
}
.list-item .ratings {
  bottom: 0;
  height: 20px;
  position: absolute;
  width: 100%;
}
.list-item .reg-price {
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .list-item .range-price {
    font-size: 16px;
  }
}
.list-item .map-text {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}
@media only screen and (max-width: 767px) {
  .list-item .map-text {
    font-size: 13px;
  }
}
.list-item .sale-price {
  color: #b22b2c;
  font-size: 20px;
  font-weight: 700;
}
.list-item .promotion-badge {
  background-color: #b22b2c;
  color: #FFF;
  top: 0;
  padding: 5px;
  min-width: 100px;
  font-size: 80%;
}
.list-item .restriction-badge {
  background-color: #757575;
  bottom: 0;
  padding: 5px;
  font-size: 80%;
}
@media only screen and (max-width: 1023px) {
  .list-category h1 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 768px) {
  .list-category h1 {
    display: none;
  }
}
.list-container .mobile-header .mobile-header-title {
  display: block;
  font-family: 'Roboto', arial, sans-serif;
  line-height: 1.3em;
  font-size: 22px;
}
.list-container .mobile-header .mobile-header-categories {
  margin: 15px 0;
}
.list-container .mobile-header .mobile-header-count {
  display: block;
  font-size: 12px;
  line-height: normal;
  font-family: 'Roboto', arial, sans-serif;
  color: #999;
}
@media only screen and (max-width: 768px) {
  .list-container .mobile-header {
    display: block;
    margin-bottom: 15px;
  }
}
.list-container .list-sidebar {
  min-width: 230px;
  width: 230px;
  padding-right: 15px;
  border-right: 2px solid #70a9db;
}
.list-container .list-sidebar form {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .list-container .list-sidebar {
    min-width: unset;
    width: 100%;
    border: none;
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .list-container .list-sidebar .list-header-sort-options .option {
    display: flex;
    align-items: center;
    background-color: #024a76;
    color: #fff;
    margin: 0;
    padding: 12px 21px 12px 10px;
    margin-bottom: 2px;
    position: relative;
    font-family: 'Roboto', arial, sans-serif;
    height: 44px;
  }
  .list-container .list-sidebar .list-header-sort-options .option.active::after {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 20px;
    content: "\f00c";
    display: block;
    font-family: FontAwesome;
  }
  .list-container .list-sidebar .list-header-sort-options.open {
    display: block;
  }
}
.list-container .list-sidebar .list-sidebar-tab {
  width: 100%;
}
.list-container .list-sidebar .list-sidebar-tab .simple-select {
  display: none;
}
@media only screen and (max-width: 768px) {
  .list-container .list-sidebar .list-sidebar-tab > div {
    display: flex;
    flex: 1 1 50%;
  }
  .list-container .list-sidebar .list-sidebar-tab .list-header-sort {
    margin-left: 10px;
  }
  .list-container .list-sidebar .list-sidebar-tab .simple-select {
    width: 100%;
    display: block;
    font-family: 'Roboto', arial, sans-serif;
    margin-bottom: 2px;
    font-weight: 700;
  }
  .list-container .list-sidebar .list-sidebar-tab .simple-select .header {
    font-size: 16px;
  }
  .list-container .list-sidebar .list-sidebar-tab .simple-select i {
    display: block;
    height: 35px;
    width: 29px;
    padding-top: 6px;
  }
}
.list-container .list-body {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: auto;
}
.list-container .list-body .list-item {
  border-bottom: solid 1px #ddd;
  margin-bottom: 10px;
}
@media only screen and (max-width: 549px) {
  .list-container .list-body .list-item {
    flex: 0 1 50%;
  }
}
@media only screen and (min-width: 550px) {
  .list-container .list-body .list-item {
    flex: 0 1 33.3%;
  }
}
@media only screen and (min-width: 650px) {
  .list-container .list-body .list-item {
    flex: 0 1 25%;
  }
}
@media only screen and (min-width: 769px) {
  .list-container .list-body .list-item {
    flex: 0 1 50%;
  }
}
@media only screen and (min-width: 1000px) {
  .list-container .list-body .list-item {
    flex: 0 1 33.3%;
  }
}
@media only screen and (min-width: 1250px) {
  .list-container .list-body .list-item {
    flex: 0 1 25%;
  }
}
@media only screen and (min-width: 1440px) {
  .list-container .list-body .list-item {
    flex: 0 1 20%;
  }
}
.list-container .list-header-container {
  padding-bottom: 20px;
  margin: 0;
  border-bottom: 2px solid #70a9db;
}
@media only screen and (max-width: 767px) {
  .list-container .list-header-container {
    display: none;
  }
}
.list-container .list-header-container p {
  font-family: 'Roboto', arial, sans-serif;
  font-size: 16px;
}
.list-container .list-header-container .list-header-text {
  font-family: 'Roboto', arial, sans-serif;
}
.list-container .list-header-container .list-header-text h1 {
  margin-bottom: 2px;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 700;
  font-size: 28px;
}
.list-container .list-header-container .list-header-text span {
  font-family: 'Roboto', arial, sans-serif;
  color: #999;
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .list-container .list-header-container .list-header-text {
    display: none;
  }
}
.list-container .list-header-container .list-header-sort .simple-select {
  width: 120px;
}
@media only screen and (max-width: 768px) {
  .list-container .list-header-container .list-header-sort .simple-select {
    display: none;
  }
}
.list-container .list-footer-container {
  padding-bottom: 20px;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .list-container .list-footer-container {
    margin: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .list-container .list-footer-container {
    flex-direction: column;
    align-items: flex-end;
  }
  .list-container .list-footer-container > div {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .list-container .list-footer-container .list-footer-pagination {
    width: 100%;
    text-align: end;
    font-size: 15px;
  }
}
.list-container .list-footer-container .list-footer-pagination > a {
  text-decoration: none;
  color: black;
  padding: 5px;
}
.list-container .list-footer-container .list-footer-pagination > a:hover {
  text-decoration: underline;
}
.list-container .list-footer-container .list-footer-pagination > a.disabled {
  opacity: 0.5;
}
.list-container .list-footer-container .list-footer-pagination > a.active {
  background-color: #70a9db;
}
.list-container .list-footer-container .list-footer-size {
  margin-left: 40px;
}
.list-container .list-footer-container .list-footer-size .list-footer-size-label {
  font-weight: 700;
}
.list-container .list-footer-container .list-footer-size .simple-select {
  margin-left: 20px;
  width: 80px;
}
.simple-select {
  background-color: #70a9db;
  font-size: 14px;
  border-radius: 2px;
  color: #fff;
  position: relative;
}
.simple-select.active .header {
  background-color: #024a76;
}
.simple-select.active .header .arrow::before {
  content: "\f077";
}
.simple-select .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 250ms cubic-bezier(0.55, 0, 0.1, 1);
  background-color: inherit;
  padding: 5px 10px;
}
.simple-select .header:hover,
.simple-select .header:active {
  cursor: pointer;
  background-color: #024a76;
}
.simple-select .header .arrow {
  height: 35px;
  padding-top: 7px;
  font-size: 24px;
}
.simple-select .header .arrow::before {
  font-size: 20px;
  content: "\f078";
  display: block;
  font-family: FontAwesome;
}
.simple-select .options {
  font-family: 'Roboto', arial, sans-serif;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  position: absolute;
  z-index: 10;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.simple-select .options .option {
  transition: background-color 250ms cubic-bezier(0.55, 0, 0.1, 1);
  background-color: #fff;
  color: black;
  padding: 10px;
}
.simple-select .options .option:hover {
  background-color: #e3e1df;
  cursor: pointer;
}
.simple-select .options .option.active {
  background-color: #757575;
  color: #fff;
}
.simple-select .options div:hover {
  background-color: #70a9db;
}
.simple-select .closed {
  display: none;
}
/*=======================================================================
== Product List
========================================================================*/
#plp-lading-page-wrapper,
#plp-carousel-wrapper {
  display: none;
}
.is-landing-page #plp-lading-page-wrapper,
.is-landing-page #plp-carousel-wrapper {
  display: block;
}
.dxp-content-product-list-wrapper {
  margin: 0 -10px;
}
@media only screen and (min-width: 768px) {
  .dxp-content-product-list-wrapper {
    margin: 0 -15px;
  }
}
@media only screen and (min-width: 1470px) {
  .dxp-content-product-list-wrapper {
    margin: 0;
  }
}
.product-list-head.is-dxp-page {
  padding-top: 0;
}
@media only screen and (max-width: 767px) {
  .product-list-head {
    padding: 0;
  }
}
@media only screen and (min-width: 768px) {
  .product-list-head {
    padding-top: 0.3rem;
  }
  .product-list-head.is-landing-page {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .product-list-head {
    padding-top: 1.3rem;
  }
}
.product-list-head .search-redirect {
  display: block;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #0054A4;
}
#product-list-title {
  display: inline;
  color: black;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.3;
}
@media only screen and (min-width: 1024px) {
  #product-list-title {
    font-size: 1.75rem;
  }
}
.is-landing-page #product-list-title {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .is-landing-page #product-list-title {
    display: inline;
    margin: 0;
  }
}
@media only screen and (max-width: 767px) {
  .is-landing-page #product-list-title {
    margin: 1rem 0 0 0;
  }
}
.product-list-total-results {
  display: inline-block;
  font-size: 0.875rem;
  padding-left: 0.25rem;
  color: #4C5271;
}
.is-landing-page .product-list-total-results {
  display: none;
}
@media only screen and (max-width: 767px) {
  .product-list-total-results {
    color: #4C5271;
    padding-top: 10px;
  }
}
.product-placeholders-wrapper {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
}
.tire-placeholders-wrapper {
  margin-top: 15px;
}
@keyframes fadeIt {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.3;
  }
}
.grey-skeleton-loader {
  display: flex;
  flex-direction: column;
}
.grey-skeleton-loader .inner {
  background-image: none !important;
  animation: fadeIt 2s ease-in-out;
  animation-iteration-count: infinite;
  height: 100%;
  width: 100%;
  flex: 1;
  background-color: #7E89BC;
  opacity: 0.3;
}
.grey-skeleton-loader.filter-header {
  min-height: 70px;
  margin-bottom: 20px;
}
.grey-skeleton-loader.horizontal-product-placeholder {
  width: 100%;
  min-height: 225px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .grey-skeleton-loader.horizontal-product-placeholder {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    min-height: 400px;
    margin-bottom: 25px;
  }
}
.grey-skeleton-loader.product-placeholder {
  min-height: 300px;
}
@media only screen and (min-width: 1024px) {
  .grey-skeleton-loader.product-placeholder {
    min-height: 500px;
  }
}
.product-list-grid {
  display: flex;
  flex-wrap: wrap;
}
.product-list-grid__item-wrapper {
  /**
            Grid Item Borders
        */
}
@media only screen and (max-width: 767px) {
  .product-list-grid__item-wrapper {
    width: 100%;
    display: flex;
    padding: 1rem 0;
    margin: 0 0.5rem 0.5rem 0.5rem;
  }
  .product-list-grid__item-wrapper:not(:first-child) {
    border-top: 2px solid #e5e7f2;
  }
}
@media only screen and (min-width: 768px) {
  .product-list-grid__item-wrapper {
    width: 33%;
    padding: 0.6rem 0.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .product-list-grid__item-wrapper {
    width: 25%;
    padding: 0.625rem 0.625rem 1.1875rem 0.625rem;
  }
}
@media only screen and (min-width: 768px) {
  .product-list-grid__item-wrapper {
    position: relative;
  }
  .product-list-grid__item-wrapper:not(.no-bottom-border) {
    border-bottom: 1px solid #e5e7f2;
  }
  .product-list-grid__item-wrapper:not(.no-right-border) {
    border-right: 1px solid #e5e7f2;
  }
}
@media only screen and (max-width: 767px) {
  .product-list-grid__item {
    display: flex;
    width: 100%;
  }
  .product-list-grid__item > * {
    width: 50%;
  }
}
.hawk-relaxedQuery {
  padding: 10px 15px;
  background-color: #FEFBAC;
  border-radius: 4px;
  margin-bottom: 15px;
}
.hawk-relaxedQuery p {
  color: #32374b;
  font-weight: 400;
  margin: 0;
}
.hawk-relaxedQuery p .keyword {
  font-weight: 500;
}
.list-container {
  flex-direction: column;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .list-container {
    flex-direction: row;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .list-container .facet-banner {
    display: none;
  }
}
.list-container .list-wrapper {
  margin: 0 -10px;
}
.list-container .list-wrapper.tire-finder {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .list-container .list-wrapper {
    margin: 0;
    max-width: calc(100% - 230px);
    width: calc(100% - 230px);
  }
  .list-container .list-wrapper.tire-finder {
    max-width: 100%;
    width: 100%;
  }
}
.list-container .list-products {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .list-container .list-products {
    margin-left: 15px;
  }
}
.styled-widget {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  padding: 0 10px;
  margin: 16px 0;
}
@media only screen and (min-width: 768px) {
  .styled-widget {
    padding: 0 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .styled-widget {
    padding: 0 16px;
    margin: 24px 0;
  }
}
@media only screen and (min-width: 1456px) {
  .styled-widget {
    padding: 0;
  }
}
.styled-widget__white-link {
  border: 2px solid #fff;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 0.875rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.2s linear;
}
.styled-widget__white-link:hover {
  background-color: #fff;
  color: #014A77;
}
@media only screen and (min-width: 1024px) {
  .styled-widget__white-link {
    font-size: 1rem;
    padding: 10px 12px 8px 12px;
  }
}
.styled-widget__title {
  font: normal normal 500 1.375rem/1.2 Roboto;
  padding-bottom: 10px;
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .styled-widget__title {
    font-size: 1.75rem;
  }
}
.styled-widget__description {
  font-size: 1.125rem;
  line-height: 1.2;
  padding-bottom: 8px;
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .styled-widget__description {
    font-size: 1.375rem;
  }
}
.styled-widget .carousel__arrow {
  display: flex;
  font-size: 24px;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 50%;
  transition: border 0.1s linear;
}
.styled-widget .carousel__button {
  display: none;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 8px;
  padding: 0;
}
.styled-widget .carousel__button:hover .carousel__arrow {
  border: 1px solid #fff;
}
.styled-widget .carousel__button.carousel__button-blue,
.styled-widget .carousel__button.carousel__button-blue:hover:not([aria-disabled="true"]) .carousel__arrow {
  border-color: #0076B6;
  color: #0076B6;
}
.styled-widget .carousel__button[aria-disabled="true"] {
  border-color: #8893B8;
  color: #8893B8;
  cursor: not-allowed;
}
.styled-widget .carousel__button[aria-disabled="true"] .carousel__arrow {
  border: 1px solid rgba(255, 255, 255, 0);
}
@media only screen and (min-width: 1024px) {
  .styled-widget .carousel__button {
    display: flex;
  }
}
.hover-cards__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
}
.hover-cards__item {
  height: 280px;
  overflow: hidden;
  border-radius: 8px;
}
.hover-cards__link {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.hover-cards__link:hover > .hover-cards__background-image {
  transform: scale(1.05);
}
.hover-cards__background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 80%;
  background-position-x: 70%;
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
  transition: all 0.2s linear;
}
.hover-cards__title {
  width: 100%;
  margin: 0;
  padding: 16px;
  background-color: #000;
  color: #fff;
  font-size: 1.6rem;
  border-radius: 0 0 8px 8px;
  z-index: 2;
}
@media only screen and (max-width: 1023px) {
  .hover-cards__item {
    aspect-ratio: 1.77777778;
    height: auto;
    width: 100%;
  }
  @supports not (aspect-ratio: 16/9) {
    .hover-cards__item {
      min-height: 180px;
    }
  }
  .hover-cards__background-image {
    background-position-y: 50%;
  }
  .hover-cards__container {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  .hover-cards__title {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 767px) {
  .hover-cards__container {
    gap: 16px;
  }
  .hover-cards__title {
    font-size: 1.1rem;
    padding: 12px 16px;
  }
}
@media only screen and (max-width: 576px) {
  .hover-cards__container {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
.name-tag-links__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px 32px;
}
.name-tag-links__link {
  text-decoration: none;
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff;
  transition: border 0.2s linear;
}
.name-tag-links__item {
  width: 100%;
  box-shadow: #0000001A 0 0 6px;
  border-bottom: 2px solid #B2B8D7;
}
.name-tag-links__item:hover > .name-tag-links__link {
  border-bottom: 1px solid #B2B8D7;
}
.name-tag-links__image {
  flex-shrink: 1;
  height: 104px;
  padding: 4px;
}
.name-tag-links__title {
  flex-shrink: 1;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 24px;
}
@media only screen and (max-width: 1085px) {
  .name-tag-links__container {
    grid-template-columns: 1fr 1fr;
  }
  .name-tag-links__title {
    font-size: 1.2rem;
    margin: 0 0 0 16px;
  }
}
@media only screen and (max-width: 767px) {
  .name-tag-links__container {
    grid-template-columns: 1fr;
  }
  .name-tag-links__title {
    font-size: 1.1rem;
  }
}
.step-text__container {
  display: flex;
  background-color: #F2F3F8;
  padding: 32px 0;
  border-radius: 5px;
}
.step-text__item {
  width: 100%;
  padding: 0 24px;
  border-right: 1px solid #7E89BC;
}
.step-text__item:last-of-type {
  border-right: none;
}
.step-text__title {
  display: flex;
  align-items: center;
  min-height: 2.2rem;
}
.step-text__title-number {
  padding: 0.45rem 0.6rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
}
.step-text__title-number--container {
  flex-shrink: 0;
  height: 1.8rem;
  width: 1.8rem;
  background-color: #7E89BC;
  color: #fff;
  border-radius: 100%;
  margin-right: 16px;
  position: relative;
}
.step-text__title-text {
  flex-shrink: 1;
  font-size: 1.2rem;
  line-height: 0.9;
  font-weight: bold;
  margin-bottom: 0;
}
.step-text__description {
  margin-top: 16px;
}
@media only screen and (max-width: 1023px) {
  .step-text__container {
    flex-direction: column;
    padding: 0 16px;
  }
  .step-text__title-number {
    font-size: 0.8rem;
  }
  .step-text__title-number--container {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 12px;
  }
  .step-text__title-text {
    font-size: 1.1rem;
  }
  .step-text__item {
    padding: 16px 0;
    border-right: none;
    border-bottom: 1px solid #7E89BC;
  }
  .step-text__item:last-of-type {
    border-bottom: none;
  }
  .step-text__description {
    margin-top: 8px;
  }
}
.hero-carousel__container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .hero-carousel__container {
    flex-direction: row;
  }
}
.hero-carousel__hero {
  width: 100%;
  height: 280px;
  flex-shrink: 0;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .hero-carousel__hero {
    height: auto;
    width: 280px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-carousel__hero {
    width: 400px;
  }
}
.hero-carousel__image {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: 50% 30%;
}
@media only screen and (min-width: 768px) {
  .hero-carousel__image {
    object-position: 50% 50%;
  }
}
.hero-carousel__content {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  justify-content: space-between;
  overflow: hidden;
  padding: 16px 24px;
  background-color: #01305F;
  color: #fff;
}
.hero-carousel__content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  position: sticky;
  left: 0;
}
@media only screen and (min-width: 1024px) {
  .hero-carousel__content {
    padding: 24px 40px;
  }
}
.hero-carousel__controls {
  display: flex;
  align-self: flex-end;
}
.hero-carousel__arrow {
  display: flex;
  font-size: 24px;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 50%;
  transition: border 0.1s linear;
}
.hero-carousel__display {
  overflow: hidden;
}
.hero-carousel__display > ul,
.hero-carousel__display > [data-list-slider-track] {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.4s ease-in-out;
  overflow-x: visible;
  overflow-y: visible;
}
@media only screen and (max-width: 1023px) {
  .hero-carousel__display > ul,
  .hero-carousel__display > [data-list-slider-track] {
    display: block;
    white-space: nowrap;
    scroll-behavior: smooth;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .hero-carousel__display > ul > li,
  .hero-carousel__display > [data-list-slider-track] > [data-list-slider-item] {
    display: inline-flex;
    white-space: normal;
    vertical-align: top;
  }
}
.hero-carousel__list {
  padding-bottom: 4px;
  scrollbar-color: #B2B8D7 transparent;
  scrollbar-width: thin;
}
.hero-carousel__list::-webkit-scrollbar-track {
  background-color: transparent;
}
.hero-carousel__list::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
}
.hero-carousel__list::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-bottom: 4px solid #B2B8D7;
}
.hero-carousel__product {
  padding-right: 8px;
  display: flex;
}
@media only screen and (min-width: 1024px) {
  .hero-carousel__product {
    padding-right: 12px;
  }
}
.hero-carousel__product:last-of-type {
  padding: 0;
}
.hero-carousel-offset .hero-carousel__content {
  width: calc(100% - 32px);
  margin: 0 16px;
}
@media only screen and (min-width: 768px) {
  .hero-carousel-offset .hero-carousel__content {
    height: fit-content;
    width: 100%;
    margin: 32px 0;
  }
}
.hero-carousel-offset .hero-carousel__hero {
  margin-bottom: -64px;
}
@media only screen and (min-width: 768px) {
  .hero-carousel-offset .hero-carousel__hero {
    width: 400px;
    margin-right: -128px;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-carousel-offset .hero-carousel__hero {
    width: 500px;
  }
}
.product-card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  height: 18.75rem;
  width: 10.625rem;
  padding: 8px;
  border-bottom: 2px solid #B2B8D7;
  background-color: #fff;
}
@media only screen and (min-width: 768px) {
  .product-card {
    height: 20rem;
    width: 11.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .product-card {
    height: 21.25rem;
    width: 11.875rem;
  }
}
.product-card .price-alt-tip {
  display: none !important;
}
.product-card::after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #B2B8D7;
  transition: all 0.2s linear;
}
.product-card:active::after {
  border-bottom: 4px solid #B2B8D7;
}
@media only screen and (min-width: 1024px) {
  .product-card:hover::after {
    border-bottom: 4px solid #B2B8D7;
  }
}
.product-card__image-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 8px;
  height: 9.375rem;
}
@media only screen and (min-width: 768px) {
  .product-card__image-container {
    height: 10rem;
  }
}
@media only screen and (min-width: 1024px) {
  .product-card__image-container {
    height: 10.625rem;
  }
}
.product-card__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
}
.product-card__price {
  display: flex;
  align-items: center;
  color: black;
  margin-bottom: 16px;
}
.product-card .price-wrapper:has(> .price-alt) {
  padding-top: 0;
  margin-bottom: 4px;
}
.product-card .price-wrapper:has(.price-saved) {
  margin-bottom: 0;
}
.product-card__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
}
.product-card__brand {
  color: #666;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
}
.product-card__name {
  color: black;
  font-size: 0.9rem;
  line-height: 1.1;
  margin-bottom: 0;
}
.product-card__rating {
  margin-top: auto;
  margin-bottom: 4px;
}
@media only screen and (max-width: 1023px) {
  .product-card {
    padding: 4px;
  }
  .product-card__price {
    margin-bottom: 0;
  }
  .product-card .price-wrapper {
    margin-top: 0 !important;
  }
  .product-card__brand {
    font-size: 0.75rem;
    line-height: 1;
  }
  .product-card__name {
    color: black;
    font-size: 0.8rem;
    line-height: 1.1;
    margin-bottom: 0;
  }
}
.banner__container {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .banner__container {
    flex-direction: row;
  }
}
.banner__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  min-height: 160px;
  width: 100%;
  padding: 16px;
  order: 1;
}
.banner__content .styled-widget__white-link {
  margin-top: 16px;
}
@media only screen and (min-width: 1024px) {
  .banner__content .styled-widget__white-link {
    margin-top: 24px;
  }
}
@media only screen and (min-width: 768px) {
  .banner__content {
    order: unset;
    min-height: 240px;
    width: 360px;
    padding: 32px;
  }
}
@media only screen and (min-width: 1024px) {
  .banner__content {
    width: 460px;
    min-height: 280px;
    padding: 32px 40px;
  }
}
.banner__content.fall {
  background-color: #A86700;
}
.banner__content.fall-2 {
  background-color: #985800;
}
.banner__content.winter {
  background-color: #1d6c9d;
}
.banner__content.winter-light {
  background-color: #E5F4FF;
}
.banner__content.winter-light .styled-widget__title,
.banner__content.winter-light .styled-widget__description {
  color: black;
}
.banner__content.winter-light .styled-widget__white-link {
  color: #01305f;
  border-color: #01305f;
}
.banner__content.winter-light .styled-widget__white-link:hover {
  background-color: #01305f;
  color: white;
}
.banner__content.spring {
  background-color: #017470;
}
.banner__content.christmas-red {
  background-color: #B12F31;
}
.banner__content.dark {
  background-color: #32374B;
}
.banner__content.dark-blue {
  background-color: #013b5f;
}
.banner__content.tools-dark-blue {
  background-color: #01305F;
}
.banner__header {
  color: #fff;
}
.banner__image {
  height: 180px;
  background-position: left;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 1;
}
@media only screen and (min-width: 768px) {
  .banner__image {
    height: unset;
  }
}
.circle-carousel.styled-widget {
  padding-right: 0;
}
@media only screen and (min-width: 1024px) {
  .circle-carousel.styled-widget {
    padding-right: 16px;
  }
}
@media only screen and (min-width: 1440px) {
  .circle-carousel.styled-widget {
    padding: 0;
  }
}
.circle-carousel__container {
  display: flex;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .circle-carousel__container {
    padding: 0 32px;
  }
}
.circle-carousel .carousel__button {
  margin: 0;
}
.circle-carousel__display {
  overflow: hidden;
}
.circle-carousel__display > ul,
.circle-carousel__display > [data-list-slider-track] {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.4s ease-in-out;
  overflow-x: visible;
  overflow-y: visible;
}
.circle-carousel__display > ul,
.circle-carousel__display > [data-list-slider-track] {
  display: block;
  white-space: nowrap;
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: scroll;
}
.circle-carousel__display > ul > li,
.circle-carousel__display > [data-list-slider-track] > [data-list-slider-item] {
  display: inline-flex;
  white-space: normal;
  vertical-align: top;
}
@media only screen and (min-width: 1024px) {
  .circle-carousel__display {
    margin: 0 24px;
    --list-slider-items-per-slide: 5;
    overflow: hidden;
  }
  .circle-carousel__display > ul,
  .circle-carousel__display > [data-list-slider-track] {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.4s ease-in-out;
    overflow-x: visible;
    overflow-y: visible;
  }
  .circle-carousel__display > ul > li,
  .circle-carousel__display > [data-list-slider-track] > [data-list-slider-item] {
    flex: 0 0 calc(100% / var(--list-slider-items-per-slide));
    width: calc(100% / var(--list-slider-items-per-slide));
  }
}
@media only screen and (min-width: 1150px) {
  .circle-carousel__display {
    --list-slider-items-per-slide: 6;
    overflow: hidden;
  }
  .circle-carousel__display > ul,
  .circle-carousel__display > [data-list-slider-track] {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.4s ease-in-out;
    overflow-x: visible;
    overflow-y: visible;
  }
  .circle-carousel__display > ul > li,
  .circle-carousel__display > [data-list-slider-track] > [data-list-slider-item] {
    flex: 0 0 calc(100% / var(--list-slider-items-per-slide));
    width: calc(100% / var(--list-slider-items-per-slide));
  }
}
@media only screen and (min-width: 1300px) {
  .circle-carousel__display {
    --list-slider-items-per-slide: 7;
    overflow: hidden;
  }
  .circle-carousel__display > ul,
  .circle-carousel__display > [data-list-slider-track] {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.4s ease-in-out;
    overflow-x: visible;
    overflow-y: visible;
  }
  .circle-carousel__display > ul > li,
  .circle-carousel__display > [data-list-slider-track] > [data-list-slider-item] {
    flex: 0 0 calc(100% / var(--list-slider-items-per-slide));
    width: calc(100% / var(--list-slider-items-per-slide));
  }
}
.circle-carousel__list {
  padding: 8px 0;
  scrollbar-color: #B2B8D7 transparent;
  scrollbar-width: thin;
}
.circle-carousel__list::-webkit-scrollbar-track {
  background-color: transparent;
}
.circle-carousel__list::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
}
.circle-carousel__list::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-bottom: 4px solid #B2B8D7;
}
@media only screen and (min-width: 1024px) {
  .circle-carousel__list {
    overflow-y: visible !important;
  }
}
.circle-carousel__item {
  display: inline-flex;
  white-space: normal;
  vertical-align: top;
  padding-right: 8px;
}
@media only screen and (min-width: 1024px) {
  .circle-carousel__item {
    width: 100%;
    padding: 0 8px;
    justify-self: center;
    align-self: center;
  }
}
.circle-content {
  display: flex;
}
.circle-content__container {
  display: flex;
  border-radius: 50%;
  border: 2px solid #E5E7F0;
}
.circle-content__container:active {
  border-color: #0076B6;
}
@media only screen and (min-width: 1024px) {
  .circle-content__container:hover {
    border-color: #0076B6;
  }
}
.circle-content__image {
  height: 92px;
  width: 92px;
  border-radius: 50%;
  padding: 4px;
  border: 1px solid transparent;
}
.circle-content__image:active {
  border-color: #0076B6;
}
@media only screen and (min-width: 768px) {
  .circle-content__image {
    height: 112px;
    width: 112px;
  }
}
@media only screen and (min-width: 1024px) {
  .circle-content__image {
    height: 100%;
    width: 100%;
  }
  .circle-content__image:hover {
    border-color: #0076B6;
  }
}
.hanging-carousel__container {
  display: flex;
  flex-direction: column;
}
.hanging-carousel__banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 16px 80px;
  background-color: #01305F;
  color: #fff;
}
@media only screen and (min-width: 1024px) {
  .hanging-carousel__banner {
    flex-direction: row;
    align-items: flex-end;
    padding: 40px 40px 232px;
  }
}
.hanging-carousel__buttons {
  display: flex;
}
.hanging-carousel__link {
  margin: 20px 0 0;
}
@media only screen and (min-width: 1024px) {
  .hanging-carousel__link {
    display: none;
  }
}
.hanging-carousel__content {
  padding: 0 16px;
  overflow: hidden;
  background-color: transparent;
  margin-top: -60px;
}
@media only screen and (min-width: 1024px) {
  .hanging-carousel__content {
    padding: 0 40px;
    margin-top: -200px;
  }
}
.hanging-carousel__item {
  display: flex;
  padding-right: 8px;
}
@media only screen and (min-width: 1024px) {
  .hanging-carousel__item {
    padding-right: 16px;
  }
}
.hanging-carousel__viewport {
  overflow: hidden;
}
.hanging-carousel__viewport > ul,
.hanging-carousel__viewport > [data-list-slider-track] {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.4s ease-in-out;
  overflow-x: visible;
  overflow-y: visible;
}
.hanging-carousel__viewport > ul,
.hanging-carousel__viewport > [data-list-slider-track] {
  display: block;
  white-space: nowrap;
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: scroll;
}
.hanging-carousel__viewport > ul > li,
.hanging-carousel__viewport > [data-list-slider-track] > [data-list-slider-item] {
  display: inline-flex;
  white-space: normal;
  vertical-align: top;
}
@media only screen and (min-width: 1024px) {
  .hanging-carousel__viewport {
    overflow: hidden;
  }
  .hanging-carousel__viewport > ul,
  .hanging-carousel__viewport > [data-list-slider-track] {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.4s ease-in-out;
    overflow-x: visible;
    overflow-y: visible;
  }
  .hanging-carousel__viewport [data-list-slider-track] {
    overflow-y: unset;
  }
}
.hanging-carousel__track {
  padding-bottom: 8px;
  scrollbar-color: #B2B8D7 transparent;
  scrollbar-width: thin;
}
.hanging-carousel__track::-webkit-scrollbar-track {
  background-color: transparent;
}
.hanging-carousel__track::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
}
.hanging-carousel__track::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-bottom: 4px solid #B2B8D7;
}
.persona-card {
  position: relative;
  border-radius: 6px;
  border: 1px solid #CBD0E4;
  text-decoration: none;
  color: #000;
}
.persona-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 6px;
  border: 1px solid #CBD0E4;
  transition: all 0.2s linear;
}
.persona-card:active::after {
  border: 2px solid #CBD0E4;
}
@media only screen and (min-width: 1024px) {
  .persona-card:hover::after {
    border: 2px solid #CBD0E4;
  }
}
.persona-card__container {
  height: 354px;
  width: 214px;
}
@media only screen and (min-width: 1024px) {
  .persona-card__container {
    height: 462px;
    width: 278px;
  }
}
.persona-card__image {
  border-radius: 5px 5px 0 0;
  height: 250px;
  width: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 1024px) {
  .persona-card__image {
    height: 320px;
  }
}
.persona-card__label {
  padding: 10px 12px;
}
@media only screen and (min-width: 1024px) {
  .persona-card__label {
    padding: 14px;
  }
}
.persona-card__title {
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 4px;
}
@media only screen and (min-width: 1024px) {
  .persona-card__title {
    font-size: 1.4rem;
  }
}
.persona-card__description {
  font-size: 0.9rem;
  line-height: 1.2;
}
@media only screen and (min-width: 1024px) {
  .persona-card__description {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .slender-carousel__buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
    padding-bottom: 16px;
  }
}
.slender-carousel__container {
  display: flex;
  flex-direction: column;
  background-color: #F2F3F8;
}
@media only screen and (min-width: 1024px) {
  .slender-carousel__container {
    flex-direction: row;
  }
}
.slender-carousel__banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px 16px;
}
.slender-carousel__title {
  font-size: 1.75rem;
  margin-bottom: 8px;
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  .slender-carousel__title {
    font-size: 2rem;
    width: 400px;
  }
}
.slender-carousel__display {
  padding: 0 16px 16px;
  overflow: hidden;
}
@media only screen and (min-width: 1024px) {
  .slender-carousel__display {
    padding: 16px 0 8px 16px;
  }
}
.slender-carousel__viewport {
  overflow: hidden;
}
.slender-carousel__viewport > ul,
.slender-carousel__viewport > [data-list-slider-track] {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.4s ease-in-out;
  overflow-x: visible;
  overflow-y: visible;
}
.slender-carousel__viewport > ul,
.slender-carousel__viewport > [data-list-slider-track] {
  display: block;
  white-space: nowrap;
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: scroll;
}
.slender-carousel__viewport > ul > li,
.slender-carousel__viewport > [data-list-slider-track] > [data-list-slider-item] {
  display: inline-flex;
  white-space: normal;
  vertical-align: top;
}
@media only screen and (min-width: 1024px) {
  .slender-carousel__viewport {
    margin-right: 40px;
    overflow: hidden;
  }
  .slender-carousel__viewport > ul,
  .slender-carousel__viewport > [data-list-slider-track] {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.4s ease-in-out;
    overflow-x: visible;
    overflow-y: visible;
  }
  .slender-carousel__viewport [data-list-slider-track] {
    overflow-y: unset;
  }
}
.slender-carousel__track {
  padding-bottom: 8px;
  scrollbar-color: #B2B8D7 transparent;
  scrollbar-width: thin;
}
.slender-carousel__track::-webkit-scrollbar-track {
  background-color: transparent;
}
.slender-carousel__track::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
}
.slender-carousel__track::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-bottom: 4px solid #B2B8D7;
}
.slender-carousel__item {
  padding-right: 8px;
}
@media only screen and (min-width: 1024px) {
  .slender-carousel__item {
    padding-right: 12px;
  }
}
.slender-carousel__item:last-of-type {
  padding: 0;
}
.featured-widget__wrapper {
  display: grid;
  gap: 35px;
}
@media only screen and (min-width: 768px) {
  .featured-widget__wrapper {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 1024px) {
  .featured-widget__wrapper {
    gap: 20px;
  }
}
.featured-widget__item {
  border: 2px solid #E5E7F1;
  border-radius: 6px;
  display: flex;
  min-height: 240px;
}
@media only screen and (min-width: 850px) {
  .featured-widget__item {
    min-height: 280px;
  }
}
@media only screen and (min-width: 1024px) {
  .featured-widget__item {
    min-height: 314px;
  }
}
.featured-widget__item .blain-dropdown-arrow {
  left: 60px !important;
}
@media only screen and (min-width: 768px) {
  .featured-widget__item .blain-dropdown-arrow {
    left: 50px !important;
  }
}
@media only screen and (min-width: 1024px) {
  .featured-widget__item .blain-dropdown-arrow {
    left: 175px !important;
  }
}
.featured-widget__item .blain-dropdown {
  left: -55px !important;
}
@media only screen and (min-width: 768px) {
  .featured-widget__item .blain-dropdown {
    left: -45px !important;
  }
}
@media only screen and (min-width: 1024px) {
  .featured-widget__item .blain-dropdown {
    left: -170px !important;
  }
}
.featured-widget__item:first-child .blain-dropdown {
  left: -27px !important;
}
.featured-widget__item:first-child .blain-dropdown-arrow {
  left: 30px !important;
}
.featured-widget__info {
  margin: 20px 0px 20px 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.featured-widget__info .red-btn {
  margin-top: 15px;
  background-color: #B12F31;
  border: 2px solid #C96E6F;
  border-radius: 6px;
  font: normal normal bold 1rem / normal Roboto;
  width: 100%;
  padding: 12px 18px;
}
.featured-widget__info .red-btn:hover {
  background-color: #e63239;
}
.featured-widget__info .red-btn:active {
  background-color: #B12F31;
}
@media only screen and (min-width: 479px) {
  .featured-widget__info .red-btn {
    width: 150px;
  }
}
@media only screen and (min-width: 768px) {
  .featured-widget__info .red-btn {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .featured-widget__info .red-btn {
    width: 100%;
  }
}
.featured-widget__product-name {
  font: normal normal bold 1.125rem/1.25 Roboto;
  margin-bottom: 0px;
}
@media only screen and (min-width: 768px) {
  .featured-widget__product-name {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .featured-widget__product-name {
    font-size: 1.5rem;
  }
}
.featured-widget__text .price-wrapper {
  padding: 0px !important;
  margin: 0px !important;
}
.featured-widget__text .price-wrapper .price-alt {
  margin-top: 12px;
}
.featured-widget__text .price-wrapper .price-prefix {
  margin-top: 12px;
}
.featured-widget__image-container {
  flex: 1;
  max-width: 230px;
}
@media only screen and (min-width: 850px) {
  .featured-widget__image-container {
    max-width: 280px;
  }
}
.featured-widget__image {
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 115%;
  height: 100%;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .featured-widget .promo-text {
    margin-top: 12px;
  }
}
.featured-widget-size-3 .featured-widget__wrapper {
  grid-template-columns: 1fr;
  gap: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .featured-widget-size-3 .featured-widget__wrapper {
    gap: 10px;
  }
}
@media only screen and (min-width: 850px) {
  .featured-widget-size-3 .featured-widget__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (min-width: 850px) {
  .featured-widget-size-3 .featured-widget__product-name {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .featured-widget-size-3 .featured-widget__product-name {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .featured-widget-size-3 .featured-widget__large-name {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 479px) {
  .featured-widget-size-3 .featured-widget__item {
    position: relative;
  }
  .featured-widget-size-3 .featured-widget__info {
    margin-bottom: 75px;
  }
  .featured-widget-size-3 .featured-widget__image-container {
    max-width: 150px;
  }
  .featured-widget-size-3 .featured-widget__image {
    background-position: unset;
  }
}
.featured-widget-size-3 .red-btn {
  width: 100%;
  z-index: 2;
}
@media only screen and (min-width: 850px) {
  .featured-widget-size-3 .red-btn {
    width: 150%;
  }
}
@media only screen and (min-width: 479px) {
  .featured-widget-size-3 .red-btn {
    width: 200px;
  }
}
@media only screen and (max-width: 479px) {
  .featured-widget-size-3 .red-btn {
    position: absolute;
    width: auto;
    left: 15px;
    right: 15px;
    bottom: 15px;
  }
}
.hero-widget {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .hero-widget {
    background-position: top center;
    background-size: contain;
  }
}
.hero-widget__gradient {
  background: transparent linear-gradient(-180deg, #013B5F00 10%, #01305F 24%) 0% 0% no-repeat padding-box;
}
@media only screen and (min-width: 479px) {
  .hero-widget__gradient {
    background: transparent linear-gradient(-180deg, #013B5F00 10%, #01305F 29%) 0% 0% no-repeat padding-box;
  }
}
@media only screen and (min-width: 768px) {
  .hero-widget__gradient {
    background: transparent linear-gradient(270deg, #013B5F00 10%, #01305F 50%) 0% 0% no-repeat padding-box;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-widget__gradient {
    background: transparent linear-gradient(270deg, #013B5F00 35%, #01305F 60%) 0% 0% no-repeat padding-box;
  }
}
.hero-widget__container {
  margin: 0 auto;
  max-width: 1540px;
  padding: 200px 10px 20px 10px;
}
@media only screen and (min-width: 479px) {
  .hero-widget__container {
    padding-top: 220px;
  }
}
@media only screen and (min-width: 768px) {
  .hero-widget__container {
    padding: 100px 0 0;
  }
}
.hero-widget__title {
  color: #fff;
  margin-bottom: 10px;
  font: normal normal 500 1.75rem/1.2 Roboto;
}
@media only screen and (min-width: 768px) {
  .hero-widget__title {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-widget__title {
    font-size: 2.25rem;
  }
}
.hero-widget__big-text {
  color: #E31B23;
  font: normal normal bold 2.75rem/1 Roboto Condensed;
}
@media only screen and (min-width: 768px) {
  .hero-widget__big-text {
    font-size: 4.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-widget__big-text {
    font-size: 6.25rem;
  }
}
.hero-widget__subtitle {
  color: #fff;
  margin-bottom: 15px;
  font: normal normal normal 1.375rem/1.3 Roboto;
}
@media only screen and (min-width: 768px) {
  .hero-widget__subtitle {
    width: 55%;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .hero-widget__line {
    padding: 0 10px 20px 40px;
  }
}
@media only screen and (min-width: 1540px) {
  .hero-widget__line {
    border-left: 4px solid #fff;
  }
}
.hero-widget__category-cards {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 768px) {
  .hero-widget__category-cards {
    gap: 12px;
    grid-template-columns: repeat(5, minmax(auto, min(136px, 18%)));
  }
}
@media only screen and (min-width: 1440px) {
  .hero-widget__category-cards {
    grid-template-columns: repeat(auto-fit, minmax(auto, 10%));
  }
}
.hero-widget__category-li {
  display: inline-flex;
}
.hero-widget__card {
  position: relative;
  display: flex;
  background-color: white;
  padding: 6px 6px 8px 6px;
  border-radius: 6px 6px 0px 0px;
  text-decoration: none;
  text-align: left;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 479px) {
  .hero-widget__card {
    width: 60%;
  }
}
@media only screen and (min-width: 768px) {
  .hero-widget__card {
    flex-direction: column;
    padding-bottom: 12px;
    text-align: center;
    width: 100%;
  }
}
.hero-widget__card::after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #B2B8D7;
  transition: all 0.2s linear;
}
.hero-widget__card:hover:after {
  border-bottom: 4px solid #B2B8D7;
}
.hero-widget__hidden-card {
  display: none;
}
@media only screen and (min-width: 1440px) {
  .hero-widget__hidden-card {
    display: inline-flex;
  }
}
.hero-widget__category-image {
  max-width: 50px;
  max-height: 50px;
}
@media only screen and (min-width: 768px) {
  .hero-widget__category-image {
    max-width: 135px;
    max-height: 135px;
    width: 100%;
  }
}
.hero-widget__category-name {
  text-transform: uppercase;
  font: normal normal bold 1rem/1.3 Roboto;
  margin-left: 8px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .hero-widget__category-name {
    margin-left: 0px;
    margin-top: 8px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1440px) {
  .hero-widget__category-name {
    font-size: 1rem;
  }
}
.hero-widget__view-all-wrapper {
  width: 100%;
  text-align: right;
  margin-right: 8px;
}
@media only screen and (min-width: 479px) {
  .hero-widget__view-all-wrapper {
    text-align: left;
  }
}
@media only screen and (min-width: 1440px) {
  .hero-widget__view-all-wrapper {
    display: none;
  }
}
.hero-widget__view-all-button {
  color: #fff;
  display: inline-flex;
  margin-top: 15px;
}
.hero-widget__view-all-button::after {
  border-color: #fff;
}
.hero-widget__view-all,
.hero-widget__view-less {
  font-size: 0.875rem;
}
.hero-widget__view-all .icon,
.hero-widget__view-less .icon {
  font-size: 0.75rem;
  padding-left: 4px;
}
.hero-widget__view-less {
  display: none;
}
.hero-widget--redirect .hero-widget__gradient {
  background: transparent linear-gradient(-180deg, #013B5F00 14%, #000 23%) 0% 0% no-repeat padding-box;
}
@media only screen and (min-width: 479px) {
  .hero-widget--redirect .hero-widget__gradient {
    background: transparent linear-gradient(-180deg, #013B5F00 10%, #000 35%) 0% 0% no-repeat padding-box;
  }
}
@media only screen and (min-width: 768px) {
  .hero-widget--redirect .hero-widget__gradient {
    background: transparent linear-gradient(270deg, #013B5F00 10%, #000 50%) 0% 0% no-repeat padding-box;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-widget--redirect .hero-widget__gradient {
    background: transparent linear-gradient(270deg, #013B5F00 35%, #000 60%) 0% 0% no-repeat padding-box;
  }
}
@media only screen and (max-width: 479px) {
  .hero-widget--redirect .hero-widget__container {
    padding-top: 160px;
  }
}
@media only screen and (min-width: 768px) {
  .hero-widget--redirect .hero-widget__container {
    padding-top: 80px;
  }
}
.hero-widget--redirect .hero-widget__title {
  font: normal normal 500 2rem/1 Roboto;
}
@media only screen and (min-width: 768px) {
  .hero-widget--redirect .hero-widget__title {
    font-size: 3.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-widget--redirect .hero-widget__title {
    font-size: 4.375rem;
  }
}
.hero-widget--redirect .hero-widget__subtitle {
  font-size: 1.375rem;
}
@media only screen and (min-width: 768px) {
  .hero-widget--redirect .hero-widget__subtitle {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-widget--redirect .hero-widget__subtitle {
    font-size: 1.75rem;
  }
}
.hero-widget--redirect .hero-widget__buttons {
  padding-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .hero-widget--redirect .hero-widget__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    width: 75%;
    gap: 8px;
  }
}
@media only screen and (max-width: 479px) {
  .hero-widget--redirect .hero-widget__buttons {
    width: 100%;
  }
}
.hero-widget--redirect .hero-widget__button-li {
  display: inline-flex;
}
.hero-widget--redirect .hero-widget__btn-gray {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #e5e7f2;
  border-radius: 6px;
  text-align: center;
  color: #014A77;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font: normal normal 500 1rem/1.3 Roboto;
  letter-spacing: 0.56px;
  padding: 9px 20px 7px 20px;
  margin-right: 15px;
  margin-top: 15px;
  width: 100%;
}
.hero-widget--redirect .hero-widget__btn-gray:hover {
  background-color: #e9f3f9;
  border: 2px solid #a7cfe7;
}
.hero-widget--redirect .hero-widget__btn-gray:active {
  background-color: #d3e7f3;
  border: 2px solid #a7cfe7;
}
@media only screen and (max-width: 1023px) {
  .hero-widget--redirect .hero-widget__btn-gray {
    font-size: 0.875rem;
    padding: 8px 15px 6px 15px;
    margin-right: 10px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-widget--redirect .hero-widget__btn-gray {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0px;
  }
}
.hero-widget--redirect .hero-widget__shop-all-categories {
  color: #71C8FF;
  display: inline-flex;
  margin-top: 15px;
  font: normal normal 500 0.75rem/1.3 Roboto;
}
.hero-widget--redirect .hero-widget__shop-all-categories::after {
  border-color: #2998DE;
}
.hero-widget--redirect .hero-widget__shop-all-categories > span {
  font-size: 0.625rem;
  padding: 4px;
}
.hero-widget--redirect .hero-widget__shop-all-categories:hover {
  color: #2998DE;
}
@media only screen and (min-width: 1024px) {
  .hero-widget--redirect .hero-widget__shop-all-categories {
    font-size: 0.875rem;
  }
}
.hero-widget--open .hero-widget__hidden-card {
  display: inline-block;
}
@media only screen and (min-width: 479px) {
  .hero-widget--open .hero-widget__hidden-card {
    display: inline-flex;
  }
}
@media only screen and (max-width: 479px) {
  .hero-widget--open .hero-widget__gradient {
    background: transparent linear-gradient(-180deg, #013B5F00 7%, #01305F 18%) 0% 0% no-repeat padding-box;
  }
}
@media only screen and (max-width: 767px) {
  .hero-widget--open .hero-widget__gradient {
    background: transparent linear-gradient(-180deg, #013B5F00 7%, #01305F 21%) 0% 0% no-repeat padding-box;
  }
}
.hero-widget--open .hero-widget__view-all {
  display: none;
}
.hero-widget--open .hero-widget__view-less {
  display: unset;
}
