@import '../_variables.less';
@import '../_mixins.less';

//Variables
@default-duration: 150ms;

html {
	@media(prefers-reduced-motion: no-preference) {
		scroll-behavior: smooth;
	}
}

.list-category, .landing-page-section{
    display: flex;
    flex-direction: column;
    .list-header-container{
        flex: 1 1 auto;
        border:none;
        margin:0;
    }
}

.hawk-bannerLink{
    display:block;
    width:100%;
    > img{
        width:100%;
    }
}

.desktop-promo{
    display:block;
	margin-bottom:1rem;
    img{
        display:block;
		height: auto;
        width: 100%;
    }
    .mobile-styles({
        display:none;
    })
}
.mobile-promo{
    display:none;
    img{
        display:block;
        height: auto;
        width:100%;
    }
    .mobile-styles({
        display:block;
		margin-bottom: 30px;
    });
}
.list-category{
	h1{
		text-align:center;
		margin-top: 10px;
		.desktop-min-only-styles({
			font-size:40px;
		});
		.mobile-styles({
			display: none;
		});
	}
}
.list-category-body{
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    .tablet-and-up-styles({
		margin-bottom: 40px;
	});
    .list-category-item {
		display: block;
		margin-bottom: 25px;
        text-align:center;
        font-weight:700;
        text-decoration: none;
		color: black;
        max-width: 233px;
		h2 {
			font-size: 16px;
		}
		img {
            width: 100%;
			height: auto;
            max-width: 180px;
		}
    }
    span{
        padding:10px 10px 20px 10px;
    }
}

.category-carousels{
	padding-top:30px;
    .tablet-and-up-styles({
		border-top: 1px solid @grey-main;
        margin-left:10px;
    });
    h2{
        text-align:center;
		.desktop-min-only-styles({
			font-size:35px;
		});
    }
	.carousel-wrapper {
        margin-bottom: 20px;
        .carousel-title {
            text-align: center;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 2px;
            color:#666;
            font-size: 28px;
            .mobile-styles({
                font-size: 22px;
            });
        }
	}
}

.list-body-text{
	padding:20px;
	font-size:14px;
}

.list-body-text-section {
    border-top: 2px solid #E5E7F2;
    padding-top: 15px;
	line-height: normal;

    h2 {
        color: #191B26;
        font-size: 1.375rem;
        font-weight: 700;
        line-height: 1.625rem;
        margin-bottom: 12px;
    }

    .body-text {
        color: #4C5271;
        font-size: 0.75rem;
        margin-bottom: 12px;

        h3, h4 {
            font-weight: 700;
            margin: 12px 0;
        }

        h3 {
            font-size: 1rem;
        }

        h4 {
            font-size: 0.875rem;
        }

        p, div {
            font-size: 0.75rem;
            margin-bottom: 12px;
        }

        ol, ul {
            font-size: 0.75rem;
            margin-bottom: 12px;
            padding-left: 1rem;
            line-height: 1.2;
        }

        // quill
        ol li[data-list="bullet"] {
            list-style: disc;
        }

        ol li[data-list="ordered"] {
            list-style: decimal;
        }

        em {
            font-style: italic;
        }
    }

    .link-button {
        display: inline-block;
        font-size: 0.875rem;
        font-weight: 500;
        color: #0054A4;

        .icon {
            font-size: 0.625rem;
        }
    }
}

.list-gift-background{
    background-image: url("/assets/images/banners/giftIdeaHeader.jpg");
    background-position:center;
    background-size:cover;
    height:300px;
    margin-bottom:20px;
    .tablet-styles({
        height:250px;
    });
    .mobile-styles({
        height:200px;
    });
    .styles-at(460px,{
        justify-content: center;
    });

    h1.gift{
        color:white;
        font-weight:700;
        margin-right:20px;
        margin-bottom:20px;
		text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.55);
        font-size:48px;
        .tablet-styles({
            font-size:35px;
        });
        .mobile-styles({
            font-size:30px;
        });
        .styles-at(460px,{
            margin-right:0;
        });
        .styles-at(768px,{
            display:block;
        });
    }
}

.list-container.gifts .mobile-header{
    display:none;
}


/* DEFAULT STYLED CATEGORY STYLES.*/

.category-page {
	.container {
		max-width: 1440px;
	}

	header,
	section {
		margin-bottom: 60px;

		.mobile-styles( {
			margin-bottom: 32px;
		});
	}

	.section-title {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		border-bottom: 1px solid tint(@CL, 60%);

		h2 {
			color: #484848;
			font-size: 18px;
			font-weight: normal;
			line-height: 1.33;
			text-transform: capitalize;
			padding: 0 0 3px 0;
			margin-bottom: 0;
		}

		.mobile-styles( {
			h2 {
				font: normal normal normal 16px/17px Roboto;
			}
		});
	}

}