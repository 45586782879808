
@import "~lessRoot/_mixins.less";

.enter-code {
	text-align: center;

	&__header-image-lock {
		max-width: 42px;
		margin: 60px auto 10px auto;
	}

	&__header {
		font: normal normal bold 1.5rem/1.25 Roboto;
		color: #191B25;
		margin-bottom: 15px;
	}

	&__header:focus {
		outline: none;
	}

	&__subtitle {
		font: normal normal normal 0.875rem/1.3 Roboto;
		color: #4C5271;
	}

	&__redacted {
		color: #191B25;
		font: normal normal bold 0.875rem/1.3 Roboto;
	}

	&__form-area {
		text-align: left;
		margin: 30px 0;
		padding: 20px 15px;
		background-color: #F2F3F8;
	}

	&__verify-button {
		margin-top: 20px;
		max-width: 100% !important;
		font-size: 1rem !important;
		padding: 11px !important;

		.mobile-and-up-styles({
			font-size: 1.125rem !important;
		});
	}

	&__link-button {
		font-size: 0.875rem;
		margin: 15px auto 0 auto;
	}
}

