
@import "~lessRoot/_mixins.less";

.external-auth__button {
	background-color: #fff;
	border: 1px solid #DADCE0;
	border-radius: 4px;
	height: 38px;
	width: 100%;
	max-width: 400px;
	min-width: 200px;
	margin: 6px 0;

	.tablet-and-up-styles({
		margin: 6px 10px;
	});

	display: flex;
	align-items: center;

	@media (hover: hover) {
		&:hover {
			border-color: #d2e3fc;
			background-color: rgba(66,133,244,.04);
		}
	}

	&:disabled {
		background-color: rgba(0,0,0,.04);
		color: rgba(0,0,0,.1);
	}

	&--title {
		width: 100%;
		text-align: center;
		margin: 0;
		font-family: 'Roboto';
		line-height: 1.7;
		font-size: 14px;
		color: #3c4043;
		font-weight: 500;
		letter-spacing: 0.25px;
	}

	&--image {
		height: 18px;
		margin: 0 6px;
	}
}
