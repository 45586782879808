@import '../_variables.less';
@import '../_mixins.less';

/*=======================================================================
== Form Components
========================================================================*/

.csiframe {
    position: absolute;
    left: -5000px;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

// fix for chrome autofill font size issue
input,
textarea,
select {
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0px 1000px #FFE inset;
		-webkit-text-fill-color: #555 !important;
	}

	&:-webkit-autofill::first-line {
		font-size: 1rem;
	}

	&.valid:-webkit-autofill,
	&.valid:-webkit-autofill:hover,
	&.valid:-webkit-autofill:focus,
	&.valid:-webkit-autofill:active {
		background-image: url(/assets/images/forms/valid.svg) !important;
	}

	&.error:-webkit-autofill,
	&.error:-webkit-autofill:hover,
	&.error:-webkit-autofill:focus,
	&.error:-webkit-autofill:active {
		background-image: url(/assets/images/forms/error.svg) !important;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

option[value=""],
option[disabled],
option:disabled,
option.disabled {
	color: #ccc !important;
}

.blain-form {

	.formcol1 {
		padding-bottom: 15px;

		.tablet-and-up-styles( {
			padding-right: 30px;
		}

		)
	}

	.formcol2 {
		padding-bottom: 15px;

		.tablet-and-up-styles( {
			padding-left: 30px;
		}

		)
	}

	.help-block {
		display: block;
		margin: 5px 0;
		font-size: 12px;
		color: #777;
	}

	.phonefield,
	.expdatefield,
	.cvvfield,
	.ccfield,
	.datefield {
		font-family: Arial, Helvetica, sans-serif;
	}

	.expdatefield {
		width: 150px !important;
	}

	.cvvfield {
		width: 150px !important;
	}

	.form-control {
		appearance: none;
		-webkit-appearance: none;
		position: relative;
		display: block;
		width: 100%;
		height: 40px;
		padding: 7px 26px 7px 12px;
		font-size: 16px;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 0;
		transition: border-color ease-in-out .15s;
		.placeholder(#ccc);

		&:focus {
			border-color: #000;
		}

		&.valid,
		&.valid:disabled,
		&.error,
		&.error:focus {
			background-position: right 8px center;
			background-repeat: no-repeat;
			background-size: 16px 16px;

			&.cc-amex,
			&.cc-blain,
			&.cc-blank,
			&.cc-discover,
			&.cc-mastercard,
			&.cc-visa {
				background-position: 4px center, right 8px center;
				background-repeat: no-repeat, no-repeat;
				background-size: 54px 36px, 16px 16px;
			}
		}

		&.cc-amex,
		&.cc-blain,
		&.cc-blank,
		&.cc-discover,
		&.cc-mastercard,
		&.cc-visa {
			background-position: 4px center;
			background-repeat: no-repeat;
			background-size: 54px 36px;
			padding-left: 70px !important;
		}

		&.cc-amex {
			background-image: url(/assets/images/credit-cards/amex.svg);
		}

		&.cc-blain {
			background-image: url(/assets/images/credit-cards/blain.svg);
		}

		&.cc-blank {
			background-image: url(/assets/images/credit-cards/blank.svg);
		}

		&.cc-discover {
			background-image: url(/assets/images/credit-cards/discover.svg);
		}

		&.cc-mastercard {
			background-image: url(/assets/images/credit-cards/mastercard.svg);
		}

		&.cc-visa {
			background-image: url(/assets/images/credit-cards/visa.svg);
		}

		&.valid,
		&.valid:disabled {
			background-image: url(/assets/images/forms/valid.svg) !important;

			&.cc-amex {
				background-image: url(/assets/images/credit-cards/amex.svg), url(/assets/images/forms/valid.svg) !important;
			}

			&.cc-blain {
				background-image: url(/assets/images/credit-cards/blain.svg), url(/assets/images/forms/valid.svg) !important;
			}

			&.cc-blank {
				background-image: url(/assets/images/credit-cards/blank.svg), url(/assets/images/forms/valid.svg) !important;
			}

			&.cc-discover {
				background-image: url(/assets/images/credit-cards/discover.svg), url(/assets/images/forms/valid.svg) !important;
			}

			&.cc-mastercard {
				background-image: url(/assets/images/credit-cards/mastercard.svg), url(/assets/images/forms/valid.svg) !important;
			}

			&.cc-visa {
				background-image: url(/assets/images/credit-cards/visa.svg), url(/assets/images/forms/valid.svg) !important;
			}
		}

		&.error,
		&.error:focus {
			background-image: url(/assets/images/forms/error.svg) !important;
			border: 2px solid #C00;

			&.cc-amex {
				background-image: url(/assets/images/credit-cards/amex.svg), url(/assets/images/forms/error.svg) !important;
			}

			&.cc-blain {
				background-image: url(/assets/images/credit-cards/blain.svg), url(/assets/images/forms/error.svg) !important;
			}

			&.cc-blank {
				background-image: url(/assets/images/credit-cards/blank.svg), url(/assets/images/forms/error.svg) !important;
			}

			&.cc-discover {
				background-image: url(/assets/images/credit-cards/discover.svg), url(/assets/images/forms/error.svg) !important;
			}

			&.cc-mastercard {
				background-image: url(/assets/images/credit-cards/mastercard.svg), url(/assets/images/forms/error.svg) !important;
			}

			&.cc-visa {
				background-image: url(/assets/images/credit-cards/visa.svg), url(/assets/images/forms/error.svg) !important;
			}
		}

		&:disabled {
			border: 1px solid transparent;
			color: #000;
			background-color: transparent;
		}

		&.no-icon {
			padding: 8px 14px;

			&.error, &.valid {
				background-image: none !important;
			}
		}

		&.valid.no-check-icon {
			background-image: none !important;
		}
	}

	.form-group.form-new-password-group {
		padding: 0.5rem;
		margin-bottom: 0.5rem;
		background: rgba(0, 0, 0, 0.04);

		label.display {
			color: #333;
		}

		#passwordRequirements {
			margin: 0;
		}

		.form-password-rules {
			padding: 1rem 0;
			margin: 5px;
			background: transparent;
		}
	}

	select.form-control {
		background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22285%22%20height%3D%22285%22%20viewBox%3D%220%200%20284.9%20284.9%22%3E%3Cpath%20d%3D%22M282.1%2076.5l-14.3-14.3c-1.9-1.9-4.1-2.9-6.6-2.9%20-2.5%200-4.7%201-6.6%202.9L142.5%20174.4%2030.3%2062.2c-1.9-1.9-4.1-2.9-6.6-2.9%20-2.5%200-4.7%201-6.6%202.9L2.9%2076.5C1%2078.4%200%2080.6%200%2083.1c0%202.5%201%204.7%202.9%206.6l133%20133c1.9%201.9%204.1%202.9%206.6%202.9s4.7-1%206.6-2.9L282.1%2089.6c1.9-1.9%202.8-4.1%202.8-6.6C284.9%2080.6%20284%2078.4%20282.1%2076.5z%22%20fill%3D%22%23AAA%22%2F%3E%3C%2Fsvg%3E);
		background-position: right 8px center;
		background-repeat: no-repeat;
		background-size: 16px 16px;
		padding: 8px 26px 8px 14px !important;

		&:focus {
			outline: none;
		}

		&::-ms-expand {
			display: none;
		}

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}

		&:disabled {
			background-image: none;
		}

		&:required:invalid {
			color: #ccc;

			option {
				color: #222;
			}

			option[value=""] {
				display: none;
			}
		}
	}

	textarea.form-control {
		resize: vertical;
		min-height: 100px;
	}

	.sublabel {
		display: inline-block;
		font-size: 12px;
		color: #999;

		.mobile-styles( {
			display: block;
			line-height: 16px;
		}

		);
	}

	.sms-sublabel {
		display: block;
		font-size: 12px;
		color: #636C94;
		line-height: 16px;
		padding-left: 15px;
		font-weight: normal;
	}

	.sms-sublabel-paypal {
		display: block;
		font-size: 12px;
		color: #999999;
		line-height: 16px;
		padding-left: 5px;
		font-weight: normal;
	}

	label:not(.display) {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;
	}

	.radio-prefix,
	.checkbox-prefix {
		font-weight: 700;
		position: relative;
		display: inline-block;
		line-height: 16px;
		padding: 2px 20px 2px 0;
	}

	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: 4px 0 0;
		outline: none;
		border: 1px solid transparent;
		background-color: transparent;
		opacity: 0;

		&:focus + label {
			outline: 2px dashed #ddd;
		}

		+ label,
		+ span.label {
			border-radius: 5px;
			border: 2px solid transparent;
			cursor: pointer;
			position: relative;
			display: inline-block;
			width: auto;
			height: auto;
			margin: 0;
			overflow: visible;
			clip: auto;
			font-size: 16px;
			line-height: 16px;
			background-repeat: no-repeat;
			background-position: left center;
			padding: 3px 25px 2px 25px;

			.mobile-styles( {
				padding: 3px 25px 2px 35px;
			}

			);

			i.fa {
				float: left;
				margin-right: 5px;
				font-size: 20px;
				width: 20px;
				line-height: 20px;
				margin-top: -3px;

				.mobile-styles( {
					font-size: 30px;
					line-height: 30px;
					width: 30px;
					margin-right: 8px;
					margin-top: 0;
				}

				);
			}

			&.last {
				padding: 3px 0 2px 25px;

				.mobile-styles( {
					padding: 3px 0 2px 35px;
				}

				);
			}
		}

		&:disabled + label,
		&:disabled + span.label {
			opacity: 0.3;
		}
	}

	.fixed-height {

		.radio-prefix,
		.checkbox-prefix {
			padding: 13px 20px 13px 0;
		}

		input[type="checkbox"] + label,
		input[type="checkbox"] + span.label,
		input[type="radio"] + label,
		input[type="radio"] + span.label {
			padding: 13px 25px 13px 25px;

			.mobile-styles( {
				padding: 13px 25px 13px 35px;
			}

			);

			&.last {
				padding: 13px 0 13px 25px;

				.mobile-styles( {
					padding: 13px 0 13px 35px;
				}

				);
			}
		}
	}

	input[type="checkbox"] {
		+ label,
		+ span.label {
			background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22402%22%20height%3D%22402%22%20viewBox%3D%220%200%20402%20402%22%3E%3Cpath%20d%3D%22M377.9%2024.1C361.8%208%20342.4%200%20319.8%200H82.2C59.6%200%2040.2%208%2024.1%2024.1%208%2040.2%200%2059.6%200%2082.2v237.5c0%2022.6%208%2042%2024.1%2058.1%2016.1%2016.1%2035.5%2024.1%2058.1%2024.1h237.5c22.6%200%2042-8%2058.1-24.1%2016.1-16.1%2024.1-35.5%2024.1-58.1V82.2C402%2059.6%20394%2040.2%20377.9%2024.1zM365.4%20319.8c0%2012.6-4.5%2023.3-13.4%2032.3%20-8.9%208.9-19.7%2013.4-32.3%2013.4H82.2c-12.6%200-23.3-4.5-32.3-13.4%20-8.9-8.9-13.4-19.7-13.4-32.3V82.2c0-12.6%204.5-23.3%2013.4-32.3%208.9-8.9%2019.7-13.4%2032.3-13.4h237.5c12.6%200%2023.3%204.5%2032.3%2013.4%208.9%208.9%2013.4%2019.7%2013.4%2032.3V319.8L365.4%20319.8z%22%20fill%3D%22%23AAA%22%2F%3E%3C%2Fsvg%3E);
			background-size: 17px 18px;
			background-position: 1px center;

			.mobile-styles( {
				background-size: 24px 26px;
				min-height: 27px;
				line-height: 22px;
				display: block;
			}

			);
		}

		&:checked + label,
		&:checked + span.label {
			background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22475%22%20height%3D%22475%22%20viewBox%3D%220%200%20474.8%20474.8%22%3E%3Cpath%20d%3D%22M396.3%20257.1c-1.1-0.6-2.3-0.9-3.4-0.9%20-2.5%200-4.7%201-6.6%202.9l-18.3%2018.3c-1.7%201.7-2.6%203.8-2.6%206.3v72.5c0%2012.6-4.5%2023.3-13.4%2032.3%20-8.9%208.9-19.7%2013.4-32.3%2013.4H82.2c-12.6%200-23.3-4.5-32.3-13.4%20-8.9-8.9-13.4-19.7-13.4-32.3V118.6c0-12.6%204.5-23.3%2013.4-32.3%208.9-8.9%2019.7-13.4%2032.3-13.4H319.8c4.2%200%208.5%200.6%2012.8%201.7%201.1%200.4%202%200.6%202.6%200.6%202.5%200%204.7-0.9%206.6-2.9l14-14c2.3-2.3%203.1-5%202.6-8.3%20-0.6-3-2.3-5.2-5.1-6.6%20-10.3-4.8-21.4-7.1-33.4-7.1H82.2c-22.6%200-42%208-58.1%2024.1C8%2076.6%200%2096%200%20118.6v237.5c0%2022.6%208%2042%2024.1%2058.1%2016.1%2016.1%2035.5%2024.1%2058.1%2024.1h237.5c22.6%200%2042-8%2058.1-24.1%2016.1-16.1%2024.1-35.4%2024.1-58.1v-90.8C402%20261.4%20400.1%20258.6%20396.3%20257.1z%22%20fill%3D%22%23AAA%22%2F%3E%3Cpath%20d%3D%22M468%2093.2l-31.4-31.4c-4.6-4.6-10-6.9-16.3-6.9%20-6.3%200-11.7%202.3-16.3%206.9L219.3%20246.5l-75.1-75.1c-4.6-4.6-10-6.9-16.3-6.9%20-6.3%200-11.7%202.3-16.3%206.9l-31.4%2031.4c-4.6%204.6-6.9%2010-6.9%2016.3%200%206.3%202.3%2011.7%206.9%2016.3l122.8%20122.8c4.6%204.6%2010%206.9%2016.3%206.9%206.3%200%2011.7-2.3%2016.3-6.9l232.4-232.4c4.6-4.6%206.9-10%206.9-16.3S472.5%2097.8%20468%2093.2z%22%20fill%3D%22%23024a76%22%2F%3E%3C%2Fsvg%3E);
			background-size: 20px 22px;
			background-position: 1px center;

			.mobile-styles( {
				background-size: 28px 30px;
				min-height: 27px;
				line-height: 22px;
			}

			);
		}
	}

	input[type="radio"] {
		+ label,
		+ span.label {
			background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%3E%3Cpath%20d%3D%22M896%20256q-130%200-248.5%2051t-204%20136.5-136.5%20204-51%20248.5%2051%20248.5%20136.5%20204%20204%20136.5%20248.5%2051%20248.5-51%20204-136.5%20136.5-204%2051-248.5-51-248.5-136.5-204-204-136.5-248.5-51zm768%20640q0%20209-103%20385.5t-279.5%20279.5-385.5%20103-385.5-103-279.5-279.5-103-385.5%20103-385.5%20279.5-279.5%20385.5-103%20385.5%20103%20279.5%20279.5%20103%20385.5z%22%20fill%3D%22%23AAA%22%2F%3E%3C%2Fsvg%3E);
			background-size: 20px 20px;

			.mobile-styles( {
				background-size: 26px 26px;
				min-height: 27px;
				line-height: 22px;
			}

			);
		}

		&:checked + label,
		&:checked + span.label {
			background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22439%22%20height%3D%22439%22%20viewBox%3D%220%200%20438.5%20438.5%22%3E%3Cpath%20d%3D%22M409.1%20109.2c-19.6-33.6-46.2-60.2-79.8-79.8C295.7%209.8%20259.1%200%20219.3%200c-39.8%200-76.5%209.8-110.1%2029.4%20-33.6%2019.6-60.2%2046.2-79.8%2079.8C9.8%20142.8%200%20179.5%200%20219.3c0%2039.8%209.8%2076.5%2029.4%20110.1%2019.6%2033.6%2046.2%2060.2%2079.8%2079.8%2033.6%2019.6%2070.3%2029.4%20110.1%2029.4s76.5-9.8%20110.1-29.4c33.6-19.6%2060.2-46.2%2079.8-79.8%2019.6-33.6%2029.4-70.3%2029.4-110.1C438.5%20179.5%20428.7%20142.8%20409.1%20109.2zM353.7%20297.2c-13.9%2023.8-32.7%2042.6-56.5%2056.5%20-23.8%2013.9-49.8%2020.8-77.9%2020.8%20-28.2%200-54.1-6.9-77.9-20.8%20-23.8-13.9-42.6-32.7-56.5-56.5%20-13.9-23.8-20.8-49.8-20.8-77.9%200-28.2%206.9-54.2%2020.8-77.9%2013.9-23.8%2032.7-42.6%2056.5-56.5%2023.8-13.9%2049.8-20.8%2077.9-20.8%2028.2%200%2054.2%206.9%2077.9%2020.8%2023.8%2013.9%2042.6%2032.7%2056.5%2056.5%2013.9%2023.8%2020.8%2049.8%2020.8%2077.9C374.6%20247.4%20367.6%20273.4%20353.7%20297.2z%22%20fill%3D%22%23AAA%22%2F%3E%3Cpath%20d%3D%22M219.3%20146.2c-20.2%200-37.4%207.1-51.7%2021.4%20-14.3%2014.3-21.4%2031.5-21.4%2051.7%200%2020.2%207.1%2037.4%2021.4%2051.7%2014.3%2014.3%2031.5%2021.4%2051.7%2021.4%2020.2%200%2037.4-7.1%2051.7-21.4%2014.3-14.3%2021.4-31.5%2021.4-51.7%200-20.2-7.1-37.4-21.4-51.7C256.7%20153.3%20239.4%20146.2%20219.3%20146.2z%22%20fill%3D%22%23024a76%22%2F%3E%3C%2Fsvg%3E);
			background-size: 20px 20px;

			.mobile-styles( {
				background-size: 26px 26px;
				min-height: 27px;
				line-height: 22px;
			}

			);
		}
	}

	.form-group {
		margin-bottom: 15px;
		position: relative;
	}

	&.show-labels {
		label {
			clip: auto;
			color: #666;
			display: block;
			font-size: 12px;
			height: auto;
			margin-bottom: 4px;
			padding-left: 2px;
			position: static;
			width: auto;
		}

		input[type="checkbox"] + label,
		input[type="checkbox"] + span.label {
			.tablet-and-up-styles( {
				margin-top: 12px;
			}

			);
			font-size: inherit;
			color: inherit;
		}

		input[type="radio"] + label,
		input[type="radio"] + span.label {
			.tablet-and-up-styles( {
				margin-top: 10px;
			}

			);
			font-size: inherit;
			color: inherit;
		}
	}

	.input-btn-group {
		display: flex;
		width: 100%;

		input[type="text"],
		input[type="email"],
		input[type="number"] {
			border-radius: 4px 0 0 4px;
			border-right: none;
			flex: 1 1 auto;

			&:active, &:focus {
				border: solid 1px #aaa;
				border-right: none;
			}
		}

		button, input[type="submit"] {
			appearance: none;
			border: solid 1px @red-main;
			border-left: none;
			background: @red-main;
			border-radius: 0 4px 4px 0;
			color: #FFF;
			flex: 0 0 auto;
			font-size: 16px;
			padding: 0 14px;
			transition: background 0.2s;

			&:hover {
				background: @red-main-hover;
			}
		}
	}

	.radio-inline,
	.checkbox-inline {
		display: inline-block;
		vertical-align: middle;
		min-height: 30px;
		text-align: center;
	}
}

.form-password-rules {
	background: #F2F3F8;
	padding: 16px 25px 10px 25px;
	min-width: 280px;
	margin: 5px auto 40px;

	&__bullet-point-wrapper {
		width: 14px;
		text-align: center;
		margin-right: 5px;
	}

	&__bullet-point {
		color: inherit;
	}

	li {
		font-weight: 600;
		color: #4C5271;
		margin-bottom: 5px;
		font-size: 14px;
		display: flex;

		.icon {
			display: none;
			color: inherit;
			font-weight: bold;
			margin-right: 5px;
		}
	}

	&.two-column {
		& > li {
			text-align: left;
		}
		.tablet-and-up-styles({
			max-width: 520px;
			flex-wrap: wrap;
			flex-direction: row;

			& > li {
				width: 46%;

				&:nth-child(even) {
					padding-left: 1rem;
				}
			}
		});
	}
}

#passwordRequirements {
	max-width: 280px;
	margin: 0 auto;
}

.update-form-wrapper {
	max-width: 420px;
	margin: 0 auto;

	h4 {
		font-weight: bold;
	}

	.icon-blain-eagle-lghead {
		color: #4C5271;
		margin-bottom: 5px;
		font-size: 40px;
	}

	.form-group-new-password {
		margin-bottom: 0.5rem;
	}

	#updatePasswordForm {
		.password-strength {
			margin-bottom: 0.5rem;
		}
	}

	.blain-form label.error:not(.display) {
		position: static;
		clip: unset;
		width: auto;
		height: auto;
		color: #E31B23;
		font-size: 12px;
		font-weight: bold;
		margin-top: 5px;
	}

	.error-message {
		display: block;
		margin: 0 auto;
		color: @DR;
		min-height: 1rem;
	}
}
.speedier-form,
.update-form-wrapper,
#activateForm,
#resetForm {
	.show-password-wrapper {
		.show-password-form-group {
			position: absolute;
			top: 0;
			right: 0;
			width: 25px;
			height: 42px;
		}
	}

	#newPassword {
		height: 42px;
		position: relative;
		padding-top: 12px;
		padding-bottom: 0;
		&:focus {
			border: 1px solid rgb(178,184,215);
		}

		&::before {
			content: "*New Password";
			position: absolute;
			top: 1px;
			left: 2px;
			color: #656E97;
			font-size: 12px;
		}
	}
	.form-control.error, .form-control.valid {
		background-image: none!important;
		&:focus {
			background-image: none!important;
		}
	}


	input[type="checkbox"].show-password-btn, .overlay-icon {
		position: absolute;
		width: 100%;
		height: 100%;
		margin: 0;
	}

	.overlay-icon {
		font-size: 20px;
		color: @DB;
		padding-top: 10px;
	}

	label.new-password-label:not(.display) {
		color: #656E97;
		z-index: 1;
		font-size: 12px;
		top: 3px;
		left: 10px;
		width: auto;
		height: auto;
		margin: 0;
		clip: rect(auto, auto, auto, auto);
	}


	.red-btn {
		margin-top: 20px;
		border-radius: 4px;
		padding: 12px;

		&.submit-btn {

			&.disabled {
				pointer-events: none;
				opacity: .5;
			}
		}
	}
}
/*=======================================================================
== Form Alerts
========================================================================*/


.blain-alert {
	position: relative;
    margin: 0 0 20px;
    color: #C00;
    padding: 20px 20px 20px 50px;
    background-color: #FFF0F0;
    border: 1px solid #C00;
    font-size: 14px;
	line-height: normal;
	text-align: left;

	.fa {
		position: absolute;
		left: 20px;
		top: 20px;
	}
}

.blain-warning {
	position: relative;
	margin: 0 0 20px;
	color: #C00;
	padding: 20px 20px 20px 50px;
	background-color: #FFFFCC;
	border: 1px solid #C90;
	font-size: 14px;
	line-height: normal;
	text-align: left;

	.fa {
		position: absolute;
		left: 20px;
		top: 20px;
		color: #C00;
	}
}

/*=======================================================================
== Google reCAPTCHA
========================================================================*/

#loginCaptcha > div {
	margin: 0 auto;
}

// Fix for the Firefox select box indentation
@-moz-document url-prefix() {
    .blain-form select.form-control {
		text-indent: -2px;
    }
}

.tire-form{
	h3{
		margin-top:25px;
		font-size:20px;
		font-weight:700;
	}
	padding-bottom:25px;
	margin-bottom:auto;
}

.file-group {
	position: relative;

	label {
		display: block !important;
		margin: 0 auto !important;
		padding: 8px !important;
		border: 4px solid #ddd !important;
		color: @blue-main !important;

		.tablet-and-up-styles({
			font-size: 16px !important;

		});

		font-size: 14px !important;
	}

	input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 0;
	}

	margin-bottom: 15px;
}

.contact-form {
	.tablet-and-up-styles({
		border-top: 1px solid #ccc;
		padding-top: 25px;
	});
}

/*=======================================================================
== Custom On/Off Switch
========================================================================*/

.on-off-switch {
	position: relative; width: 50px;
	user-select: none;
}

.on-off-switch-checkbox {
    display: none;
}

.on-off-switch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 20px; padding: 0; line-height: 20px;
    border: 2px solid #B0B0B0; border-radius: 20px;
    background-color: #EEEEEE;
    transition: background-color 0.3s ease-in;
}

.on-off-switch-label:before {
    content: "";
    display: block; width: 20px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 28px;
    border: 2px solid #B0B0B0; border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.on-off-switch-checkbox:checked + .on-off-switch-label {
    background-color: #44E344;
}

.on-off-switch-checkbox:checked + .on-off-switch-label, .on-off-switch-checkbox:checked + .on-off-switch-label:before {
   border-color: #44E344;
}

.on-off-switch-checkbox:checked + .on-off-switch-label:before {
    right: 0px;
}

.password-strength {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	min-height: 1.35rem;

	&__segment {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 19%;
		height: 100%;
	}

	&__bar {
		height: 0.4rem;
		width: 100%;
		background: #F5F5F5;
	}

	&__label {
		font-size: 0.6rem;
		line-height: 1.3;

		.tablet-and-up-styles({
			font-size: 0.7rem;
			font-weight: 500;
		});
	}

	&.very-weak {
		color: @DR;
		.password-strength__segment--filled .password-strength__bar {
			background: @BR;
		}
	}
	&.fair {
		color: @AAOCH;
		.password-strength__segment--filled .password-strength__bar {
			background: @YL;
		}
	}
	&.good, &.strong, &.very-strong{
		color: @AAEME;
		.password-strength__segment--filled .password-strength__bar {
			background: @AAEME;
		}
	}
}
