
@import "~@less/_mixins.less";
.header-drop-menu {
	box-shadow: 0 0 10px 0 rgba(0,0,0,.25);
	margin-top: 15px;
	top: 100%; right: -20px;
	max-width: 100vw;
	position: absolute;
	z-index: 11;
	&.center {
		transform: translateX(50%);
	}
	&.left {
		left: 0;
		right: auto;
	}
	.mobile-styles({
		bottom: auto;
		top: auto;
		transform: none !important;
		position: fixed;
		top: 20px !important;
		left: 10px !important;
		right: 10px !important;
		z-index: 99;
	});
}
.header-drop-menu__chevron-button {
	position: relative;

	&:not(:focus) {
		.hide-chevron-icon {
			display: none;
		}
	}

	.hide-chevron-icon {
		//Absolute position to prevent layout shift.
		position: absolute;
		inset: 0;
		font-size: 12px !important;
		margin: 0 !important;
	}
}

.header-drop-menu-content {
	background-color: #FFF;
	width: 100%;
}
.header-drop-menu-arrow {
	background: #FFF;
	box-shadow: 0 0 10px 0 rgba(0,0,0,.25);
	height: 16px;
	right: 30px;
	top: -8px;
	transform: rotate(45deg);
	width: 16px;
	z-index: -1;
	&.center {
		right: 50%;
	}
	&.left {
		left: 15%;
		right: auto;
	}
}
.hdm-trigger p {
	margin: 0;
	padding: 0 0 0 2px;
}
.hdm-fade-enter-active {
	transition: opacity 0.3s;
}
.hdm-fade-leave-active {
	transition: opacity 0.1s;
}
.hdm-fade-enter, .hdm-fade-leave-to {
	opacity: 0;
}
