
@import '../../less/_mixins.less';

.sidemenu-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1004; // place over search bar
	pointer-events: none;

	&--open {
		pointer-events: auto;
	}
}

.sidemenu__overlay {
	position: absolute;
	width: 100vw;
	height: 100%;
	background: #000;
	opacity: 0.5;
}

.sidemenu {
	--sidemenu-width: 475px;

	width: var(--sidemenu-width);
	display: flex;
	flex-direction: column;
	position: absolute;
	max-width: 100%;
	height: 100%;
	overflow-y: scroll;
	background: #fff;
	z-index: 1;

	&--right {
		right: 0;
	}

	&--sm {
		--sidemenu-width: 300px;
	}
}

.sidemenu__close-button-wrapper {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background-color: white;
	z-index: 1;
}

.sidemenu__close {
	border-bottom: 2px solid #E5E7F1;
	padding: 5px 0;
	margin: 0 10px;

	&__button {
		.button-reset();
		color: #000;
		padding: 10px;
		margin-left: -10px;

		> span {
			display: flex;
			line-height: 1;
			font-size: 1rem;
		}
	}

	.icon {
		color: #2487C4;
		margin-right: 5px;
	}
}

.sidemenu__content {
	flex: 1;
}

.slide-in-enter, .slide-in-leave-to {
	transform: translateX(var(--sidemenu-translateX, 100%));
}

.slide-in-enter-to {
	transform: translateX(0);
}

.slide-in-enter-active, .slide-in-leave-active {
	transition: all 0.3s ease;
}

