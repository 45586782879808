
@import '~@less/_mixins.less';
#siteHeaderNotifications {

	.header-notification-icon {
		display: inline-block;
		font-size: 30px;
		width: 30px;

		.tablet-and-up-styles({
			width: 40px;
			font-size: 40px;
		});
	}

	#notificationsDropdown {
		width: 330px;
		.mobile-styles({
			width: 100%;
		});
	}
}
