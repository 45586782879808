
@import '../../../less/_mixins.less';
@checkboxSize: 1.25rem;

.blain-form-checkbox {
	display: flex;
	align-items: center;
	font-size: 1em;
	position: relative;
	user-select: none;

	label {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	label.disabled {
		cursor: not-allowed;
		color: #444;
	}

	.checkmark {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: white;
		border: 1px solid @LB;
		border-radius: 2px;
		width: @checkboxSize;
		height: @checkboxSize;
		font-size: 0.875rem;
		margin-right: 0.625rem;
		transition: background-color 0.05s ease;
		flex-shrink: 0;
	}

	.checkmark.checked {
		background-color: @LB;
	}

	.checkmark.disabled {
		border-color: #888;
	}

	.checkmark.checked.disabled {
		background-color: #999;
	}

	.checkmark.error {
		border-color: #B6161C;
	}

	input:checked ~ .checkmark {
		background-color: @LB;
		&.disabled {
			opacity: 0.4;
		}
	}

	label:hover .checkmark:not(.disabled),
	input:focus ~ label .checkmark:not(.disabled) {
		outline: 3px solid tint(@LB, 60%);
	}

	.indeterminate-marker {
		display: none;
	}

	input:indeterminate ~ label .checkmark .indeterminate-marker {
		display: block;
		height: 0.1875rem;
		width: 0.75rem;
		border-radius: 2px;
		background-color: @LB;
	}
}
