
@import '../../../less/_mixins.less';
.blain-form-radio-card {
	border: 1px solid #CBD0E4;
	border-radius: 6px;
	padding: 10px;
	background-color: white;
	position: relative;
	z-index: 1;

	button, a {
		// This places buttons and links above the full-size, clickable label so that
		// they can be clicked
		position: relative;
		z-index: 2;
	}

	&.selected {
		border: 2px solid #2487C4;
		padding: 9px;

		&.error {
			background-color: #FCE9E9;
			border-color: #E31B23;

			.blain-form-radio-card__radio-wrapper.selected {
				background-color: #B12F31;
				border-color: #B12F31;
			}
		}
	}

	&.disabled {
		background-color: #F2F3F8;
	}

	&__inner {
		display: flex;
		height: 100%;
	}

	&__header {
		display: flex;
		align-items: flex-start;

		.desktop-min-only-styles({
			align-items: center;
		});
	}

	&__body {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	&__title {
		color: black;
		font-size: 1rem;
		font-weight: 500;
		line-height: 1.3125;
		cursor: pointer;

		.disabled & {
			color: #636C94;
			cursor: default;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		.desktop-min-only-styles({
			font-size: 1.25rem;
		});
	}

	&__actions {
		margin-top: auto;
		padding-top: 6px;
	}
}

.blain-form-radio-card__radio-wrapper {
	position: relative;
	border-radius: 50%;
	flex-shrink: 0;
	margin-left: 15px;
	height: 20px;
	width: 20px;
	border: 1px solid #B2B8D7;
	z-index: -1;
	align-self: center;

	&.selected {
		border-color: #2079B0;
		background-color: #2079B0;
	}

	.disabled & {
		opacity: 0;
	}

	&:focus-within {
		outline: 3px solid tint(@LB, 60%);
	}
}

.blain-form-radio-card__radio {
	.visually-hidden();
}

.blain-form-radio-card__radio-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 14px;
}
