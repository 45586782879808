
@import "~lessRoot/_mixins.less";

.create-account-page {
	&__header {
		font: normal normal bold 1.5rem/1.25 Roboto;
		color: #191B25;
		margin-bottom: 5px;
	}

	&__header:focus {
		outline: none;
	}

	&__subtitle {
		font: normal normal normal 1rem/1.25 Roboto;
		color: #4C5271;
		margin-bottom: 32px;
	}

	&__error-banner {
		margin-bottom: 15px;
		font-size: 0.875rem;
	}

	&__error-banner-text {
		color: black;
		letter-spacing: 0.42px;
		line-height: 1.29;
		font-weight: bold;
	}

	&__form-area {
		padding: 20px 15px;
		background-color: #F2F3F8;
		margin-bottom: 30px;
		text-align: left;
	}

	&__name-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
	}

	&__input-field {
		margin-bottom: 12px;
	}

	&__create-account-button {
		margin: 20px 0;
		width: 100% !important;
		max-width: unset !important;
		font-size: 1.125rem !important;

		.mobile-and-up-styles({
			font-size: 1.25rem !important;
		});
	}

	&__remember-me-wrapper {
		display: flex;
		justify-content: center;
	}

	&__remember-me-button {
		label {
			color: #4C5271;
			font-size: 0.875rem;
		}
 	}

	&__terms-wrapper {
		text-align: center;
		font: normal normal normal 0.875rem/1.7 Roboto;
		color: #4C5271;
		margin: 0 auto 32px auto;
		max-width: 215px;
	}

	&__link-button {
		font-size: 0.875rem;
	}
}
