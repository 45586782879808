
@import '~@less/_mixins.less';
.notif-summary-wrapper {
	border-bottom: 2px solid #E5E7F1;
	max-width: 100%;
	&:last-child {
		border: none;
	}
	.mobile-styles({
		max-width: 100%;
	});
	.notif-content-wrapper {
		cursor: pointer;
		display: flex;
		align-items: center;
		text-decoration: none;
		padding: 5px;
		background: #ECEEF5;
		&.viewed, &.clicked {
			background: #fff;
		}
	}
	.image {
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;

		img {
			width: 60px;
		}

		div {
			height: 100%;
			margin: 0 auto;
			max-width: 35px;
			width: 100%;

			&[class*="svg"] {
				align-self: center;
				height: 22px;
			}
			// icon is a little smaller than svg
			&.icon {
				color: @LB;
				font-size: 26px;
				align-self: center;
				height: 26px;
				min-width: 60px;
			}
		}
	}
	.content {
		margin-left: 10px;
		text-align: left;
		p {
			margin: 0;
			padding: 0;
		}
		.main-text {
			.subject {
				padding: 0;
				margin: 0;
				color: @LB;
				font-size: 14px;
				font-weight: 500;
				line-height: 16px;
				&:hover {
					color: @DB;
					text-decoration: underline;
				}
			}
			.body {
				padding: 0;
				margin: 2px 0 0 0;
				color: #666666;
				font-size: 12px;
				line-height: 14px;
			}
		}
	}
}
.expandable-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	.product-notif {
		max-width: 50%;
		cursor: pointer;
		text-decoration: none;
		padding: 5px;
		.mobile-styles({
			margin: 10px;
			max-width: 45%;
		});
		.ms-styles({
			margin: 0;
		});
		.product-img {
			width: 100px;
			height: 100px;
			position: relative;
			margin: 0 auto;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.price {
			margin-top: 5px;
			.reg-price, .sale-price {
				font-weight: bold;
				font-size: 16x;
				line-height: 14px;
			}
			.original-price {
				font-size: 12px;
				text-decoration: line-through;
			}
			.sale-price {
				color: #B12F31;
			}
		}
		.product-name {
			line-height: 18px;
			font-weight: bold;
			font-size: 14px;
			margin-top: 10px;
		}
	}
}
