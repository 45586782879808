
.product-summary-wrapper {
	background-color: #fff;
	color: #000000;
	margin-bottom: 10px;
	padding: 5px;
	text-decoration: none;
}

.image {
	margin-top: 10px;
	flex: 1 1 120px;
	margin-right: 8px;
}

.product-detail {
	flex: 1 1 250px;
	line-height: 22px;
}


.price {
	> span {
		font-weight: 400;
		color: #656884;
		font-size: 12px;
		padding-top: 1px;
	}
}

.product-name,
.warranty-name {
	color: #656884;
}

.price-amount-wrapper {
	display: inline-block;
}

.price .dollar-sign {
	vertical-align: text-bottom;
	align-self: flex-start;
	padding: 4px 1px 0 0;
}

.price-prefix {
	text-transform: uppercase;
	line-height: 14px;
	margin-right: 5px;
}

.original-price {
	margin-left: 5px;
	span {
		text-decoration: line-through;
	}
}

.product-warranties {
	margin-top: 8px;

	.price-amount-wrapper {
		display: inline-block;
		margin-right: 5px;
	}
	.dollar-sign {
		padding: 0 1px 2px 0;
	}
}
