
@import '~lessRoot/_mixins.less';

#headerSearchSuggestions {
	background-color: #FFF;
	border-radius: 30px 30px 0 0;
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
	            0 1px 10px 0 rgba(0, 0, 0, 0.12),
	            0 2px 4px -1px rgba(0, 0, 0, 0.4);
	padding-top: 50px;
	top: 0; right: 0; left: 0;
	z-index: -1;

	p.fs-10 {
		color: shade(@CL, 30%);
		font-weight: 500;
		letter-spacing: .085em;
	}

	a b {
		font-weight: bold;
	}

	li:focus, li:focus-visible,
	a:focus, a:focus-visible {
		outline: #5B9DD9 auto 5px !important;
	}
}

#hssCategories,
#hssBrands,
#hssPopular {
	padding: 5px 15px 10px;
	a {
		padding: 8px 0;
	}
}

#hssProducts {
	background-color: #f2f3f8;
	padding: 10px 15px;

	a {
		background-color: #FFF;
		padding: 5px;
	}

	img {
		margin-right: 10px;
	}

	a p {
		color: #000;
		line-height: 1.2em;
	}

	a p span {
		color: #666;
		font-size: 13px;
	}
}

#hssViewAll a.light-blue-btn {
	border-radius: 4px;
	color: shade(@LB, 10%);
	padding: 5px;
	line-height: 1em;
}
