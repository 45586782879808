#noResults {
	#visualDeptNav {
		h2 {
			text-align: center;
		}
	}
}

.hawk-banner {
	img {
		width: 100%;
		height: auto;
	}
}