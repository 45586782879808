
@import "../../less/_mixins.less";
.social-icons-wrapper a.social-icon-link {
	display: inline-block;
	margin: 0 0 0 10px;
	&.icon-grey {
		color: #717171;
	}
	&.icon-darkblue {
		color: @DB;
	}
	&.icon-blue {
		color: #346E92;
	}
	&.icon-lightblue {
		color: @LB;
	}
	&.icon-white {
		color: #fff;
	}
	&.icon-sm {
		font-size: 16px;
	}
	&.icon-md {
		font-size: 24px;
	}
	&:first-child {
		margin-left: 0;
	}
	.fa {
		transition: color 0.25s;
		cursor: pointer;
		&:focus {
			outline: none;
		}
	}
	.mobile-styles({
		.social-icon-link:first-child {
			margin: 5px 0 5px 5px;
		}
	});
}
