
@import '../../../../less/_mixins.less';

.footer-customer-care {
	a {
		line-height: 1em;
	}
	p {
		color: #4C5271;
		margin-bottom: 10px;
	}
	span.icon {
		color: @DB;
		font-size: 20px;
		margin-right: 3px;
	}

	.fcc-div {
		background-color: #CBD0E4;
		flex: 0 0 1px;
		width: 1px;
	}

	.mobile-styles({
		border-left: solid 2px #CBD0E4;
		padding-left: 15px;

		.fcc-links {
			
			max-width: 220px;

			a {
				font-size: 12px;
			}
		}
	})
}
