
    @import "~lessRoot/_mixins.less";

.user-verify {
	text-align: center;

	&__header-image-lock {
		width: 75px;
		height: 75px;
		margin: 60px auto 10px auto;
	}

	&__header-image {
		width: 100%;
		margin-bottom: 5px;
	}

	&__header {
		font: normal normal bold 1.5rem/1.25 Roboto;
		color: #191B25;
		margin-bottom: 20px;
	}

	&__header:focus {
		outline: none;
	}

	&__subtitle {
		font: normal normal normal 0.875rem/1.3 Roboto;
		color: #4C5271;
		margin-bottom: 15px;
	}

	&__redacted-wrapper {
		margin-bottom: 30px;
	}

	&__redacted {
		color: #191B25;
		font: normal normal bold 0.875rem/1.3 Roboto;
		margin-top: 5px;
	}

	&__continue {
		margin: 0 auto;
		max-width: unset;
		width: unset;
		min-width: 193px;
		margin-bottom: 10px;
		padding: 10px 24px;
		font-size: 1rem !important;

		.mobile-and-up-styles({
			font-size: 1.125rem !important;
		});
	}

	&__link-button {
		font-size: 0.875rem;
		margin: 30px auto 0 auto;
	}

	&__bottom {
		margin-top: 30px;
	}

}


