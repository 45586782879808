
@import '~@less/_mixins.less';

#storeFinderDropdown {
	max-width: 100%;
	width: 540px;

}

#siteHeaderStoreFinder {
	.header-drop-menu-arrow {
		left: 40px;
	}
	.tablet-styles({
		.header-drop-menu-arrow {
			left: auto;
			right: 30px;
		}
	})
}
