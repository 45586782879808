@import '../_mixins.less';

/*=======================================================================
== Tires / Auto Services Listings
========================================================================*/

.list-auto-item {
	border-bottom: solid 1px #eee;
	padding: 25px 0;
	width: 100%;
	img {
		max-width: 100%;
	}
	.list-item-price {
		flex: 0 0 200px;
		.ms-styles({
			flex: 0 0 105px;
		});
	}
	.list-item-name {
		font-size: 22px;
		line-height: 1.3em;
		.tablet-styles({
			font-size: 18px;
		});
		.mobile-styles({
			font-size: 14px;
		});
		.ms-styles({
			font-size: 12px;
		});
	}
	.list-item-price-body {
		text-align: right;
		.red-btn {
			margin-bottom: 10px;
			.ms-styles({
				display: none;
			});
		}
		.blue-btn {
			.ms-styles({
				display: none;
			});
		}
	}
	.red-btn {
		margin-bottom: 0;
	}
	.reg-price, .sale-price {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 10px;
		.ms-styles({
			font-size: 14px;
		});
	}
	.original-price {
	    text-decoration: line-through;
		margin-bottom: 5px;
    }
	.sale-price {
		color: @red-main;
	}
	.light {
		.ms-styles({
			font-size: 12px;
		});
	}
	.promotion-badge {
        top:0;
        padding:5px;
        min-width:100px;
        font-size:80%;
        .iphone-styles({
            min-width:100%;
            font-size:12px;
        });
    }
    .restriction-badge{
        background-color:#757575;
        bottom:0;
        padding:5px;
        font-size:80%;
    }
}

.list-auto-item-mobile-btns {
	.ms-styles({
		display: block;
		margin: 20px auto 0;
		.red-btn {
			margin-bottom: 10px;
		}
	});
}

.list-auto-item-body {
	ul {
		list-style: disc;
		padding-left: 40px;
	}
	li {
		font-size: 15px;
		line-height: 1.3em;
	}
}

.list-auto-item-img-container {
	flex: 0 1 200px;
	margin-right: 20px;
}

.list-item-img-bg {
    padding-top: 100%;
	background-size: 165% !important;
	background-position: center !important;
	overflow: hidden;
}

.list-auto-item-details {
	margin-top: 10px;
	.styles-at(1200px, {
		display: none;
	});
}

.mile-warranty span {
	font-size: 30px;
	margin-right: 15px;
}

.tire-att {
	margin-right: 15px;
	img {
		height: 40px;
		margin-right: 10px;
		width: 40px;
	}
}

#tireNoStock,
#batteryNoStock {
	text-align: center;
	margin-bottom: 50px;
	line-height: 1.5em;

	br {
		.mobile-styles({
			display: none;
		});
	}

	> div {
		.mobile-styles({
			padding: 0 15px;
		})
	}

	> span {
		margin-top: 8px;
		font-size: 22px;
		display: inline-block;
	}

	.please-call {
		margin-top: 5px;
	}
}

#tireNoStockHeader,
#batteryNoStockHeader {
    text-align: center;
    .mobile-styles ({
        font-size:22px;
        line-height:26px;
    });
}

/*=======================================================================
== (Legacy) Product Listings - ex: /promo-deal/
========================================================================*/
#NonHawkList{
    .list-products{
        margin:0;
    }
    .list-wrapper{
        width:100%;
        max-width:100%;
    }
	.list-item {
        .styles-at(549px, {
            flex: 0 1 50%;
        });

        .styles-at-min(550px, {
            flex: 0 1 33.3%;
        });

        .styles-at-min(650px, {
            flex: 0 1 25%;
        });

        .styles-at-min(769px, {
            flex: 0 1 33.3%;
        });

        .styles-at-min(1000px, {
            flex: 0 1 25%;
        });

        .styles-at-min(1250px, {
            flex: 0 1 20%;
        });

        .styles-at-min(1440px, {
            flex: 0 1 16.6%;
        });
    }
}


.list-item {
    height:auto;
    padding:15px 0;
    .styles-at(768px,{
        padding:10px 0;
    });
    outline:none;
    > div {
		margin: 0 auto;
		position: relative;
        width:200px;
        height:100%;
        .styles-at(768px,{
            width:140px;
        });
		&:active, &:hover, &:visited {
			color: inherit;
			text-decoration: none;
		}
    }
	a {
		color: inherit;
		display: block;
		outline:none;
		text-decoration: none;
	}
    .list-item-img-container{
        position:relative;
        height: 200px;
        width: 200px;
        .styles-at(768px,{
            height:120px;
            width:120px;
        });
    }
    .list-item-img:not(.swatch-img),img:not(.swatch-img) {
        height: 200px;
        width: 200px;
        .styles-at(768px,{
            height:120px;
            width:120px;
        });
    }
    .swatches{
        padding:5px 0;
        height:35px;
        .swatch-body{
            .mobile-styles({
                justify-content: center;
            });
            .swatch-plus{
                display:none;
            }
            &.count-5{
                .swatch-plus{
                    display: flex;
                }
            }
            .styles-at(768px,{
                &.count-2{
                    .swatch-plus{
                        display:none;
                    }
                }
                &.count-3{
                    .swatch-plus{
                        display:none;
                    }
                }

                &.count-5,&.count-4{
                    .swatch{
                        &:nth-of-type(3),
                        &:nth-of-type(4){
                            display:none;
                        }
                    }
                    .swatch-plus{
                        display: flex;
                    }
                }
            });
        }
        .swatch{
            height:25px;
            width:25px;

            img{
				outline: solid 1px #cccccc;
                height:25px;
                width:25px;
            }
            margin-right:10px;
            transition: box-shadow 150ms;
            &.selected {
                background: #FFF;
				outline: solid 2px #222;
				padding: 1px;
				img {
					height: 23px;
					width: 23px;
				}
            }
        }
        .swatch-plus{
            color:#FFF;
            background-color:@blue-light;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .list-item-price {
	    height: 40px;
	    margin-top: 25px;
		margin-bottom: 5px;
    }
    .list-item-name {
	    margin-bottom: 25px;
        margin-top:5px;
        font-weight:300;
        line-height:17px;
		&.low-stock {
			margin-bottom: 42px;
		}
    }
    .list-item-stock{
		bottom: 24px;
    }
    .original-price {
	    position: absolute;
	    transform: translateY(-17px);
	    font-size: 14px;
	    text-decoration: line-through;
    }
    .ratings {
	    bottom: 0;
	    height: 20px;
	    position: absolute;
	    width: 100%;
    }
    .reg-price {
	    font-size: 20px;
	    font-weight: 700;
    }
    .range-price{
        .mobile-styles({
            font-size:16px;
        });
    }
    .map-text{
        font-size: 16px;
        font-weight: 700;
		line-height: normal;

        .mobile-styles({
            font-size:13px;
        });
    }
    .sale-price {
	    color: @red-main;
	    font-size: 20px;
	    font-weight: 700
    }
    .promotion-badge {
		background-color: #b22b2c;
		color: #FFF;
        top:0;
        padding:5px;
        min-width:100px;
        font-size:80%;
    }
    .restriction-badge{
        background-color:#757575;
        bottom:0;
        padding:5px;
        font-size:80%;
    }
}

.list-category {

	h1 {
		.tablet-styles({
			font-size: 26px;
		});
		.styles-at(768px, {
			display: none;
		});
	}
}

.list-container {
    .mobile-header {
        .mobile-header-title {
            display: block;
            font-family: @font-body;
            line-height:1.3em;
            font-size: 22px;
        }

        .mobile-header-categories {
            margin: 15px 0;
        }

        .mobile-header-count {
            display:block;
            font-size:12px;
            line-height:normal;
            font-family: @font-body;
            color:#999;
        }

        .styles-at(768px,{
            display:block;
            margin-bottom:15px;
        });
    }
    .list-sidebar {
        min-width:230px;
        width:230px;
        padding-right:15px;
        border-right:2px solid @blue-light;
        form{
            width:100%;
        }
        .styles-at(768px,{
            min-width:unset;
            width:100%;
            border:none;
            padding:0;
        });

        .list-header-sort-options{
            .styles-at(768px,{
                .option{
                    display: flex;
                    align-items: center;
                    background-color: @blue-main;
                    color: #fff;
                    margin: 0;
                    padding: 12px 21px 12px 10px;
                    margin-bottom:2px;
                    position:relative;
                    font-family:@font-body;
                    height:44px;

                    &.active::after{
                        position:absolute;
                        right:15px;
                        top:10px;
                        font-size:20px;
                        content: "\f00c";
                        display: block;
                        font-family: FontAwesome;
                    }
                }
                &.open{
                    display:block;
                }
                &.closed{
                }
            });
        }
        .list-sidebar-tab{
            width: 100%;

            .simple-select{
                display:none;
            }
            .styles-at(768px,{
                > div{
                    display: flex;
                    flex: 1 1 50%;
                }
                .list-header-sort{
                    margin-left:10px;
                }
                .simple-select{
                    width:100%;
                    display:block;
                    font-family:@font-body;
                    margin-bottom:2px;
                    font-weight:700;

                    .header{
                        font-size:16px;
                    }
                    i{
                        display:block;
                        height:35px;
                        width:29px;
                        padding-top:6px;
                    }
                }
            });
        }
    }
    .list-body{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: auto;

        .list-item {
			border-bottom: solid 1px #ddd;
			margin-bottom: 10px;
            .styles-at(549px, {
                flex: 0 1 50%;
            });

            .styles-at-min(550px, {
                flex: 0 1 33.3%;
            });

            .styles-at-min(650px, {
                flex: 0 1 25%;
            });

            .styles-at-min(769px, {
                flex: 0 1 50%;
            });

            .styles-at-min(1000px, {
                flex: 0 1 33.3%;
            });

            .styles-at-min(1250px, {
                flex: 0 1 25%;
            });

            .styles-at-min(1440px, {
                flex: 0 1 20%;
            });
        }
    }

    .list-header-container{
        padding-bottom:20px;
		margin:0;
        border-bottom:2px solid @blue-light;
        .styles-at(767px,{
            display:none;
        });

        p{
            font-family:@font-body;
            font-size:16px;
        }
        .list-header-text{
            font-family:@font-headers;
			h1{
				margin-bottom:2px;
                font-family:@font-body;
				font-weight: 700;
				font-size: 28px;
			}
            span{
                font-family:@font-body;
                color:#999;
                font-size:14px;
            }
            .styles-at(768px,{
                display:none;
            });
        }
        .list-header-sort{
            .simple-select{
                width:120px;
                .styles-at(768px,{
                    display:none;
                });
            }
        }
    }

    .list-footer-container{
        padding-bottom:20px;
		margin:0;
        .styles-at(768px,{
            margin:0;
        });
        .tablet-styles({
            flex-direction: column;
            align-items: flex-end;
            > div{
                margin-top:10px;
            }
        });
        .list-footer-pagination{
            .mobile-styles({
                width:100%;
                text-align:end;
                font-size:15px;
            });
            > a{
                text-decoration:none;
                color:black;
                padding:5px;
                &:hover{
                    text-decoration:underline;
                }
                &.disabled{
                    opacity:.5;
                }
                &.active{
                    background-color:@blue-light;

                }
            }
        }
        .list-footer-size{
            margin-left:40px;
            .list-footer-size-label{
                font-weight:700;
            }
            .simple-select{
                margin-left:20px;
                width:80px;
            }
        }
    }
}

.simple-select{
    background-color: @blue-light;
    font-size: 14px;
    border-radius:2px;
    color:#fff;
    position:relative;
    &.active .header{
        background-color: @blue-main;
    }
    &.active .header .arrow::before{
        content: "\f077";
    }
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: background-color 250ms cubic-bezier(.55,0,.1,1);
        background-color:inherit;
        padding:5px 10px;

        &:hover,&:active{
            cursor:pointer;
            background-color: @blue-main;
        }
        .arrow{
            height:35px;
            padding-top:7px;
            font-size:24px;
            &::before{
                font-size:20px;
                content: "\f078";
			    display: block;
			    font-family: FontAwesome;
            }
        }
    }
    .options{
        font-family:@font-body;
        border-bottom-left-radius:2px;
        border-bottom-right-radius:2px;
        position: absolute;
        z-index: 10;
        width: 100%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);

        .option{
            transition: background-color 250ms cubic-bezier(.55,0,.1,1);
            background-color:#fff;
            color:black;
            padding:10px;
            &:hover{
                background-color:@grey-main;
                cursor:pointer;
            }
            &.active{
                background-color:#757575;
                color:#fff;
            }
        }
    }

    .options div:hover{
        background-color:@blue-light;
    }

    .closed{
        display:none;
    }
}

/*=======================================================================
== Product List
========================================================================*/


#plp-lading-page-wrapper,
#plp-carousel-wrapper {
    display: none;
    .is-landing-page & {
        display: block;
    }
}

.dxp-content-product-list-wrapper {
	margin: 0 -10px;

	.tablet-and-up-styles({
		margin: 0 -15px;
	});

	//When on desktop make the widgets no larger than the product list section
	.styles-at-min(1470px, {
		margin: 0;
	});
}

.product-list-head {

	&.is-dxp-page {
		padding-top: 0;
	}

    .mobile-styles({
        padding: 0;
    });

    .tablet-and-up-styles({
        padding-top: 0.3rem;

		&.is-landing-page {
			display: none;
		}
    });

	.desktop-min-only-styles({
		padding-top: 1.3rem;
    });

    .search-redirect {
        display: block;
        margin-top: 5px;
        font-weight: 500;
        font-size: 14px;
        color: @BB;
    }
}

#product-list-title {
    display: inline;
	color: black;
	font-weight: 700;
	font-size: 1.25rem;
    line-height: 1.3;

	.desktop-min-only-styles({
		font-size: 1.75rem;
    });

    .is-landing-page & {
        display: none;
        .tablet-styles({
            display: inline;
            margin: 0;
        });
        .mobile-styles({
            margin: 1rem 0 0 0;
        });
    }
}

.product-list-total-results {
    display: inline-block;
    font-size: 0.875rem;
    padding-left: 0.25rem;
    color: #4C5271;

    .is-landing-page & {
        display: none;
    }

    .mobile-styles({
        color: #4C5271;
        padding-top: 10px;
    });
}


.product-placeholders-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
}

.tire-placeholders-wrapper {
    margin-top: 15px;
}

@keyframes fadeIt {
    0%   { opacity: 0.3; }
    50%  { opacity: 0.1; }
    100% { opacity: 0.3; }
}

.grey-skeleton-loader {
    display: flex;
    flex-direction: column;
    .inner {
        background-image:none !important;
        animation: fadeIt 2s ease-in-out;
        animation-iteration-count: infinite;
        height: 100%;
        width: 100%;
        flex: 1;
		background-color: @CL;
		opacity: 0.3;
    }

    &.filter-header {
        min-height: 70px;
        margin-bottom: 20px;
    }

    &.horizontal-product-placeholder {
        width: 100%;
        min-height: 225px;
        margin-bottom: 15px;
        .mobile-styles({
            max-width: 420px;
            margin-left: auto;
            margin-right: auto;
            min-height: 400px;
            margin-bottom: 25px;
        });
    }

    &.product-placeholder {
		min-height: 300px;
		.desktop-min-only-styles({
			min-height: 500px;
		});
    }
}

.product-list-grid {
    display: flex;
    flex-wrap: wrap;

    &__item-wrapper {
        .mobile-styles({
            width: 100%;
            display: flex;
            padding: 1rem 0;
            margin: 0 0.5rem 0.5rem 0.5rem;

            &:not(:first-child) {
                border-top: 2px solid tint(@CL, 80%);
            }
        });

        .tablet-and-up-styles({
            width: 33%;
            padding: .6rem 0.4rem;
        });

        .desktop-min-only-styles({
            width: 25%;
            padding: 0.625rem 0.625rem 1.1875rem 0.625rem;
        });

        /**
            Grid Item Borders
        */

        @grid-border: 1px solid tint(@CL, 80%);

        .tablet-and-up-styles({
            position: relative;

            &:not(.no-bottom-border) {
                border-bottom: @grid-border;
            }

            &:not(.no-right-border) {
                border-right: @grid-border;
            }
        });
    }

    &__item {
        .mobile-styles({
            display: flex;
            width: 100%;
            & > * {
                width: 50%;
            }
        });
    }
}

.hawk-relaxedQuery {
    padding: 10px 15px;
	background-color: #FEFBAC;
	border-radius: 4px;
	margin-bottom: 15px;
    p {
        color: shade(@CL, 60%);
        font-weight: 400;
        margin: 0;
        .keyword {
            font-weight: 500;
        }
    }
}

.list-container{
    flex-direction: column;
    margin-top: 10px;

    .tablet-and-up-styles({
        flex-direction: row;
        margin-top:15px;
    });
    .facet-banner{
        .styles-at(768px,{
            display:none;
        });
    }

    .list-wrapper{
        margin: 0 -10px;

		&.tire-finder {
			margin: 0;
		}

        .tablet-and-up-styles({
            margin: 0;
            max-width: ~"calc(100% - 230px)";
            width: ~"calc(100% - 230px)";
            &.tire-finder {
                max-width: 100%;
                width: 100%;
            }
        });
    }
    .list-products{
        margin:0;
        .tablet-and-up-styles({
            margin-left:15px;
        });
    }
}
