
.scroll-hidden {
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	margin-bottom: 5px;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar { width: 0 !important }

	.scroll-hint {
		min-height: 70px;
		bottom: 0;
		position: sticky;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		color: #717BA9;
		font-size: 14px;
		&.grey {
			border-bottom: 2px solid #E5E5E5;
			margin: 0 10px;
			background: rgb(245,245,245);
			background: linear-gradient(180deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 50%, rgba(245,245,245,1) 100%);
		}
		&.white {
			background: #fff;
			background: transparent linear-gradient(180deg, #E5E7F100 0%, #FFFFFF 63%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
		}
		span {
			display: flex;
			align-items: center;
		}
		.icon-tiny {
			font-size: 7px;
			margin-left: 7px;
		}
	}
}
