
@import "../../less/_mixins.less";
a.ui-btn {
	text-decoration: none;
}
.ui-btn {
	appearance: none;
	background-color: @DR;
	border: solid 2px rgba(255, 255, 255, 0.3);
	border-radius: 6px;
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 18px;
	font-weight: bold;
	max-width: 340px;
	line-height: normal !important;
	width: 100%;
	padding: 12px 18px;
	transition: background-color 0.25s, border-color 0.25s, color 0.25s;
	&:hover:not(:disabled) {
		background-color: @red-hover;
	}
	&.inline {
		display: inline-block;
		width: auto;
	}

	&:focus-visible {
		.focus-outline()
	}

	&.disabled,
	&[aria-disabled=true],
	&[disabled] {
		cursor: not-allowed;
		opacity: 0.6;
	}
}

.ui-btn.ui-btn-sm {
	font-size: 12px;
	max-width: 270px;
	padding: 5px 12px;
}

.ui-btn.ui-btn-md {
	max-width: 320px;
	font-size: 16px;
	padding: 10px 16px;
	font-weight: bold;
}

.ui-btn.ui-btn-lg {
	font-size: 20px;
	max-width: 350px;
	padding: 12px 18px;
	min-width: 150px;
}

.ui-btn.ui-btn-full {
	max-width: 100% !important;
}

.ui-btn.ui-btn-narrow {
	min-height: 24px;
	min-width: 110px;
	padding: 5px 10px;
}

.ui-btn-icon {
	margin-right: 5px;
	i {
		.green {
			color: #74AA50;
		}
	}
	&.ui-btn-icon-right {
		margin-right: 0;
		margin-left: 5px;
	}
}

// Red with transparent border
.ui-btn-red-white-border {
	border: 2px solid rgba(255,255,255,0.2);
}

// Red outline and text
.ui-btn-red-outline {
	border: 2px solid #B12F31;
	color: #B12F31;
	background-color: transparent;
	&:hover:not(:disabled) {
		color: white;
		background-color: #B12F31;
		border-color: #B12F31;
	}
}

// White button
.ui-btn-white {
	border: 2px solid #CCCCCC;
	background-color: #fff;
	color: @blue;
	&:hover:not(:disabled) {
		background-color: #F9F9F9;
	}
}

// Grey-Blue button
.ui-btn-grey-blue {
	background-color: #fff;
	color: #024a76;
	border: 2px solid #ddd;
	font-weight: 700;
	&:hover:not(:disabled) {
		background-color: #fff;
	}
}

// Blue button
.ui-btn-blue {
	background-color: #024a76;
	color: #fff;
	border: 2px solid #024a76;
	&:hover:not(:disabled) {
		background-color: rgb(13, 99, 152);
		border: 2px solid rgb(13, 99, 152);
	}
}

// White Outline - Transparent BG
.ui-btn-white-outline {
	border: 2px solid #fff;
	color: #fff;
	background-color: transparent;

	&:hover:not(:disabled) {
		color: #01305F;
		background-color: #fff;
		border-color: #fff;
	}
}

// Dark Blue button
.ui-btn-dark-blue-outline {
	border: 2px solid #01305F;
	color: #01305F;
	background-color: transparent;
	&:hover:not(:disabled) {
		color: #fff;
		background-color: #01305F;
		border-color: #01305F;
	}
}

// Soft Blue button
.ui-btn-soft-blue-outline {
	border: 2px solid fadeout(@LB, 60%);
	color: shade(@LB, 20%);
	background-color: #fff;
	&:hover:not(:disabled) {
		color: shade(@LB, 20%);
		background-color: #fff;
		border-color: @LB;
	}
}

// Soft Gray button
.ui-btn.ui-btn-soft-gray-outline {
	border: 2px solid tint(@CL, 60%);
	background: #fff;
	border-radius: 5px;
	font-size: 0.9rem !important;
	padding: 0.6rem;
	color: #2079b0;
	font-weight: 500;
	&:hover:not(:disabled),
	&:focus {
		color: @LB;
		background-color: #fff;
		border-color: @LB;
	}
}

// Soft Blue button
.ui-btn-soft-blue {
	border: 2px solid rgba(255, 255, 255, 0.3);
	color:#fff;
	background-color: shade(@LB, 10%);
	&:hover:not(:disabled) {
		background-color: #fff;
		background-color: rgb(13, 99, 152);
		border-color: rgb(13, 99, 152);
	}
}

// Soft Blue button with green text
.ui-btn-soft-blue-green {
	border: 1px solid #D0E9FA;
	background-color: #F2F9FE;
	color:#008040;
	&:hover:not(:disabled) {
		border: 1px solid #D0E9FA;
		background-color: #F2F9FE;
		color: #008040;
	}
}

// Grey button
.ui-btn-grey {
	background-color: #EDEDED;
	color: #4A4A4A;
	border: 2px solid #C3C3C3;
	border-radius: 6px;
	font-weight: bold;
	&:hover:not(:disabled) {
		background-color: #EDEDED;
	}
}

@media screen and (max-width: @mobile) {
	.ui-btn.ui-btn-md {
		font-size: 12px;
		padding: 9px;
	}
	.ui-btn.ui-btn-lg {
		font-size: 16px;
	}
	.ui-btn-right {
		float: right;
	}
	.ui-btn.ui-btn-full {
		font-size: 14px;
	}
}

@media screen and (max-width: @mobile-small) {
	.ui-btn.ui-btn-full {
		padding: 7px 18px;
		font-size: 12px;
	}
	.ui-btn.ui-btn-sm {
		padding: 5px 6px;
		font-size: 12px;
		text-align: center;
	}
}
