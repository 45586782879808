
@import "~lessRoot/_mixins.less";

.external-auth {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;

	.tablet-and-up-styles({
		margin: 10px;
	});

	&__google {
		margin: 6px 0;
		max-height: 40px;
	}
}
