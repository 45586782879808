
@import '../../../../less/_mixins.less';
.blain-modal-loading {
	padding: 42px 20px 45px 20px;
	.mobile-styles({
		padding: 35px 20px 35px 20px;
	});
}
.sign-up-success-wrapper {
	padding: 10px 45px 45px 45px;
	.mobile-styles({
		padding: 0px 35px 35px 35px;
	});
	h2, p, a {
		font-family: 'Roboto';
	}
	h2 {
		font-weight: 500;
		margin-bottom: 18px;
		.ms-styles({
			margin-bottom: 15px;
			font-size: 26px;
		});
	}
	p {
		color: #656E97;
		line-height: 28px;
		.ms-styles({
			line-height: 20px;
		});
		&.main-text {
			font-size: 18px;
			.ms-styles({
				font-size: 16px;
			})
		}
		&.secondary-text {
			font-size: 12px;
		}
	}
}
