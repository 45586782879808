
@import '~@less/_mixins.less';
.header-icon-badge {
	background-color: #AA141A;
	color: #FFF;
	border-radius: 10px;
	font-size: 12px;
	font-weight: 600;
	line-height: 1em;
	height: 16px;
	min-width: 16px;
	padding: 2px 5px;
	position: absolute;
	top: -6px;
	right: -6px;

	.tablet-and-up-styles({
		&--2 {
			right: -11px;
		}

		&--3 {
			right: -16px;
		}
	});

}
