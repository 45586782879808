
@import "~lessRoot/_mixins.less";

.scrolling-site-messenger {
	width: 100%;
	background-color: @BB;
	display: flex;
	flex-direction: row;
	justify-content: center;

	&__messages-wrapper {
		max-width: 692px;
		overflow: hidden;
	}

	&__messages {
		display: flex;
		//Prevent flicker on Safari
		backface-visibility: hidden;
		transform-style: preserve-3d;
	}

	&__message {
		background-color: @BB;
		display: flex;
		min-width: 100%;
		justify-content: center;
	}

	&__text-wrapper {
		text-decoration: none;
		width: 100%;
		color: white;
		align-self: center;
		padding: 7px 8px 9px 8px;
		text-align: center;

		&:focus-visible {
			border-radius: 6px;
			outline: white solid 1px;
			outline-offset: -3px;
		}

		.tablet-and-up-styles({
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 690px;
			margin: 0 auto;
		});
	}

	&__icon {
		display: inline;
		vertical-align: text-bottom;
		margin-right: 4px;
		font-size: 18px;

		.tablet-and-up-styles({
			vertical-align: bottom;
			margin-right: 8px;
			font-size: 24px;
		});
	}

	&__text {
		position: relative;
		display: inline;
		margin: 0;
		color: white;
		font-size: 14px;
		line-height: 1.35;
		padding: 0 2px;
		text-align: center;

		.tablet-and-up-styles({
			font-size: 16px;
		});

		&--link {
			border-bottom: 1px solid white;

			.tablet-and-up-styles({
				padding: 0 2px 2px;
				border-bottom: none;

				&::after {
					transition: 150ms ease-in-out;
					content: "";
					width: 100%;
					border-bottom: 1px solid white;
					position: absolute;
					bottom: 0;
					left: 0;
				}

				&:hover {
					&::after {
						transform: scaleY(2);
						transform-origin: bottom;
					}
				}
			});
		}
	}

	&__countdown-text {
		display: inline;
	}


	&__arrow {
		padding: 8px;
		font-size: 14px;
		color: white;
		line-height: 1;
		z-index: 1;

		&:focus-visible {
			border-radius: 6px;
			outline: white solid 1px;
			outline-offset: -3px;
		}

		.tablet-and-up-styles({
			padding: 10px;
			font-size: 18px;
		});
	}
}

