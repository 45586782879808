
@import '../../../../less/_mixins.less';
.footer-email-signup {
	p {
		color: #4C5271;
	}
	.footer-divider {
		margin: 20px 0;
	}
}

.fes-form {
	padding: 0 10px;
	img {
		max-width: 100%;
	}
	.mobile-styles({
		padding: 0;
	});
}

.fes-input {
	border: solid 1px #CBD0E4;
	border-radius: 6px 0 0 6px;
	color: #4C5271;
	padding: 7px;
}

.fes-submit.ui-btn.ui-btn-sm {
	flex: 0 0 80px;
	font-size: 14px;
	position: relative;
	right: 3px;
}
