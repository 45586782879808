
@import '~@less/_mixins.less';
.mmd-automotive-link {
	background-color: #F7F8FB;
	border-bottom: solid 2px #CBD0E4;
	padding: 20px;
	flex: 1 1 30%;
	margin-right: 20px;
	.ui-btn:hover {
		text-decoration: none;
	}
	.icon-chevron-right {
		position: relative;
		top: 2px;
	}
	&__title {
		margin-bottom: 6px;
	}
}
.mmd-automotive-icon-lg {
	border-bottom: solid 5px @LB;
	display: block;
	color: @LB;
	margin-bottom: 20px;
	padding-bottom: 5px;
	font-size: 33px;
	width: 52px;
}
.mmd-automotive-services {
	margin-bottom: 15px;
	li {
		margin-bottom: 5px;
	}
	li::before {
		content: "\2022";
		color: @CL;
		font-weight: bold;
		font-size: 22px;
		line-height: 16px;
		display: inline-block;
		width: 12px;
		position: relative;
		top: 3px;
	}
}

.mega-menu-automotive-department {
	background-color: #FFF;
	top: 0; bottom: 0; left: 100%;
	padding: 20px;
	position: absolute;
	min-height: 0;
	max-width: calc(~"100vw - 240px");
	width: 1240px;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	&-image img {
		width: 300px;
	}
}
