
@import '../../../../less/_mixins.less';
.footer-mobile-links-wrapper .blue-link {
	color: @DB;
	font-size: 12px;
	line-height: 1em;
	margin: 0 0 10px 10px;

	&:hover {
		text-decoration: none;
	}

	span.icon {
		font-size: 7px;
		margin-left: 5px;
	}
}
