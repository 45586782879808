@import '../_variables.less';
@import '../_mixins.less';

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
	overflow-x: scroll;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 8px;
	line-height: 1.42857143;
	vertical-align: top;
	border-top: 1px solid #ddd;
	text-align: left;
}
.table > thead > tr > th {
	font-weight: bold;
	vertical-align: bottom;
	border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
	border-top: 0;
}
.table > tbody + tbody {
	border-top: 2px solid #ddd;
}
.table .table {
	background-color: #fff;
}
.table-striped > tbody > tr:nth-of-type(odd) {
	background-color: #f9f9f9;
}
table col[class*='col-'] {
	position: static;
	display: table-column;
	float: none;
}
table td[class*='col-'],
table th[class*='col-'] {
	position: static;
	display: table-cell;
	float: none;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
	background-color: #f5f5f5;
}
.table-responsive {
	min-height: 0.01%;
	overflow-x: auto;
}
@media screen and (max-width: 767px) {
	.table-responsive {
		width: 100%;
		margin-bottom: 15px;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ddd;
	}
	.table-responsive > .table {
		margin-bottom: 0;
	}
	.table-responsive > .table > thead > tr > th,
	.table-responsive > .table > tbody > tr > th,
	.table-responsive > .table > tfoot > tr > th,
	.table-responsive > .table > thead > tr > td,
	.table-responsive > .table > tbody > tr > td,
	.table-responsive > .table > tfoot > tr > td {
		white-space: nowrap;
	}
	.table-responsive > .table-bordered {
		border: 0;
	}
	.table-responsive > .table-bordered > thead > tr > th:first-child,
	.table-responsive > .table-bordered > tbody > tr > th:first-child,
	.table-responsive > .table-bordered > tfoot > tr > th:first-child,
	.table-responsive > .table-bordered > thead > tr > td:first-child,
	.table-responsive > .table-bordered > tbody > tr > td:first-child,
	.table-responsive > .table-bordered > tfoot > tr > td:first-child {
		border-left: 0;
	}
	.table-responsive > .table-bordered > thead > tr > th:last-child,
	.table-responsive > .table-bordered > tbody > tr > th:last-child,
	.table-responsive > .table-bordered > tfoot > tr > th:last-child,
	.table-responsive > .table-bordered > thead > tr > td:last-child,
	.table-responsive > .table-bordered > tbody > tr > td:last-child,
	.table-responsive > .table-bordered > tfoot > tr > td:last-child {
		border-right: 0;
	}
	.table-responsive > .table-bordered > tbody > tr:last-child > th,
	.table-responsive > .table-bordered > tfoot > tr:last-child > th,
	.table-responsive > .table-bordered > tbody > tr:last-child > td,
	.table-responsive > .table-bordered > tfoot > tr:last-child > td {
		border-bottom: 0;
	}
}