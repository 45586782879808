

.mega-menu-department {
	background-color: #FFF;
	top: 0; bottom: 0; left: 100%;
	position: absolute;
	width: 320px;
	border-left: 1px solid #CBD0E4;

	&__wrapper {
		height: 100%;
		overflow-y: auto;
	}

	&__shop-all {
		padding: 20px 25px 0 25px;
		display: flex;
		align-items: center;
		text-decoration: none;
		color: #01305F;
		font-weight: bold;
		font-size: 16px;

		&:hover {
			.mega-menu-department__shop-all-text {
				text-decoration: underline;
			}
		}
	}

	&__shop-all-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__shop-all-icon {
		font-size: 18px;
		margin-left: 10px;
	}
}

