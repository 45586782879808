
@import '../../../less/_mixins.less';

.blain-form-field {
	&__error {
		color: #B6161C;
		font-size: 0.75rem;
		line-height: 1.33;
		padding-top: 4px;
	}

	&__messages {
		display: flex;
		color: shade(@CL, 15%) ;
		font-size: 0.875rem;
		line-height: 1.35;
		font-style: italic;

		&__left {
			flex-grow: 1;
			margin-right: 1rem;
		}

		&__right {
			text-align: right;
		}
	}
}
