
@import '../../../../less/_mixins.less';
.list-items {
	padding-bottom: 5px;
	a {
		margin: 0 10px;
		padding: 10px;
		display: block;
		text-decoration: none;
		border-top: 2px solid #E5E7F1;
		color: @DB;
		font-size: 14px;
		font-weight: 500;
		line-height: 19px;
		&:first-child {
			border: none;
		}
	}
}
