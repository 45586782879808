
@import '../../../less/_mixins.less';

.blain-form-radio-button {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;

	&--custom-label {
		align-items: flex-start;
	}

	&--custom-label &__input {
		margin-top: .25rem;
	}

	&--disabled {
		opacity: 0.5;
	}

	* {
		cursor: pointer;
	}

	&--disabled * {
		cursor: default;
	}

	&__input {
		margin-right: 4px;
		border-radius: 50%;
		height: 20px;
		width: 20px;
		min-width: 20px;
		border: 1px solid currentColor;
		color: #2079B0;
	}

	&--disabled &__input {
		color: tint(@CL, 60%);
		outline: none !important;
	}

	&__input:checked {
		border-width: 5px;
	}

	&__label:hover ~ &__input,
	&__input:hover,
	&__input:focus {
		outline: 3px solid tint(@LB, 60%);
	}
}

