
@import '~@less/_mixins.less';
#headerNavigation {
	background-color: #FFF;
	border-bottom: 2px solid #CBD0E4;
	padding: 2px 0;
}
#headerAllDepartmentsBtn {
	appearance: none;
	background-color: #FFF;
	border-right: 2px solid rgba(126, 137, 188, 0.1);
	color: #01305F;
	padding: 3px 15px;
	flex-shrink: 0;

	.header-hamburger-icon {
		display: inline-block;
		font-size: 24px;
		width: 24px;
	}

	.chevron-icon {
		font-size: 10px;
	}
}
.nav-menu-wrapper {
	padding: 3px 0;
}
.nav-menu-item {
	flex-shrink: 0;

	&--stores {
		padding: 0 6px;
		.styles-at-min(1100px, {
			padding: 3px 12px;
		});
	}

	&__link {
		padding: 0 6px;
		font-size: 13px;
		font-weight: 500;
		text-decoration: none;
		color: #01305F;

		.styles-at-min(1100px, {
			padding: 3px 12px;
			font-size: 0.875rem;
		});

		&--border-right {
			border-right: 2px solid rgba(126,137,188,.1);
			padding: 6px 12px;
		}
	}

	&__phone {
		color: #656E97;
		font-weight: 400;
	}

	&__red {
		color: @BR;
	}
}
