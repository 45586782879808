
@import '../../../less/_mixins.less';

.footer-content {
	background-color: #F2F3F8;
}

.footer-content-top {
	a {
		color: @DB;
		display: block;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 10px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.footer-email-signup {
		flex: 0 1 300px;
	}
}

.footer-content-top.desktop {
	padding: 50px 15px;
	display: none;

	@media only screen and (min-width: 1025px) {
		display: flex;
	}

	.styles-at(1180px, {
		.footer-content-newsweek {
			display: none;
		}
		.footer-email-signup .footer-content-newsweek {
			display: block !important;
			margin: 20px auto 0 auto;
		}
	});
}

.footer-content-top.tablet {
	display: none;
	margin: 0 auto;
	max-width: 720px;
	padding: 20px;

	@media only screen and (min-width: @tablet-min) and (max-width: @desktop-min) {
		display: block;
	}
}

.footer-content-top.mobile {
	margin: 0 auto;
	max-width: 420px;
	padding: 20px;

	@media only screen and (min-width: @tablet-min){
		display: none;
	};

	.footer-customer-care,
	.footer-content-newsweek {
		margin: 0 0 20px;
	}

	.fes-social {
		margin: 0 auto 20px;
		width: 290px;
	}
}

.footer-content-signup {
	flex: 0 1 520px;
}

.footer-content-newsweek {
	max-width: 225px;
	flex: 0 0 130px;
	img {
		width: 100%;
	}
}

.footer-bottom {
	background-color: #E5E7F1;
	padding: 10px;

	a {
		border-left: solid 1px #B2B8D7;
		color: @DB;
		display: inline-block;
		font-size: 14px;
		margin: 0 0 10px 2px;
		padding-left: 5px;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}

		.mobile-styles({
			font-size: 11px;
			margin: 0 0 5px 2px;
		});
	}

	a:first-child {
		border-left: none;
		padding-left: 0;
		margin-left: 0;
	}

	.footer-divider {
		margin-top: 5px;
	}

	.mobile-styles({
		padding-bottom: 100px;
	});
}

.footer-bottom-copy {
	color: #4C5271;

	.mobile-styles({
		font-size: 11px;
	});
}

.footer-divider {
	background-color: #CBD0E4;
	height: 2px;
	margin: 15px 0 10px;
}

.footer-links-list {
	h2 {
		color: #4C5271;
		font-size: 18px;
		font-weight: 200;
		margin-bottom: 12px;
	}
}
