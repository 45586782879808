<template>
	<div>
		<transition name="fade" :mode="mode">
			<slot></slot>
		</transition>
	</div>
</template>

<script setup lang="ts">
defineProps({
	duration: {
		type: String,
		default: '0.5s',
	},
	easing: {
		type: String,
		default: 'linear',
	},
	mode: String,
});
</script>

<style scoped>
	.fade-enter-active, .fade-leave-active {
		transition-property: opacity;
		transition-duration: v-bind(duration);
		transition-timing-function: v-bind(easing);
	}

	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>